import {
  AfterViewInit,
  Component,
  computed,
  ElementRef,
  inject,
  Input,
  input,
  InputSignal,
  OnChanges,
  output,
  OutputEmitterRef,
  OutputRef,
  signal,
  Signal,
  ViewChild,
  WritableSignal,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { outputFromObservable, toSignal } from '@angular/core/rxjs-interop';
import { map } from 'rxjs';
import { PlantCodeComponent } from '../plant-code/plant-code.component';
import { ScopesService } from '../../../services/scopes/scopes.service';
import { SubsetScope } from '../../../services/scopes/model/scope.model';

@Component({
  selector: 'app-plant-code-list',
  standalone: true,
  imports: [PlantCodeComponent, ReactiveFormsModule],
  templateUrl: './plant-code-list.component.html',
  styleUrl: './plant-code-list.component.css',
})
export class PlantCodeListComponent implements OnChanges, AfterViewInit {
  @ViewChild('dropdownContainer') dropdownContainer!: ElementRef;

  private scopeService: ScopesService = inject(ScopesService);
  public displayList: InputSignal<boolean> = input(false);

  @Input() rePushScope: SubsetScope | undefined;
  public scopeList: Signal<SubsetScope[]> = toSignal(this.scopeService.retrieveAllObservable(), { initialValue: [] });
  public scopesSelected: WritableSignal<SubsetScope[]> = signal([]);
  public scopeAvailableList: Signal<SubsetScope[]> = computed(() => {
    const availableList: SubsetScope[] = this.scopeListMinusSelected();

    this.outputScopeAvailableList.emit(availableList);
    return availableList;
  });
  public scopeToAdd = new FormControl('');
  public isCustomDropdownOpen: WritableSignal<boolean> = signal(false);

  public outputScopeAvailableList: OutputEmitterRef<SubsetScope[]> = output();
  public outputScopeSelectedList: OutputRef<SubsetScope[] | undefined> = outputFromObservable(
    this.scopeToAdd.valueChanges.pipe(map((scopeToAdd: string | null) => this.onScopeToAdd(scopeToAdd))),
  );
  public searchTerm = new FormControl();

  scopeListMinusSelected() {
    const availableList = [...this.scopeList()];

    for (const scopeSelected of this.scopesSelected()) {
      const index = availableList.findIndex(({ plantCode }) => plantCode === scopeSelected.plantCode);
      if (index !== -1) {
        availableList.splice(index, 1);
      }
    }

    if (this.rePushScope) {
      availableList.push(this.rePushScope);
      // TODO: sort list
    }

    return availableList;
  }

  ngOnChanges() {
    if (this.rePushScope) {
      const scopesSelected = [...this.scopesSelected()];
      this.scopesSelected.set(scopesSelected.filter((s) => s.plantCode !== this.rePushScope?.plantCode));
    }
  }

  ngAfterViewInit(): void {
    this.searchTerm.valueChanges.subscribe((value: string) => {
      if (value.length > 2) {
        this.isCustomDropdownOpen.set(true);
      } else {
        this.isCustomDropdownOpen.set(false);
      }
    });
  }

  onScopeToAdd(scopeToAdd: string | null) {
    if (scopeToAdd) {
      this.searchTerm.setValue('');
      const scopeForSubset = this.scopeList().find(({ plantCode }) => plantCode === scopeToAdd) as SubsetScope;
      const scopesSelected = [...this.scopesSelected(), scopeForSubset];
      this.scopesSelected.set(scopesSelected);
      return scopesSelected;
    }
    return;
  }

  getScopeAvailableList(): SubsetScope[] {
    if (this.searchTerm.value) {
      const regExp = new RegExp(this.normalize(this.searchTerm.value));
      return this.scopeAvailableList().filter(
        (scope) => regExp.test(this.normalize(scope.plantCode)) || regExp.test(this.normalize(scope.name)),
      );
    }
    return this.scopeAvailableList();
  }

  /**
   * Transform: "Hélène-Frànçoise" -> "helene-francoise"
   */
  private normalize(value = ''): string {
    return value
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
  }

  selectScope(scope: SubsetScope) {
    this.scopeToAdd.setValue(scope.plantCode);
  }

  openManualSelect() {
    this.isCustomDropdownOpen.set(false);
    setTimeout(() => {
      this.dropdownContainer.nativeElement.querySelector('select').focus();
    }, 10);
  }
}
