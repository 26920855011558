import { EfaScopeModel } from '../../app/services/efa-scope/model/efa-scope';

export const efaScopeListSeed = [
  EfaScopeModel.builder().withLevel(1).withType('REG').withCode('EMEA').build(),
  EfaScopeModel.builder().withLevel(1).withType('REG').withCode('AMAS').build(),
  EfaScopeModel.builder().withLevel(1).withType('REG').withCode('APAC').build(),
  EfaScopeModel.builder().withLevel(2).withType('CTY').withCode('AE').build(),
  EfaScopeModel.builder().withLevel(2).withType('CTY').withCode('AT').build(),
  EfaScopeModel.builder().withLevel(2).withType('CTY').withCode('BE').build(),
  EfaScopeModel.builder().withLevel(2).withType('CTY').withCode('CH').build(),
  EfaScopeModel.builder().withLevel(2).withType('CTY').withCode('CZ').build(),
  EfaScopeModel.builder().withLevel(2).withType('CTY').withCode('DE').build(),
  EfaScopeModel.builder().withLevel(3).withType('PLT').withCode('BAU20').build(),
  EfaScopeModel.builder().withLevel(3).withType('PLT').withCode('BAU21').build(),
  EfaScopeModel.builder().withLevel(3).withType('PLT').withCode('BCN10').build(),
  EfaScopeModel.builder().withLevel(3).withType('PLT').withCode('BCN11').build(),
];
