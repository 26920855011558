import { EfaItem, EfaItemModel } from '../../app/pages/efa-management/model/item';

export const efaItemSeed: EfaItem[] = [
  EfaItemModel.builder()
    .withSourceErpCode('N')
    .withCode('N800524')
    .withUserItemCode('N800524')
    .withName('DECARBONATED WATER')
    .withType('Utility')
    .withUnitCode('TO')
    .build(),
  EfaItemModel.builder()
    .withSourceErpCode('N')
    .withCode('N800525')
    .withUserItemCode('N800525')
    .withName('MEAN PRESSURE STEAM TOR')
    .withType('Utility')
    .withUnitCode('TO')
    .build(),
  EfaItemModel.builder()
    .withSourceErpCode('N')
    .withCode('N800529')
    .withUserItemCode('N800529')
    .withName('ELECTRICITY')
    .withType('Utility')
    .withUnitCode('TO')
    .build(),
];
