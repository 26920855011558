import { throwParseError, tryParseNumber } from "@ark/util";
export const isDateLiteral = value => typeof value === "string" && value[0] === "d" && (value[1] === "'" || value[1] === '"') && value.at(-1) === value[1];
export const isValidDate = d => d.toString() !== "Invalid Date";
export const extractDateLiteralSource = literal => literal.slice(2, -1);
export const writeInvalidDateMessage = source => `'${source}' could not be parsed by the Date constructor`;
export const tryParseDate = (source, errorOnFail) => maybeParseDate(source, errorOnFail);
const maybeParseDate = (source, errorOnFail) => {
  const stringParsedDate = new Date(source);
  if (isValidDate(stringParsedDate)) return stringParsedDate;
  const epochMillis = tryParseNumber(source);
  if (epochMillis !== undefined) {
    const numberParsedDate = new Date(epochMillis);
    if (isValidDate(numberParsedDate)) return numberParsedDate;
  }
  return errorOnFail ? throwParseError(errorOnFail === true ? writeInvalidDateMessage(source) : errorOnFail) : undefined;
};