import { noSuggest } from "./errors.js";
import { flatMorph } from "./flatMorph.js";
/**
 * Object.entries wrapper providing narrowed types for objects with known sets
 * of keys, e.g. those defined internally as configs
 */
export const entriesOf = Object.entries;
export const fromEntries = entries => Object.fromEntries(entries);
export const keysOf = o => Object.keys(o);
export const isKeyOf = (k, o) => k in o;
export const hasKey = (o, k) => k in o;
// must be defined this way to avoid https://github.com/microsoft/TypeScript/issues/55049
export const hasDefinedKey = (o, k) => o[k] !== undefined;
export const InnerDynamicBase = class {};
export class DynamicBase {
  constructor(properties) {
    Object.assign(this, properties);
  }
}
export const NoopBase = class {};
/** @ts-ignore (needed to extend `t`) **/
export class CastableBase extends NoopBase {}
export const splitByKeys = (o, leftKeys) => {
  const l = {};
  const r = {};
  let k;
  for (k in o) {
    if (k in leftKeys) l[k] = o[k];else r[k] = o[k];
  }
  return [l, r];
};
export const pick = (o, keys) => splitByKeys(o, keys)[0];
export const omit = (o, keys) => splitByKeys(o, keys)[1];
export const isEmptyObject = o => Object.keys(o).length === 0;
export const stringAndSymbolicEntriesOf = o => [...Object.entries(o), ...Object.getOwnPropertySymbols(o).map(k => [k, o[k]])];
/** Like Object.assign, but it will preserve getters instead of evaluating them. */
export const defineProperties = (base, merged) =>
// declared like this to avoid https://github.com/microsoft/TypeScript/issues/55049
Object.defineProperties(base, Object.getOwnPropertyDescriptors(merged));
/** Copies enumerable keys of o to a new object in alphabetical order */
export const withAlphabetizedKeys = o => {
  const keys = Object.keys(o).sort();
  const result = {};
  for (let i = 0; i < keys.length; i++) result[keys[i]] = o[keys[i]];
  return result;
};
export const invert = t => flatMorph(t, (k, v) => [v, k]);
export const unset = noSuggest("represents an uninitialized value");