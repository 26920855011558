import { Injectable } from '@angular/core';
import { CactusService } from '../cactus.service';
import { Unit } from './model/unit.model';
import { catchError, map, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { unitListSeed } from '../../../tests/seed/unitList.seed';
import { toSignal } from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root',
})
export class UnitService extends CactusService<Unit> {
  retrieveUnitsByUnitCodeSignal() {
    return toSignal(
      this.retrieveAllObservable().pipe(
        map((observable) =>
          observable.reduce<Record<string, Unit>>((prev, curr) => ({ ...prev, [curr.unit_code]: curr }), {}),
        ),
      ),
      { initialValue: {} as Record<string, Unit> },
    );
  }

  retrieveAllObservable(): Observable<Unit[]> {
    if (environment.name === 'test') {
      return new Observable<Unit[]>((observer) => {
        if (!this.useInMemory) {
          this.inMemoryData = unitListSeed;
        }
        observer.next(this.inMemoryData);
        observer.complete();
      });
    }
    return this.retrieveAllSource();
  }

  private retrieveAllSource() {
    return this.http.get<{ data: Unit[] }>(`${environment.api}retrieveUnitList`).pipe(
      map(({ data }) => {
        this.inMemoryData = [];

        for (const unit of data) {
          this.inMemoryData.push(unit);
        }

        return this.inMemoryData;
      }),
      catchError(this.handleHttpError),
    );
  }
}
