import { Component, inject, input, output, signal } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CmdkItemSearch } from '../../../../utils/components/cmdk/cmdk-item-search.component';
import { Item, ItemsService } from '../../../../services/items/items.service';

import { toSignal } from '@angular/core/rxjs-interop';
import { debounceTime, filter, switchMap, tap } from 'rxjs';
import { EfaFormGroup, EfaFormNames } from '../../efa-management.types';

@Component({
  selector: 'app-efa-item-search',
  standalone: true,
  imports: [ReactiveFormsModule, CmdkItemSearch],
  template: `
    <app-cmdk-item-search
      [searchStringControl]="searchStringControl"
      [loading]="loading()"
      [items]="items()"
      [onSelectCallback]="onSelectCallback"
      (close)="onClose()"
    >
    </app-cmdk-item-search>
  `,
})
export class EfaItemSearch {
  private itemsService = inject(ItemsService);
  private formBuilder = inject(FormBuilder);

  public efaForm = input.required<FormGroup<EfaFormGroup>>();
  public close = output<void>();

  public searchStringControl = this.formBuilder.nonNullable.control('');

  public loading = signal<boolean>(false);

  private searchResults$ = this.searchStringControl.valueChanges.pipe(
    tap(() => this.loading.set(true)),
    debounceTime(800),
    filter((search) => search.length > 2),
    switchMap((search) => this.itemsService.retrieveAllObservable({ searchString: search })),
    tap(() => this.loading.set(false)),
  );

  public items = toSignal(this.searchResults$, { initialValue: [] });

  public onSelectCallback = (item: Item) => {
    this.efaForm().controls[EfaFormNames.ITEM].setValue(item);
  };

  public onClose() {
    this.close.emit();
  }
}
