import { Responsible, ResponsibleModel } from '../../app/services/responsible/model/responsible.model';

export const responsibleListSeed: Responsible[] = [
  ResponsibleModel.builder()
    .withFirstName('Alice')
    .withLastName('Bob')
    .withUuid('fd7486eb-9496-4419-bc50-6b8044042ba5')
    .withEmail('alice.bob@arkema.com')
    .build(),
  ResponsibleModel.builder()
    .withFirstName('Claire')
    .withLastName('Daniel')
    .withUuid('c139e1a1-f8e9-4c3d-80cf-0838b0e37f89')
    .withEmail('claire.daniel@arkema.com')
    .build(),
  ResponsibleModel.builder()
    .withFirstName('Eva')
    .withLastName('Francine')
    .withUuid('0c2b9998-0676-457c-bbf8-91fe7e714709')
    .withEmail('eva.francine@arkema.com')
    .build(),
  ResponsibleModel.builder()
    .withFirstName('Gwen')
    .withLastName('Herald')
    .withUuid('e124cbf4-281c-49f4-9ecb-ae5180bdf316')
    .withEmail('gwen.herald@arkema.com')
    .build(),
  ResponsibleModel.builder()
    .withFirstName('Isabelle')
    .withLastName('Justine')
    .withUuid('2596e033-6c47-4d62-9b01-a9a3dbe6e822')
    .withEmail('isabelle.justine@arkema.com')
    .build(),
  ResponsibleModel.builder()
    .withFirstName('Kim')
    .withLastName('Loris')
    .withUuid('fbc90efd-502a-498f-9510-18bd8646cbf0')
    .withEmail('kim.loris@arkema.com')
    .build(),
  ResponsibleModel.builder()
    .withFirstName('Ando')
    .withLastName('Nary')
    .withUuid('c2d524b4-0071-702f-6c4b-942b16de30ab')
    .withEmail('ando.nary-ext@arkema.com')
    .build(),
  ResponsibleModel.builder()
    .withFirstName('John')
    .withLastName('Doe')
    .withUuid('51b40fa6-dfea-4867-8bd5-7aeaec615224')
    .withEmail('john.doe-ext@arkema.com')
    .build(),
];
