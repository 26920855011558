import {
  Component,
  computed,
  effect,
  HostListener,
  inject,
  InjectionToken,
  OnDestroy,
  Signal,
  signal,
  untracked,
  WritableSignal,
} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePipe, NgClass, NgIf } from '@angular/common';
import { concatWith, debounceTime, lastValueFrom, map, Subject, takeUntil } from 'rxjs';
import { Router } from '@angular/router';
import { toSignal } from '@angular/core/rxjs-interop';

import { AuthService } from '../../services/auth/auth.service';
import { ChangePageEvent } from '../../utils/cactus-events/changepage';
import { EfaItem } from './model/item';
import { EfaItemType, EfaItemTypeService } from '../../services/efa-item-type/efa-item-type.service';
import { EfaItemSupplyService } from '../../services/efa-item-supply/efa-item-supply.service';
import { EfaItemsService } from '../../services/efa-items/efa-items.service';
import { EfaScope } from '../../services/efa-scope/model/efa-scope';
import { EfaScopeService } from '../../services/efa-scope/efa-scope.service';
import { ItemSupply, ItemSupplyModel } from './model/itemSupply';
import { ModalComponent } from '../../utils/components/modal/modal.component';
import { PlantCodeComponent } from '../../utils/components/plant-code/plant-code.component';
import { PlantCodeListComponent } from '../../utils/components/plant-code-list/plant-code-list.component';
import { Responsible } from '../../services/responsible/model/responsible.model';
import { ResponsibleService } from '../../services/responsible/responsible.service';
import { ResponsibleListComponent } from '../../utils/components/responsible-list/responsible-list.component';
import { SubsetScope } from '../../services/scopes/model/scope.model';
import { SourceERPCode, SourceErpService } from '../../services/source-erp/source-erp.service';
import { UniqueId } from '../../../tests/uniqueId';

type FormBuilderCategory =
  | 'itemSupplyUuid'
  | 'scopeType'
  | 'scopeCode'
  | 'itemSupplyStatus'
  | 'indicatorValue'
  | 'processusLabel'
  | 'datasource'
  | 'itemSupplyResponsible'
  | 'updateDate'
  | 'updateBy'
  | 'comment';

export const DEBOUNCE_TIME = new InjectionToken<number>('debounceTime');

@Component({
  selector: 'app-efa-management',
  standalone: true,
  imports: [
    PlantCodeComponent,
    FormsModule,
    PlantCodeListComponent,
    ResponsibleListComponent,
    DatePipe,
    ReactiveFormsModule,
    NgIf,
    NgClass,
    ModalComponent,
  ],
  providers: [{ provide: DEBOUNCE_TIME, useValue: 1000 }],
  templateUrl: './efa-management.component.html',
  styleUrl: './efa-management.component.css',
})
export class EfaManagementComponent implements OnDestroy {
  @HostListener(ChangePageEvent.triggerFullName, ['$event'])
  @HostListener('window:beforeunload', ['$event'])
  handleChangePage(event: BeforeUnloadEvent | ChangePageEvent) {
    if (event.type === ChangePageEvent.trigger && this.hasUnsavedChanges) {
      this.showModalChangePage.set(true);
    }
    // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
    // `event.preventDefault()` is already done by @HostListener decorator
    // returnValue included for legacy support, e.g. Chrome/Edge < 119
    if (this.hasUnsavedChanges) {
      event.returnValue = this.hasUnsavedChanges;
      return this.hasUnsavedChanges;
    }
    return;
  }

  private destroy$: Subject<undefined> = new Subject<undefined>();
  private displayHasChanged$: Subject<boolean> = new Subject<boolean>();
  private readonly authService = inject(AuthService);
  private formBuilder: FormBuilder = inject(FormBuilder);
  private efaItemService: EfaItemsService = inject(EfaItemsService);
  private efaScopeService: EfaScopeService = inject(EfaScopeService);
  private responsibleService: ResponsibleService = inject(ResponsibleService);
  private sourceErpService: SourceErpService = inject(SourceErpService);
  private efaItemTypeService: EfaItemTypeService = inject(EfaItemTypeService);
  private efaItemSupplyService: EfaItemSupplyService = inject(EfaItemSupplyService);
  private changePageEvent: ChangePageEvent = inject(ChangePageEvent);
  private router: Router = inject(Router);

  public lastSaveDate: Date = new Date();
  private debounceTimeValue: number = inject(DEBOUNCE_TIME);
  private defaultItemType: EfaItemType[] = ['Raw', 'Utility', 'Plant emissions', 'Plant wastes'];

  public efaScopes: Signal<EfaScope[]> = toSignal(this.efaScopeService.retrieveAllObservable(), { initialValue: [] });
  public sourceErpCodes: Signal<SourceERPCode[]> = toSignal(this.sourceErpService.retrieveAllObservable(), {
    initialValue: [],
  });
  public itemType: Signal<EfaItemType[]> = toSignal(this.efaItemTypeService.retrieveAllObservable(), {
    initialValue: [],
  });
  public itemSupplies: WritableSignal<ItemSupply[]> = signal([]);
  public responsibleList: WritableSignal<Responsible[]> = signal([]);

  public scopesSelected: WritableSignal<SubsetScope[]> = signal([]);
  public rePushScope: SubsetScope | undefined;
  public efaItemsDisplay: WritableSignal<EfaItem[]> = signal([]);

  displayRowLevelTwo: WritableSignal<boolean[]> = signal(this.efaItemsDisplay().map(() => false));
  itemSupplyForms: WritableSignal<FormGroup<Record<FormBuilderCategory, FormArray>>[] | null[]> = signal(
    new Array(this.efaItemsDisplay().length),
  );
  itemSupplyList: WritableSignal<ItemSupply[][]> = signal(this.efaItemsDisplay().map(() => []));
  itemSupplyToDelete: WritableSignal<ItemSupply[]> = signal([]);
  searchForm = new FormControl();
  filterSourceERPForm = computed(() => {
    return this.formBuilder.group({
      checkboxesERP: this.formBuilder.array(
        this.sourceErpCodes().map(() => this.formBuilder.control({ value: true, disabled: false })),
      ),
    });
  });
  filterExclusiveSourceERPForm = computed(() => {
    return this.formBuilder.group({
      exclusiveERP: this.formBuilder.array(
        this.sourceErpCodes().map(() => this.formBuilder.control({ value: true, disabled: false })),
      ),
    });
  });
  isSourceErpFilterPopupDisplayed: WritableSignal<boolean> = signal(false);
  erpFilter: WritableSignal<string[]> = signal([]);

  filterItemTypeForm = computed(() => {
    return this.formBuilder.group({
      checkboxesItemType: this.formBuilder.array(
        this.itemType().map((type: EfaItemType) =>
          this.formBuilder.control({ value: this.defaultItemType.includes(type), disabled: false }),
        ),
      ),
    });
  });
  isItemTypeFilterPopupDisplayed: WritableSignal<boolean> = signal(false);
  itemTypeFilter: WritableSignal<EfaItemType[]> = signal(this.defaultItemType);

  canApplyChange: WritableSignal<boolean> = signal(false);
  isApplyingChanges: WritableSignal<boolean> = signal(false);

  showModalChangePage: WritableSignal<boolean> = signal(false);
  showModalChangeDisplay: WritableSignal<boolean> = signal(false);

  commandChangeDisplay: Subject<void> = new Subject<void>();
  changeEfaItemServiceFetched: Subject<void> = new Subject<void>();

  hasInit: WritableSignal<boolean> = signal(false);

  columnItemSupplyForm: FormBuilderCategory[] = [
    'itemSupplyUuid',
    'scopeType',
    'scopeCode',
    'itemSupplyStatus',
    'indicatorValue',
    'processusLabel',
    'datasource',
    'itemSupplyResponsible',
    'updateDate',
    'updateBy',
    'comment',
  ];
  requiredColumns: FormBuilderCategory[] = [
    'scopeType',
    'scopeCode',
    'indicatorValue',
    'processusLabel',
    'itemSupplyResponsible',
  ];

  private _allRequests = toSignal(
    this.responsibleService.retrieveAllObservable().pipe(
      map((responsibleList) => {
        this.responsibleList.set(responsibleList);
        return responsibleList;
      }),
      map((responsibleList) => {
        return lastValueFrom(
          this.efaItemSupplyService.retrieveAllObservable(responsibleList).pipe(
            map((itemSupplies) => {
              this.itemSupplies.set(itemSupplies);
              return itemSupplies;
            }),
          ),
        );
      }),
    ),
  );

  constructor() {
    effect(() => {
      if (this.efaItemsDisplay().length > 0 && this.itemSupplies().length > 0) {
        untracked(() => {
          this.displayRowLevelTwo.set(this.efaItemsDisplay().map(() => false));
          this.itemSupplyForms.set(new Array(this.efaItemsDisplay().length));
          this.itemSupplyList.set(
            this.efaItemsDisplay().map(({ itemCode }, indexEfaItem) => {
              const itemSupplies = this.itemSupplies().filter((itemSupply) => itemSupply.itemCode === itemCode);
              return this.createItemSupplyForm(itemSupplies, indexEfaItem);
            }),
          );
        });
      }
      if (!this.hasInit()) {
        this.commandChangeDisplay.pipe(debounceTime(this.debounceTimeValue)).subscribe(() => {
          this.changeEfaItemsDisplay();
        });
      }
    });
  }

  /**
   * OnInit launched by user when clicking on exclusiveERP button
   */
  fetchRetrieveItems() {
    this.hasInit.set(true);
    return this.changeEfaItemsDisplay();
  }

  private createItemSupplyForm(itemSupplies: ItemSupply[], indexEfaItem: number) {
    const list = [];
    for (const itemSupply of itemSupplies) {
      if (!this.itemSupplyForms()[indexEfaItem]) {
        this.itemSupplyForms()[indexEfaItem] = this.formBuilder.group({
          itemSupplyUuid: this.formBuilder.array([]),
          scopeType: this.formBuilder.array([]),
          scopeCode: this.formBuilder.array([]),
          itemSupplyStatus: this.formBuilder.array([]),
          indicatorValue: this.formBuilder.array([]),
          processusLabel: this.formBuilder.array([]),
          datasource: this.formBuilder.array([]),
          itemSupplyResponsible: this.formBuilder.array([]),
          updateDate: this.formBuilder.array([]),
          updateBy: this.formBuilder.array([]),
          comment: this.formBuilder.array([]),
        });
      }

      this.columnItemSupplyForm.forEach((column: FormBuilderCategory) => {
        const formArrayItemSupply = this.itemSupplyFormGroup(indexEfaItem).get(column) as FormArray;
        const control = this.formBuilder.control('');
        if (column === 'itemSupplyStatus') {
          control.setValue(itemSupply.itemSupplyStatus);
        } else if (column === 'itemSupplyResponsible') {
          control.setValue(itemSupply.responsible?.uuid);
        } else if (column === 'scopeType') {
          control.setValue(itemSupply.scope.scopeType);
          control.valueChanges.pipe(takeUntil(this.displayHasChanged$)).subscribe(() => {
            this.canApplyChange.set(true);
          });
        } else if (column === 'scopeCode') {
          control.setValue(itemSupply.scope.scopeCode);
          control.valueChanges.pipe(takeUntil(this.displayHasChanged$)).subscribe(() => {
            this.canApplyChange.set(true);
          });
        } else if (column === 'indicatorValue') {
          control.setValue(itemSupply.indicatorValue.toString());
          control.valueChanges.pipe(takeUntil(this.displayHasChanged$)).subscribe(() => {
            this.canApplyChange.set(true);
          });
        } else if (column === 'processusLabel') {
          control.setValue(itemSupply.processusLabel);
          control.valueChanges.pipe(takeUntil(this.displayHasChanged$)).subscribe(() => {
            this.canApplyChange.set(true);
          });
        } else if (column === 'datasource') {
          control.setValue(itemSupply.dataSource);
          control.valueChanges.pipe(takeUntil(this.displayHasChanged$)).subscribe(() => {
            this.canApplyChange.set(true);
          });
        } else if (column === 'updateDate') {
          control.setValue(itemSupply.updateDate?.toISOString() || null);
        } else if (column === 'updateBy') {
          control.setValue(itemSupply.updateBy);
        } else if (column === 'comment') {
          control.setValue(itemSupply.comment);
          control.valueChanges.pipe(takeUntil(this.displayHasChanged$)).subscribe(() => {
            this.canApplyChange.set(true);
          });
        } else if (column === 'itemSupplyUuid') {
          control.setValue(itemSupply.itemSupplyUuid);
        }
        formArrayItemSupply.push(control);
      });

      list.push(itemSupply);
    }
    return list;
  }

  get hasUnsavedChanges(): boolean {
    return this.canApplyChange();
  }

  get canDeactivate(): boolean {
    if (this.hasUnsavedChanges) {
      this.showModalChangePage.set(true);
      return false;
    }
    return true;
  }

  itemSupplyFormGroup(indexEfaItem: number): FormGroup {
    return this.itemSupplyForms()[indexEfaItem] as FormGroup;
  }

  ngOnDestroy(): void {
    this.destroy$.next(undefined); // trigger unsubscribe
    this.displayHasChanged$.next(true); // trigger unsubscribe
    this.destroy$.complete();
    this.displayHasChanged$.complete();
    this.commandChangeDisplay.complete();
    this.changeEfaItemServiceFetched.complete();
  }

  get checkboxesItemType() {
    return this.filterItemTypeForm().get('checkboxesItemType') as FormArray;
  }

  getItemType(index: number) {
    return this.itemType()[index];
  }

  onItemTypeFilterChange(i: number) {
    const isChecked = this.checkboxesItemType.controls[i].value;

    const cloneCurrentFilter = [...this.itemTypeFilter()];
    this.itemTypeFilter.set(this.itemType().filter((_, j) => this.checkboxesItemType.controls[j].value));

    if (this.hasUnsavedChanges) {
      const applyDiscard = this.displayHasChanged$.pipe(takeUntil(this.destroy$)).subscribe((hasChanged: boolean) => {
        if (!hasChanged) {
          applyDiscard.unsubscribe();
          this.itemTypeFilter.set(cloneCurrentFilter);
          this.checkboxesItemType.controls[i].setValue(!isChecked);
        } else {
          applyDiscard.unsubscribe();
          this.canApplyChange.set(false);
          this.commandChangeDisplay.next();
        }
      });
    }

    this.commandChangeDisplay.next();
  }

  get exclusiveErp() {
    return this.filterExclusiveSourceERPForm().get('exclusiveERP') as FormArray;
  }

  onSourceErpFilterExclusiveChange(i: number) {
    if (!this.hasInit()) {
      this.hasInit.set(true);
    }
    for (let j = 0; j < this.checkboxesErp.controls.length; j++) {
      this.exclusiveErp.controls[j].setValue(i === j);
      this.checkboxesErp.controls[j].setValue(i === j);
    }

    return this.onSourceErpFilterChange(i);
  }

  getErpButtonSelected(i: number) {
    if (!this.hasInit()) {
      return false;
    }
    return this.exclusiveErp.controls[i].value;
  }

  get checkboxesErp() {
    return this.filterSourceERPForm().get('checkboxesERP') as FormArray;
  }

  getErpCode(index: number) {
    return this.sourceErpCodes()[index];
  }

  onSourceErpFilterChange(i: number) {
    const isChecked = this.checkboxesErp.controls[i].value;

    const cloneCurrentFilter = [...this.erpFilter()];
    this.erpFilter.set(this.sourceErpCodes().filter((_, j) => this.checkboxesErp.controls[j].value));

    if (this.hasUnsavedChanges) {
      const applyDiscard = this.displayHasChanged$.pipe(takeUntil(this.destroy$)).subscribe((hasChanged: boolean) => {
        if (!hasChanged) {
          applyDiscard.unsubscribe();
          this.erpFilter.set(cloneCurrentFilter);
          this.checkboxesErp.controls[i].setValue(!isChecked);
        } else {
          applyDiscard.unsubscribe();
          this.canApplyChange.set(false);
          this.commandChangeDisplay.next();
        }
      });
    }

    this.commandChangeDisplay.next();
  }

  addScopeSelected(scopes: SubsetScope[]) {
    if (this.hasUnsavedChanges) {
      const applyDiscard = this.displayHasChanged$.pipe(takeUntil(this.destroy$)).subscribe((hasChanged: boolean) => {
        if (!hasChanged) {
          for (const scope of scopes) {
            this.removeScopeSelected(scope, true);
          }
          applyDiscard.unsubscribe();
        } else {
          applyDiscard.unsubscribe();
          this.canApplyChange.set(false);
          this.commandChangeDisplay.next();
        }
      });
    }
    this.scopesSelected.set(scopes);
    if (this.hasInit()) {
      this.commandChangeDisplay.next();
    }
  }

  removeScopeSelected(scope: SubsetScope, forceToNotChangeDisplay = false) {
    const list = [...this.scopesSelected()];
    const index = list.findIndex(({ plantCode }) => plantCode === scope.plantCode);
    if (index > -1) {
      list.splice(index, 1);
      this.scopesSelected.set(list);
      this.rePushScope = scope;
      if (!forceToNotChangeDisplay) {
        const applyDiscard = this.displayHasChanged$.pipe(takeUntil(this.destroy$)).subscribe((hasChanged: boolean) => {
          if (!hasChanged) {
            list.splice(index, 0, scope);
            this.scopesSelected.set(list);
            applyDiscard.unsubscribe();
          } else {
            applyDiscard.unsubscribe();
            this.commandChangeDisplay.next();
          }
        });
        if (this.hasInit()) {
          this.commandChangeDisplay.next();
        }
      }
    }
  }

  changeEfaItemsDisplay() {
    if (this.hasUnsavedChanges) {
      this.showModalChangeDisplay.set(true);
      return;
    }

    const plantCodes: string[] = this.scopesSelected().map(({ plantCode }) => plantCode);
    const itemTypes: EfaItemType[] = this.itemTypeFilter() as EfaItemType[];
    const sourcesErpCodes: SourceERPCode[] = this.erpFilter() as SourceERPCode[];
    const itemName: string = this.searchForm.value;

    this.efaItemService
      .retrieveFromFilter({ plantCodes, itemTypes, sourcesErpCodes, itemName })
      .pipe(takeUntil(this.destroy$))
      .subscribe((efaItemList) => {
        this.efaItemsDisplay.set(efaItemList);
        this.changeEfaItemServiceFetched.next();
      });
  }

  getFormControl(formBuilderName: FormBuilderCategory, indexEfaItem: number, indexItemSupply: number): FormControl {
    const itemSupplyForm = this.itemSupplyFormGroup(indexEfaItem);
    const formBuilder = itemSupplyForm.get(formBuilderName) as FormArray;
    return formBuilder.controls.at(indexItemSupply) as FormControl;
  }

  getFormControlValue(formBuilderName: FormBuilderCategory, indexEfaItem: number, indexItemSupply: number) {
    const formControl = this.getFormControl(formBuilderName, indexEfaItem, indexItemSupply);
    return formControl.value;
  }

  efaScopeType(): string[] {
    const typeList: string[] = [];
    for (const efaScope of this.efaScopes()) {
      if (!typeList.find((t) => t === efaScope.scopeType)) {
        typeList.push(efaScope.scopeType);
      }
    }
    return typeList;
  }

  efaScopesCode(efaScopesType: string) {
    return this.efaScopes().filter((s) => s.scopeType === efaScopesType);
  }

  displayRowLevel(indexItem: number, forceTrue = false) {
    const currentDisplay = this.displayRowLevelTwo();
    currentDisplay[indexItem] = forceTrue || !currentDisplay[indexItem];
    this.displayRowLevelTwo.set(currentDisplay);
  }

  onResponsibleSelected(indexEfaItem: number, indexItemSupply: number, responsible?: Responsible) {
    if (responsible) {
      const formArrayResponsible = this.getFormControl('itemSupplyResponsible', indexEfaItem, indexItemSupply);

      formArrayResponsible.setValue(responsible.uuid);

      if (this.isResponsibleIsDifferent(indexEfaItem, indexItemSupply, responsible)) {
        this.canApplyChange.set(true);
      }
    }
  }

  private isResponsibleIsDifferent(indexEfaItem: number, indexItemSupply: number, responsible: Responsible): boolean {
    let isDifferent = false;

    const formArrayItemSupplyUuid = this.getFormControl('itemSupplyUuid', indexEfaItem, indexItemSupply);

    const itemSupplySaved = this.itemSupplies().find((is) => is.itemSupplyUuid === formArrayItemSupplyUuid.value);
    if (itemSupplySaved && responsible.uuid !== itemSupplySaved?.responsible.uuid) {
      isDifferent = true;
    }

    return isDifferent;
  }

  responsiblePreSelected(indexEfaItem: number, indexItemSupply: number) {
    const formArrayResponsible = this.getFormControl('itemSupplyResponsible', indexEfaItem, indexItemSupply);
    if (formArrayResponsible?.value) {
      return this.responsibleList().find((responsible) => responsible.uuid === formArrayResponsible.value);
    }
    return;
  }

  addItemSupply(indexEfaItem: number) {
    if (!this.itemSupplyForms()[indexEfaItem]) {
      this.itemSupplyForms()[indexEfaItem] = this.formBuilder.group({
        itemSupplyUuid: this.formBuilder.array([]),
        scopeType: this.formBuilder.array([]),
        scopeCode: this.formBuilder.array([]),
        itemSupplyStatus: this.formBuilder.array([]),
        indicatorValue: this.formBuilder.array([]),
        processusLabel: this.formBuilder.array([]),
        datasource: this.formBuilder.array([]),
        itemSupplyResponsible: this.formBuilder.array([]),
        updateDate: this.formBuilder.array([]),
        updateBy: this.formBuilder.array([]),
        comment: this.formBuilder.array([]),
      });
    }
    const fullList = this.itemSupplyList();
    const listToAdd = fullList[indexEfaItem];

    const newItemSupply = ItemSupplyModel.builder().build();
    listToAdd.push(newItemSupply);

    this.itemSupplyList.set(fullList);

    this.displayRowLevel(indexEfaItem, true);

    const itemSupplyForm = this.itemSupplyFormGroup(indexEfaItem);
    this.columnItemSupplyForm.forEach((column) => {
      const formArrayItemSupply = itemSupplyForm.get(column) as FormArray;
      const control = this.formBuilder.control('');
      if (column === 'itemSupplyStatus') {
        control.setValue('KO');
      } else if (column === 'itemSupplyUuid') {
        control.setValue(newItemSupply.itemSupplyUuid);
      } else if (column === 'itemSupplyResponsible') {
        const userUuid = this.authService.getCurrentUserUuid(this.responsibleList());
        if (userUuid) {
          control.setValue(userUuid);
        }
      }
      formArrayItemSupply.push(control);
    });
    this.itemSupplyForms()[indexEfaItem] = itemSupplyForm;

    this.canApplyChange.set(true);
  }

  duplicateRowLevel(indexEfaItem: number, itemSupply: ItemSupply, indexItemSupply: number) {
    const fullList = this.itemSupplyList();
    const listToDuplicate = fullList[indexEfaItem];

    const duplicate = ItemSupplyModel.builder().withItemSupply(itemSupply).build();

    listToDuplicate.splice(indexItemSupply, 0, duplicate);
    this.itemSupplyList.set(fullList);

    const itemSupplyForm = this.itemSupplyFormGroup(indexEfaItem);
    this.columnItemSupplyForm.forEach((column) => {
      const formArrayItemSupply = itemSupplyForm.get(column) as FormArray;
      const control = this.formBuilder.control('');
      if (column === 'itemSupplyStatus') {
        control.setValue('KO');
      } else if (column === 'itemSupplyUuid') {
        control.setValue(UniqueId.nextId());
        formArrayItemSupply.push(control);
      } else if (column === 'scopeCode') {
        control.setValue(null);
        formArrayItemSupply.push(control);
      } else if (column === 'itemSupplyResponsible') {
        const userUuid = this.authService.getCurrentUserUuid(this.responsibleList());
        if (userUuid) {
          control.setValue(userUuid);
        }
      } else {
        control.setValue(formArrayItemSupply.at(indexItemSupply).value);
      }
      formArrayItemSupply.insert(indexItemSupply + 1, control);
    });

    this.canApplyChange.set(true);
  }

  deleteItemSupply(indexEfaItem: number, indexItemSupply: number) {
    if (this.isItemSupplyStatusOK(indexEfaItem, indexItemSupply)) {
      return;
    }

    const fullList = this.itemSupplyList();
    const listToDelete = fullList[indexEfaItem];

    const itemSupplyDeleted = listToDelete.splice(indexItemSupply, 1);
    this.itemSupplyToDelete.set([...this.itemSupplyToDelete(), itemSupplyDeleted[0]]);

    this.itemSupplyList.set(fullList);

    this.columnItemSupplyForm.forEach((column) => {
      const itemSupplyForm = this.itemSupplyFormGroup(indexEfaItem);
      const formArrayItemSupply = itemSupplyForm.get(column) as FormArray;
      formArrayItemSupply.removeAt(indexItemSupply);
    });

    this.canApplyChange.set(true);
  }

  isItemSupplyStatusOK(indexEfaItem: number, indexItemSupply: number): boolean {
    const formArrayItemSupplyStatus = this.getFormControl('itemSupplyStatus', indexEfaItem, indexItemSupply);
    return formArrayItemSupplyStatus.value === 'OK';
  }

  isItemSupplyStatusKO(indexEfaItem: number, indexItemSupply: number): boolean {
    const formArrayItemSupplyStatus = this.getFormControl('itemSupplyStatus', indexEfaItem, indexItemSupply);
    return formArrayItemSupplyStatus.value === 'KO';
  }

  isItemSupplyValidable(indexEfaItem: number, indexItemSupply: number, itemSupply: ItemSupply): boolean {
    const validationRequired = this.checkAllRequiredFields(indexEfaItem, indexItemSupply);

    const validationCode = this.checkUniqueCode(indexEfaItem, itemSupply.itemSupplyUuid);

    return validationRequired && validationCode;
  }

  setStatusOk(indexEfaItem: number, indexItemSupply: number, itemSupply: ItemSupply) {
    const formArrayItemSupplyStatus = this.getFormControl('itemSupplyStatus', indexEfaItem, indexItemSupply);
    const validationRequired = this.checkAllRequiredFields(indexEfaItem, indexItemSupply);

    const validationCode = this.checkUniqueCode(indexEfaItem, itemSupply.itemSupplyUuid);

    if (!validationRequired || !validationCode) {
      return;
    }

    this.setUpdateByCurrentUser(indexEfaItem, indexItemSupply);
    formArrayItemSupplyStatus.setValue('OK');

    this.canApplyChange.set(true);
  }

  private checkAllRequiredFields(indexEfaItem: number, indexItemSupply: number) {
    return this.requiredColumns.every((column: FormBuilderCategory) => {
      const formArrayItemSupply = this.getFormControl(column, indexEfaItem, indexItemSupply);
      return !!formArrayItemSupply.value;
    });
  }

  private checkUniqueCode(indexEfaItem: number, itemSupplyUuid: string) {
    let validation = true;

    let itemSupplyIndex: number;
    const itemSupply = this.itemSupplyList()[indexEfaItem].find((i, index) => {
      itemSupplyIndex = index;
      return i.itemSupplyUuid === itemSupplyUuid;
    });
    const myCode = this.getFormControl('scopeCode', indexEfaItem, itemSupplyIndex!);
    const itemSuppliesInItemLength = this.itemSupplyList()[indexEfaItem].length || 0;
    for (let i = 0; i < itemSuppliesInItemLength; i++) {
      if (!itemSupply) continue; // should never append
      if (i === itemSupplyIndex!) continue;
      const otherCode = this.getFormControl('scopeCode', indexEfaItem, i);
      const otherUuid = this.getFormControl('itemSupplyUuid', indexEfaItem, i);
      if (otherCode.value === myCode.value && otherUuid.value !== itemSupplyUuid) {
        validation = false;
      }
    }

    return validation;
  }

  setStatusKo(indexEfaItem: number, indexItemSupply: number) {
    const formArrayItemSupplyStatus = this.getFormControl('itemSupplyStatus', indexEfaItem, indexItemSupply);

    this.setUpdateByCurrentUser(indexEfaItem, indexItemSupply);
    formArrayItemSupplyStatus.setValue('KO');

    this.canApplyChange.set(true);
  }

  private setUpdateByCurrentUser(indexEfaItem: number, indexItemSupply: number) {
    const formArrayUpdateDate = this.getFormControl('updateDate', indexEfaItem, indexItemSupply);
    const formArrayUpdateBy = this.getFormControl('updateBy', indexEfaItem, indexItemSupply);

    const userUuid = this.authService.getCurrentUserUuid(this.responsibleList());

    if (userUuid) {
      formArrayUpdateBy.setValue(userUuid);
      formArrayUpdateDate.setValue(new Date().toISOString());
    }
  }

  itemSupplyIndex(indexEfaItem: number) {
    return this.itemSupplyList()[indexEfaItem];
  }

  expandAll() {
    const result = this.displayRowLevelTwo().map(() => true);
    this.displayRowLevelTwo.set(result);
  }

  collapseAll() {
    const result = this.displayRowLevelTwo().map(() => false);
    this.displayRowLevelTwo.set(result);
  }

  onKeydown(event: KeyboardEvent | string) {
    if (!this.hasInit()) {
      return;
    }
    if (event === 'click' || (event instanceof KeyboardEvent && event.key === 'Enter')) {
      if (this.hasUnsavedChanges) {
        const applyDiscard = this.displayHasChanged$.pipe(takeUntil(this.destroy$)).subscribe((hasChanged: boolean) => {
          if (!hasChanged) {
            applyDiscard.unsubscribe();
          } else {
            applyDiscard.unsubscribe();
            this.canApplyChange.set(false);
            this.commandChangeDisplay.next();
          }
        });
        this.showModalChangeDisplay.set(true);
        return;
      }
      this.commandChangeDisplay.next();
    }
  }

  onConfirmChangePage(isConfirm: boolean) {
    if (isConfirm) {
      this.canApplyChange.set(false);
      this.router.navigate([this.changePageEvent.nextRoute]);
    }
    this.showModalChangePage.set(false);
  }

  onConfirmChangeDisplay(isConfirm: boolean) {
    if (isConfirm) {
      this.canApplyChange.set(false);
    }
    this.showModalChangeDisplay.set(false);
    this.displayHasChanged$.next(isConfirm);
  }

  applyChange() {
    const itemSupplies: ItemSupply[] = [];

    for (let indexEfaItem = 0; indexEfaItem < this.efaItemsDisplay().length; indexEfaItem++) {
      const itemCode = this.efaItemsDisplay()[indexEfaItem].itemCode;
      const itemSuppliesGroup = this.itemSupplyFormGroup(indexEfaItem);
      if (itemSuppliesGroup) {
        const array = itemSuppliesGroup.get('itemSupplyStatus') as FormArray;
        for (let indexItemSupply = 0; indexItemSupply < array.length; indexItemSupply++) {
          const itemSupplyBuilder = ItemSupplyModel.builder();

          const updateDate = this.getFormControl('updateDate', indexEfaItem, indexItemSupply).value;
          if (!updateDate) {
            this.setUpdateByCurrentUser(indexEfaItem, indexItemSupply);
          }

          itemSupplyBuilder
            .withItemCode(itemCode)
            .withUuid(this.getFormControl('itemSupplyUuid', indexEfaItem, indexItemSupply).value)
            .withScopeType(this.getFormControl('scopeType', indexEfaItem, indexItemSupply).value)
            .withScopeCode(this.getFormControl('scopeCode', indexEfaItem, indexItemSupply).value)
            .withIndicatorValue(Number(this.getFormControl('indicatorValue', indexEfaItem, indexItemSupply).value))
            .withProcessusLabel(this.getFormControl('processusLabel', indexEfaItem, indexItemSupply).value)
            .withDataSource(this.getFormControl('datasource', indexEfaItem, indexItemSupply).value)
            .withUpdateBy(this.getFormControl('updateBy', indexEfaItem, indexItemSupply).value)
            .withUpdateDate(this.getFormControl('updateDate', indexEfaItem, indexItemSupply).value)
            .withStatus(this.getFormControl('itemSupplyStatus', indexEfaItem, indexItemSupply).value);

          const responsibleUuid = this.getFormControl('itemSupplyResponsible', indexEfaItem, indexItemSupply).value;
          const responsible = this.responsibleList().find(({ uuid }) => uuid === responsibleUuid);

          if (responsible) {
            itemSupplyBuilder.withResponsible(responsible);
          }

          itemSupplies.push(itemSupplyBuilder.build());
        }
      }
    }

    this.isApplyingChanges.set(true);
    this.efaItemSupplyService
      .remove(this.itemSupplyToDelete())
      .pipe(concatWith(this.efaItemSupplyService.upsert(itemSupplies)))
      .subscribe({
        complete: () => {
          this.lastSaveDate = new Date();
          this.canApplyChange.set(false);
          this.isApplyingChanges.set(false);
          this.itemSupplyToDelete.set([]);
        },
      });
  }
}
