import { Injectable } from '@angular/core';
import XLSX from 'xlsx';

import { ArkErrors, type } from 'arktype';
import {
  EfaBulkUploadSheetName,
  EFA_BULK_UPLOAD_ITEM_SUPPLY_SCHEMA,
  EfaBulkUploadItemSupplyRow,
  EFA_BULK_UPLOAD_CHARACTERISATION_FACTOR_ROW_SCHEMA,
  EfaBulkUploadCharacterisationFactorRow,
  EFA_BULK_UPLOAD_SCHEMA,
  EfaBulkUploadPreviewTableData,
  EfaBulkUpload,
  EfaBulkUploadErrors,
  EfaBulkUploadErrorTableData,
} from './excel-service.schemas';

@Injectable({
  providedIn: 'root',
})
export class ExcelService {
  public combinedDataMemory: EfaBulkUpload[] = [];

  public generateEfaBulkUploadExcel(data: any[] | undefined, fileName: string): void {
    const workbook = XLSX.utils.book_new();
    const worksheetItems = XLSX.utils.json_to_sheet(data ?? []);
    const worksheetItemSupply = XLSX.utils.aoa_to_sheet([
      ['itemCode', 'scopeType', 'scopeCode', 'processusLabel', 'dataSource'],
    ]);
    const worksheetCharacterisationFactor = XLSX.utils.aoa_to_sheet([
      ['itemCode', 'scopeType', 'scopeCode', 'indicatorValue', 'indicatorCode'],
    ]);

    XLSX.utils.book_append_sheet(workbook, worksheetItems, EfaBulkUploadSheetName.ITEMS);
    XLSX.utils.book_append_sheet(workbook, worksheetItemSupply, EfaBulkUploadSheetName.ITEM_SUPPLIES);
    XLSX.utils.book_append_sheet(
      workbook,
      worksheetCharacterisationFactor,
      EfaBulkUploadSheetName.CHARACTERISATION_FACTORS,
    );

    XLSX.writeFile(workbook, fileName, { compression: true });
  }

  public async readAndValidateEfaBulkUploadExcel(
    file: File,
    responsible: string,
  ): Promise<EfaBulkUpload[] | EfaBulkUploadErrors> {
    const workbook = XLSX.read(await file.arrayBuffer());
    const itemSuppliesWorkSheet = workbook.Sheets[EfaBulkUploadSheetName.ITEM_SUPPLIES];
    const characterisationFactorWorkSheet = workbook.Sheets[EfaBulkUploadSheetName.CHARACTERISATION_FACTORS];
    const itemSupplyRows = EFA_BULK_UPLOAD_ITEM_SUPPLY_SCHEMA.array()(
      XLSX.utils.sheet_to_json<EfaBulkUploadItemSupplyRow>(itemSuppliesWorkSheet, {
        blankrows: false,
      }),
    );
    const characterisationFactorRows = EFA_BULK_UPLOAD_CHARACTERISATION_FACTOR_ROW_SCHEMA.array()(
      XLSX.utils.sheet_to_json<EfaBulkUploadCharacterisationFactorRow>(characterisationFactorWorkSheet, {
        blankrows: false,
      }),
    );

    if (itemSupplyRows instanceof type.errors || characterisationFactorRows instanceof type.errors) {
      const sheetErrors = {
        ...(() =>
          itemSupplyRows instanceof type.errors ? { [EfaBulkUploadSheetName.ITEM_SUPPLIES]: itemSupplyRows } : {})(),
        ...(() =>
          characterisationFactorRows instanceof type.errors
            ? { [EfaBulkUploadSheetName.CHARACTERISATION_FACTORS]: characterisationFactorRows }
            : {})(),
      };
      return sheetErrors;
    }

    const data = {
      itemSupplyRows: itemSupplyRows,
      characterisationFactorRows: characterisationFactorRows,
    };

    const combinedData = EFA_BULK_UPLOAD_SCHEMA.array().atLeastLength(1)(
      this.combineEfaBulkUploadData(data, responsible),
    );

    if (combinedData instanceof type.errors) {
      return {
        combined: combinedData,
      };
    }
    this.combinedDataMemory = combinedData;
    return combinedData;
  }

  private combineEfaBulkUploadData(
    {
      itemSupplyRows,
      characterisationFactorRows,
    }: {
      itemSupplyRows: EfaBulkUploadItemSupplyRow[];
      characterisationFactorRows: EfaBulkUploadCharacterisationFactorRow[];
    },
    responsible: string,
  ): EfaBulkUpload[] {
    const itemSupplies = itemSupplyRows.reduce<Record<string, EfaBulkUpload>>((prev, curr) => {
      const key = `${curr.itemCode}-${curr.scopeType}-${curr.scopeCode}`;
      prev[key] = {
        ...curr,
        responsible,
        indicators: [],
      };

      return prev;
    }, {});

    const itemSuppliesWithCharacterisationFactors = characterisationFactorRows.reduce<Record<string, EfaBulkUpload>>(
      (prev, curr) => {
        const key = `${curr.itemCode}-${curr.scopeType}-${curr.scopeCode}`;
        if (prev[key] != null) {
          prev[key].indicators.push({
            indicatorValue: curr.indicatorValue,
            indicatorCode: curr.indicatorCode,
          });
        }
        return prev;
      },
      itemSupplies,
    );

    return Object.values(itemSuppliesWithCharacterisationFactors);
  }

  public buildPreviewTableDataForEfaBulkUpload(data: EfaBulkUpload[]) {
    return data.reduce<EfaBulkUploadPreviewTableData[]>((prev, curr) => {
      return [
        ...prev,
        ...curr.indicators.reduce<EfaBulkUploadPreviewTableData[]>((prevIndicator, currIndicator) => {
          const id = `${curr.itemCode}-${curr.scopeType}-${curr.scopeCode}`;
          return [
            ...prevIndicator,
            {
              id,
              itemCode: curr.itemCode,
              scopeType: curr.scopeType,
              scopeCode: curr.scopeCode,
              processusLabel: curr.processusLabel,
              dataSource: curr.dataSource,
              indicatorValue: currIndicator.indicatorValue,
              indicatorCode: currIndicator.indicatorCode,
            },
          ];
        }, []),
      ];
    }, []);
  }

  public buildErrorTableDataForEfaBulkUpload(errorsObj: EfaBulkUploadErrors) {
    return (
      (Object.entries(errorsObj) as [string, ArkErrors][])
        .reduce<EfaBulkUploadErrorTableData[]>(
          (prev, [location, errs]) => [
            ...prev,
            ...errs.reduce<EfaBulkUploadErrorTableData[]>(
              (prev, err) => [
                ...prev,
                {
                  location: location,
                  row: (err.path[0] as number) + 2, // header & arrays start at 0
                  cell: err.path[1] as string,
                  error: err.problem,
                },
              ],
              [],
            ),
          ],
          [],
        )
        // TODO: replace with sorting in the table component
        .sort((a, b) => (a.row > b.row ? 1 : -1))
    );
  }
}
