import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { sourceItemTypeList } from '../../../tests/seed/efaItemType.seed';
import { CactusService } from '../cactus.service';
import { toSignal } from '@angular/core/rxjs-interop';

export type EfaItemType =
  | 'Finished'
  | 'Intermediate'
  | 'Other'
  | 'Packaging'
  | 'Plant wastes'
  | 'Plant emissions'
  | 'Raw'
  | 'Utility';

@Injectable({
  providedIn: 'root',
})
export class EfaItemTypeService extends CactusService<EfaItemType> {
  retrieveItemTypesListSignal() {
    return toSignal(this.retrieveAllObservable(), {
      initialValue: [],
    });
  }

  retrieveAllObservable(): Observable<EfaItemType[]> {
    if (['test'].includes(environment.name)) {
      return new Observable<EfaItemType[]>((observer) => {
        if (!this.useInMemory) {
          this.inMemoryData = sourceItemTypeList;
        }
        observer.next(this.inMemoryData);
        observer.complete();
      });
    }
    return this.getSource();
  }

  private getSource() {
    if (this.useInMemory) {
      return new Observable<EfaItemType[]>((observer) => {
        observer.next(this.inMemoryData);
        observer.complete();
      });
    }
    return this.http
      .get<{
        data: { item_type: EfaItemType }[];
      }>(`${environment.api}retrieveItemTypeList`)
      .pipe(
        map(({ data }) => {
          const inMemoryItemTypeCodeList: EfaItemType[] = [];
          for (const dataQueried of data) {
            inMemoryItemTypeCodeList.push(dataQueried.item_type);
          }
          return this.feedWith(inMemoryItemTypeCodeList.sort());
        }),
      );
  }
}
