import { Component, effect, HostListener, inject, OnInit, signal, ViewChild } from '@angular/core';
import { GroupedTableRowComponent, TableComponent } from '../../utils/components/table/table.component';
import { ColumnDef, flexRenderComponent, Row } from '@tanstack/angular-table';
import { EfaGroupedItemTableRow } from './table-components/efa-grouped-item-table-row.component';
import { EfaFilterDrawerComponent } from './efa-filter-drawer/efa-filter-drawer.component';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ResponsibleService } from '../../services/responsible/responsible.service';
import { SourceErpService } from '../../services/source-erp/source-erp.service';
import { EfaItemTypeService } from '../../services/efa-item-type/efa-item-type.service';
import { UnitService } from '../../services/unit/unit.service';
import reduce from 'lodash.reduce';
import { SubsetScope } from '../../services/scopes/model/scope.model';
import { EfaFilterNames, EfaItemDTO, EfaItemsService } from '../../services/efa-items/efa-items.service';
import { debounceTime, map, merge, Subject, switchMap, tap } from 'rxjs';
import { EfaAddEditModalComponent } from './efa-add-edit-modal/efa-add-edit-modal.component';
import { EfaGroupedItemSupplyTableRow } from './table-components/efa-grouped-item-supply-table-row.component';
import { EfaFormGroup, EfaFormNames, EfaTableFormNames, FilterFormGroup, TableFormGroup } from './efa-management.types';
import { Item } from '../../services/items/items.service';
import { EfaEditButtonComponent } from './table-components/efa-edit-button.component';
import { toSignal } from '@angular/core/rxjs-interop';
import { PopoverComponent } from '../../utils/components/popover/popover.component';
import { defaultTableViews } from './efa-management.constants';

import { type } from 'arktype';
import { validateWithSchema } from '../../utils/components/form/helpers/validateWithSchema';
import { EfaBulkUploadModal } from './efa-bulk-upload-modal/efa-bulk-upload-modal.component';
import { AuthService } from '../../services/auth/auth.service';

@Component({
  selector: 'app-efa-management-new',
  standalone: true,
  imports: [
    TableComponent,
    EfaFilterDrawerComponent,
    ReactiveFormsModule,
    PopoverComponent,
    EfaAddEditModalComponent,
    EfaBulkUploadModal,
  ],
  templateUrl: './efa-management.component.html',
})
export class EfaManagement implements OnInit {
  @ViewChild(TableComponent) tableComponent!: TableComponent<EfaItemDTO>;

  private authService = inject(AuthService);
  private formBuilder = inject(FormBuilder);
  private sourceErpService = inject(SourceErpService);
  private efaItemTypeService = inject(EfaItemTypeService);
  private responsibleService = inject(ResponsibleService);
  private unitsService = inject(UnitService);
  private efaItemsService = inject(EfaItemsService);

  private erpCodesSignal = this.sourceErpService.retrieveSourceErpCodesListSignal();
  private itemTypesSignal = this.efaItemTypeService.retrieveItemTypesListSignal();
  private responsiblesByIdSignal = this.responsibleService.retrieveResponsiblesByIdSignal();
  private unitsByUnitCodeSignal = this.unitsService.retrieveUnitsByUnitCodeSignal();

  public pageSizeOptions = [50, 100, 200];

  public filterForm: FormGroup<FilterFormGroup> = this.formBuilder.group({
    [EfaFilterNames.PAGE]: this.formBuilder.nonNullable.control<number>(1),
    [EfaFilterNames.PAGE_SIZE]: this.formBuilder.nonNullable.control<number>(this.pageSizeOptions[0]),
    [EfaFilterNames.SEARCH_STRING]: this.formBuilder.nonNullable.control<string>(''),
    [EfaFilterNames.PLANT_CODES_SEARCH]: this.formBuilder.nonNullable.control<string>(''),
    [EfaFilterNames.PLANT_CODES_SELECTED]: this.formBuilder.nonNullable.control<Record<string, SubsetScope>>({}),
    [EfaFilterNames.ERP_CODES]: this.buildComputedCheckboxGroup(this.erpCodesSignal()),
    [EfaFilterNames.ITEM_TYPE]: this.buildComputedCheckboxGroup(this.itemTypesSignal()),
    [EfaFilterNames.RESPONSIBLE]: this.buildComputedCheckboxGroup(this.responsiblesByIdSignal()),
    [EfaFilterNames.UNITS]: this.buildComputedCheckboxGroup(this.unitsByUnitCodeSignal()),
  });

  private efaFormSchema = type({
    [EfaFormNames.ITEM]: type({
      item_code: 'string > 0',
      user_item_code: 'string > 0',
      item_name: 'string > 0',
    }).or(type.undefined),
    [EfaFormNames.ITEM_SUPPLY_UUID]: 'string | null',
    [EfaFormNames.ITEM_SUPPLY]: {
      [EfaFormNames.SCOPE_TYPE]: 'string > 0',
      [EfaFormNames.SCOPE_CODE]: 'string > 0',
      [EfaFormNames.PROCESSUS_LABEL]: 'string > 5',
      [EfaFormNames.DATA_SOURCE]: 'string > 0',
      [EfaFormNames.RESPONSIBLE]: 'string > 0',
    },
    [EfaFormNames.INDICATORS]: {
      [EfaFormNames.INDICATOR_VALUE]: 'number',
      [EfaFormNames.INDICATOR_CODE]: 'string',
    },
  });

  public efaForm: FormGroup<EfaFormGroup> = this.formBuilder.group<EfaFormGroup>(
    {
      [EfaFormNames.ITEM]: this.formBuilder.nonNullable.control<Item | undefined>(undefined),
      [EfaFormNames.ITEM_SUPPLY_UUID]: this.formBuilder.nonNullable.control<string | null>(null),
      [EfaFormNames.ITEM_SUPPLY]: this.formBuilder.group({
        [EfaFormNames.SCOPE_TYPE]: this.formBuilder.nonNullable.control<string>(''),
        [EfaFormNames.SCOPE_CODE]: this.formBuilder.nonNullable.control<string>(''),
        [EfaFormNames.PROCESSUS_LABEL]: this.formBuilder.nonNullable.control<string>(''),
        [EfaFormNames.DATA_SOURCE]: this.formBuilder.nonNullable.control<string>(''),
        [EfaFormNames.RESPONSIBLE]: this.formBuilder.nonNullable.control<string>(''),
      }),
      [EfaFormNames.INDICATORS]: this.formBuilder.group({
        [EfaFormNames.INDICATOR_VALUE]: this.formBuilder.nonNullable.control<number>(0),
        [EfaFormNames.INDICATOR_CODE]: this.formBuilder.nonNullable.control<string>(''),
      }),
    },
    { validators: validateWithSchema(this.efaFormSchema), updateOn: 'blur' },
  );

  public tableForm: FormGroup<TableFormGroup> = this.formBuilder.group({
    [EfaTableFormNames.GROUPING]: this.formBuilder.control<Array<keyof EfaItemDTO>>([
      'user_item_code',
      'item_supply_uuid',
    ]),
    [EfaTableFormNames.COLUMN_VISIBILITY]: this.formBuilder.group({
      source_erp_code: this.formBuilder.nonNullable.control<boolean>(false),
      item_code: this.formBuilder.nonNullable.control<boolean>(false),
      user_item_code: this.formBuilder.nonNullable.control<boolean>(true),
      item_name: this.formBuilder.nonNullable.control<boolean>(false),
      item_type: this.formBuilder.nonNullable.control<boolean>(false),
      item_unit_code: this.formBuilder.nonNullable.control<boolean>(false),
      item_supply_uuid: this.formBuilder.nonNullable.control<boolean>(true),
      scope_type: this.formBuilder.nonNullable.control<boolean>(true),
      scope_code: this.formBuilder.nonNullable.control<boolean>(true),
      processus_label: this.formBuilder.nonNullable.control<boolean>(true),
      data_source: this.formBuilder.nonNullable.control<boolean>(true),
      responsible: this.formBuilder.nonNullable.control<boolean>(true),
      indicator_value: this.formBuilder.nonNullable.control<boolean>(true),
      indicator_code: this.formBuilder.nonNullable.control<boolean>(true),
    }),
  });

  constructor() {
    effect(() => {
      this.filterForm.setControl(EfaFilterNames.ERP_CODES, this.buildComputedCheckboxGroup(this.erpCodesSignal()));
      this.filterForm.setControl(EfaFilterNames.ITEM_TYPE, this.buildComputedCheckboxGroup(this.itemTypesSignal()));
      this.filterForm.setControl(
        EfaFilterNames.RESPONSIBLE,
        this.buildComputedCheckboxGroup(this.responsiblesByIdSignal()),
      );
      this.filterForm.setControl(EfaFilterNames.UNITS, this.buildComputedCheckboxGroup(this.unitsByUnitCodeSignal()));
    });
  }

  public incrementPageSize() {
    const currentValue = this.filterForm.controls[EfaFilterNames.PAGE].value;
    this.filterForm.controls[EfaFilterNames.PAGE].setValue(currentValue + 1);
  }

  public decrementPageSize() {
    const currentValue = this.filterForm.controls[EfaFilterNames.PAGE].value;
    if (currentValue > 1) {
      this.filterForm.controls[EfaFilterNames.PAGE].setValue(currentValue - 1);
    }
  }

  /** Filter state management */
  public filterDrawerOpen = signal<boolean>(false);

  public invertFilterDrawerOpenStatus = () => {
    this.filterDrawerOpen.update((isCurrentlyOpen) => {
      if (isCurrentlyOpen) {
        this.filterForm.controls.page.reset();
        this.performManualSearch();
        return !isCurrentlyOpen;
      }
      return !isCurrentlyOpen;
    });
  };

  /**
   * Modals
   */
  public bulkUploadModelOpenSignal = signal<boolean>(false);
  public addOrEditModelOpenSignal = signal<boolean>(false);

  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.metaKey && event.key === 'k') {
      event.preventDefault();
      this.efaForm.reset();
      this.addOrEditModelOpenSignal.update((v) => !v);
    }
  }

  /** Table View Management */
  public groupedTableRows: Array<GroupedTableRowComponent<EfaItemDTO>> = [
    EfaGroupedItemTableRow,
    EfaGroupedItemSupplyTableRow,
  ];

  public actionButtonCallbacks: Array<(row: Row<EfaItemDTO>) => void> = [
    // For item grouped row
    (row) => {
      this.efaForm.setValue({
        [EfaFormNames.ITEM]: {
          item_code: row.original.item_code,
          item_name: row.original.item_name,
          user_item_code: row.original.user_item_code,
        },
        [EfaFormNames.ITEM_SUPPLY_UUID]: null,
        [EfaFormNames.ITEM_SUPPLY]: {
          [EfaFormNames.SCOPE_TYPE]: 'WLD',
          [EfaFormNames.SCOPE_CODE]: 'WORLD',
          [EfaFormNames.PROCESSUS_LABEL]: '',
          [EfaFormNames.DATA_SOURCE]: '',
          [EfaFormNames.RESPONSIBLE]:
            this.authService.getCurrentUserUuid(Object.values(this.responsiblesByIdSignal())) ?? '',
        },
        [EfaFormNames.INDICATORS]: {
          [EfaFormNames.INDICATOR_VALUE]: 0,
          //TODO: fetch from backend
          [EfaFormNames.INDICATOR_CODE]: 'EFA_TOTAL',
        },
      });
      this.addOrEditModelOpenSignal.set(true);
    },
  ];

  public showColumnVisibilityPopover = signal<boolean>(false);

  public invertShowColumnVisibilityPopover() {
    this.showColumnVisibilityPopover.update((v) => !v);
  }

  public toggleTableView() {
    // ** Columns that are grouped must be visible by default.
    if (this.tableForm.controls[EfaTableFormNames.GROUPING].value == null) {
      this.tableForm.setValue(defaultTableViews[0]);
      return;
    }
    if (this.tableForm.controls[EfaTableFormNames.GROUPING].value.length === 1) {
      this.tableForm.setValue(defaultTableViews[1]);
      return;
    }
    this.tableForm.setValue(defaultTableViews[2]);
  }

  public get columnVisibilityControls() {
    return Object.entries(this.tableForm.controls[EfaTableFormNames.COLUMN_VISIBILITY].controls) as [
      keyof EfaItemDTO,
      FormControl<boolean>,
    ][];
  }

  public get columnVisibilityValue() {
    return this.tableForm.controls[EfaTableFormNames.COLUMN_VISIBILITY].getRawValue();
  }

  public get groupingValue() {
    return this.tableForm.controls[EfaTableFormNames.GROUPING].getRawValue();
  }

  public columnIsGrouped(column: string) {
    return (this.tableForm.controls[EfaTableFormNames.GROUPING].value ?? ([] as string[])).includes(column);
  }

  /** Data Fetching */
  public tableDataLoading = signal<boolean>(false);
  public totalResultsFound = signal<number | undefined>(undefined);

  private manualSearchTrigger$ = new Subject<void>();

  public performManualSearch() {
    this.manualSearchTrigger$.next();
  }

  private performFilter = () => {
    const filters = this.filterForm.value;
    return this.efaItemsService.retrieveAllObservable({ filters }).pipe(
      map((result) => {
        this.totalResultsFound.set(result.metadata.totalCount);
        return result.data;
      }),
    );
  };

  private searchTrigger$ = merge(
    this.manualSearchTrigger$,
    this.filterForm.controls.searchString.valueChanges.pipe(
      debounceTime(800),
      tap(() => this.filterForm.controls.page.reset()),
    ),
  );

  public searchResults$ = this.searchTrigger$.pipe(
    tap(() => this.tableDataLoading.set(true)),
    switchMap(() => this.performFilter()),
    tap(() => this.tableDataLoading.set(false)),
  );

  public tableData = toSignal(this.searchResults$, { initialValue: [] });

  ngOnInit() {
    this.manualSearchTrigger$.next();
  }

  /** Table */
  private tableEditButtonCallback = (row: Row<EfaItemDTO>) => () => {
    const item = {
      item_code: row.original.item_code,
      item_name: row.original.item_name,
      user_item_code: row.original.user_item_code,
    };

    const itemSupplyUuid = row.original.item_supply_uuid;

    const itemSupply = {
      scopeType: row.original.scope_type,
      scopeCode: row.original.scope_code,
      processusLabel: row.original.processus_label,
      dataSource: row.original.data_source,
      responsible: row.original.responsible,
    };

    const indicators = {
      indicatorValue: parseFloat(row.original.indicator_value.replace(',', '.')),
      indicatorCode: row.original.indicator_code,
    };

    if ((this.tableForm.controls[EfaTableFormNames.GROUPING].value ?? []).includes('item_supply_uuid')) {
      this.efaForm.setValue({
        ...this.efaForm.getRawValue(),
        item,
        itemSupplyUuid,
        indicators,
      });
    } else {
      this.efaForm.setValue({
        item,
        itemSupply,
        itemSupplyUuid,
        indicators,
      });
    }
    this.addOrEditModelOpenSignal.set(true);
  };

  public columnHeaders: Record<keyof EfaItemDTO, string> = {
    source_erp_code: 'Erp',
    item_code: 'Item Code',
    user_item_code: 'Item Code',
    item_name: 'Item Name',
    item_type: 'Item Type',
    item_unit_code: 'Unit',
    item_supply_uuid: 'Item Supply Uuid',
    scope_type: 'Scope Type',
    scope_code: 'Scope Code',
    processus_label: 'Processus Label',
    data_source: 'Data Source',
    responsible: 'Responsible',
    indicator_value: 'Indicator Value',
    indicator_code: 'Indicator Code',
  };

  public columns: ColumnDef<EfaItemDTO>[] = [
    {
      accessorKey: 'source_erp_code',
      header: this.columnHeaders.source_erp_code,
      size: 4,
    },
    {
      accessorKey: 'user_item_code',
      header: this.columnHeaders.user_item_code,
      size: 10,
    },
    {
      accessorKey: 'item_name',
      header: this.columnHeaders.item_name,
    },
    {
      accessorKey: 'item_type',
      header: this.columnHeaders.item_type,
    },
    {
      accessorKey: 'item_unit_code',
      header: this.columnHeaders.item_unit_code,
      size: 4,
    },
    {
      accessorKey: 'item_supply_uuid',
      header: this.columnHeaders.item_supply_uuid,
    },
    {
      accessorKey: 'scope_type',
      header: this.columnHeaders.scope_type,
      size: 8,
    },
    {
      accessorKey: 'scope_code',
      header: this.columnHeaders.scope_code,
      size: 8,
    },
    {
      accessorKey: 'processus_label',
      header: this.columnHeaders.processus_label,
    },
    {
      accessorKey: 'data_source',
      header: this.columnHeaders.data_source,
    },
    {
      accessorKey: 'responsible',
      header: this.columnHeaders.responsible,
      size: 12,
      cell: (ctx) => {
        const responsible = this.responsiblesByIdSignal()[ctx.getValue<string>()];
        if (!responsible) return ctx.getValue<string>();
        return `${responsible.firstName} ${responsible.lastName}`;
      },
    },
    {
      accessorKey: 'indicator_value',
      header: this.columnHeaders.indicator_value,
      size: 12,
      cell: (ctx) => {
        const value = ctx.getValue<string>();
        if (!value) return '';
        return `${value} teqCO2`;
      },
    },
    {
      accessorKey: 'indicator_code',
      header: this.columnHeaders.indicator_code,
      size: 8,
    },
    {
      id: 'actions',
      size: 8,
      cell: (ctx) => {
        return flexRenderComponent(EfaEditButtonComponent, {
          inputs: { callback: this.tableEditButtonCallback(ctx.row) },
        });
      },
    },
  ];

  /** Utility functions */
  /**
   * @returns a form group of checkboxes based on the input data.
   */
  private buildComputedCheckboxGroup(
    values: string[] | Record<string, any>,
  ): FormGroup<Record<string, FormControl<boolean>>> {
    if (Array.isArray(values)) {
      return this.formBuilder.group(
        values.reduce<Record<string, FormControl<boolean>>>(
          (prev, curr) => ({ ...prev, [curr]: this.formBuilder.nonNullable.control<boolean>(true) }),
          {},
        ),
      );
    }
    if (typeof values === 'object') {
      return this.formBuilder.group(
        reduce(values, (prev, _, key) => ({ ...prev, [key]: this.formBuilder.nonNullable.control<boolean>(true) }), {}),
      );
    }
    // should never happen
    return this.formBuilder.group({});
  }
}
