import { Routes } from '@angular/router';
import { HomepageComponent } from './pages/homepage/homepage.component';
import { SubsetListComponent } from './pages/subset-list/subset-list.component';
import { SubsetCreateComponent } from './pages/subset-create/subset-create.component';
import { SubsetDetailsComponent } from './pages/subset-details/subset-details.component';
import { SubsetEditComponent } from './pages/subset-edit/subset-edit.component';
import { SubsetPublishComponent } from './pages/subset-publish/subset-publish.component';
import { EfaManagementComponent } from './pages/efa-management/efa-management.component';

export const routes: Routes = [
  { path: '', component: HomepageComponent },
  { path: 'list', component: SubsetListComponent },
  { path: 'new-subset', component: SubsetCreateComponent },
  { path: 'subset/:uuid', component: SubsetDetailsComponent },
  { path: 'subset-edit/:uuid', component: SubsetEditComponent },
  { path: 'subset-publish/:uuid', component: SubsetPublishComponent },
  {
    path: 'efa',
    component: EfaManagementComponent,
    canDeactivate: [(component: EfaManagementComponent) => component.canDeactivate],
  },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];
