import { append, conflatenate, flatMorph, printable, spliterate, throwParseError } from "@ark/util";
import { BaseConstraint, constraintKeyParser, flattenConstraints, intersectConstraints } from "../constraint.js";
import { typeOrTermExtends } from "../roots/root.js";
import { Disjoint } from "../shared/disjoint.js";
import { implementNode } from "../shared/implement.js";
import { intersectNodesRoot } from "../shared/intersections.js";
import { JsonSchema } from "../shared/jsonSchema.js";
import { $ark, registeredReference } from "../shared/registry.js";
import { traverseKey } from "../shared/traversal.js";
import { hasArkKind, isNode, makeRootAndArrayPropertiesMutable } from "../shared/utils.js";
import { Optional } from "./optional.js";
import { arrayIndexMatcherReference } from "./shared.js";
const createStructuralWriter = childStringProp => node => {
  if (node.props.length || node.index) {
    const parts = node.index?.map(String) ?? [];
    node.props.forEach(node => parts.push(node[childStringProp]));
    if (node.undeclared) parts.push(`+ (undeclared): ${node.undeclared}`);
    const objectLiteralDescription = `{ ${parts.join(", ")} }`;
    return node.sequence ? `${objectLiteralDescription} & ${node.sequence.description}` : objectLiteralDescription;
  }
  return node.sequence?.description ?? "{}";
};
const structuralDescription = createStructuralWriter("description");
const structuralExpression = createStructuralWriter("expression");
const implementation = implementNode({
  kind: "structure",
  hasAssociatedError: false,
  normalize: schema => schema,
  applyConfig: (schema, config) => {
    if (!schema.undeclared && config.onUndeclaredKey !== "ignore") {
      return {
        ...schema,
        undeclared: config.onUndeclaredKey
      };
    }
    return schema;
  },
  keys: {
    required: {
      child: true,
      parse: constraintKeyParser("required"),
      reduceIo: (ioKind, inner, nodes) => {
        // ensure we don't overwrite nodes added by optional
        inner.required = append(inner.required, nodes.map(node => node[ioKind]));
        return;
      }
    },
    optional: {
      child: true,
      parse: constraintKeyParser("optional"),
      reduceIo: (ioKind, inner, nodes) => {
        if (ioKind === "in") {
          inner.optional = nodes.map(node => node.in);
          return;
        }
        nodes.forEach(node => inner[node.outProp.kind] = append(inner[node.outProp.kind], node.outProp.out));
      }
    },
    index: {
      child: true,
      parse: constraintKeyParser("index")
    },
    sequence: {
      child: true,
      parse: constraintKeyParser("sequence")
    },
    undeclared: {
      parse: behavior => behavior === "ignore" ? undefined : behavior,
      reduceIo: (ioKind, inner, value) => {
        if (value !== "delete") return;
        // if base is "delete", undeclared keys are "ignore" (i.e. unconstrained)
        // on input and "reject" on output
        if (ioKind === "in") delete inner.undeclared;else inner.undeclared = "reject";
      }
    }
  },
  defaults: {
    description: structuralDescription
  },
  intersections: {
    structure: (l, r, ctx) => {
      const lInner = {
        ...l.inner
      };
      const rInner = {
        ...r.inner
      };
      const disjointResult = new Disjoint();
      if (l.undeclared) {
        const lKey = l.keyof();
        r.requiredKeys.forEach(k => {
          if (!lKey.allows(k)) {
            disjointResult.add("presence", $ark.intrinsic.never.internal, r.propsByKey[k].value, {
              path: [k]
            });
          }
        });
        if (rInner.optional) rInner.optional = rInner.optional.filter(n => lKey.allows(n.key));
        if (rInner.index) {
          rInner.index = rInner.index.flatMap(n => {
            if (n.signature.extends(lKey)) return n;
            const indexOverlap = intersectNodesRoot(lKey, n.signature, ctx.$);
            if (indexOverlap instanceof Disjoint) return [];
            const normalized = normalizeIndex(indexOverlap, n.value, ctx.$);
            if (normalized.required) {
              rInner.required = conflatenate(rInner.required, normalized.required);
            }
            if (normalized.optional) {
              rInner.optional = conflatenate(rInner.optional, normalized.optional);
            }
            return normalized.index ?? [];
          });
        }
      }
      if (r.undeclared) {
        const rKey = r.keyof();
        l.requiredKeys.forEach(k => {
          if (!rKey.allows(k)) {
            disjointResult.add("presence", l.propsByKey[k].value, $ark.intrinsic.never.internal, {
              path: [k]
            });
          }
        });
        if (lInner.optional) lInner.optional = lInner.optional.filter(n => rKey.allows(n.key));
        if (lInner.index) {
          lInner.index = lInner.index.flatMap(n => {
            if (n.signature.extends(rKey)) return n;
            const indexOverlap = intersectNodesRoot(rKey, n.signature, ctx.$);
            if (indexOverlap instanceof Disjoint) return [];
            const normalized = normalizeIndex(indexOverlap, n.value, ctx.$);
            if (normalized.required) {
              lInner.required = conflatenate(lInner.required, normalized.required);
            }
            if (normalized.optional) {
              lInner.optional = conflatenate(lInner.optional, normalized.optional);
            }
            return normalized.index ?? [];
          });
        }
      }
      const baseInner = {};
      if (l.undeclared || r.undeclared) {
        baseInner.undeclared = l.undeclared === "reject" || r.undeclared === "reject" ? "reject" : "delete";
      }
      const childIntersectionResult = intersectConstraints({
        kind: "structure",
        baseInner,
        l: flattenConstraints(lInner),
        r: flattenConstraints(rInner),
        roots: [],
        ctx
      });
      if (childIntersectionResult instanceof Disjoint) disjointResult.push(...childIntersectionResult);
      if (disjointResult.length) return disjointResult;
      return childIntersectionResult;
    }
  }
});
export class StructureNode extends BaseConstraint {
  impliedBasis = $ark.intrinsic.object.internal;
  impliedSiblings = this.children.flatMap(n => n.impliedSiblings ?? []);
  props = conflatenate(this.required, this.optional);
  propsByKey = flatMorph(this.props, (i, node) => [node.key, node]);
  propsByKeyReference = registeredReference(this.propsByKey);
  expression = structuralExpression(this);
  requiredKeys = this.required?.map(node => node.key) ?? [];
  optionalKeys = this.optional?.map(node => node.key) ?? [];
  literalKeys = [...this.requiredKeys, ...this.optionalKeys];
  _keyof;
  keyof() {
    if (this._keyof) return this._keyof;
    let branches = this.$.units(this.literalKeys).branches;
    this.index?.forEach(({
      signature
    }) => {
      branches = branches.concat(signature.branches);
    });
    return this._keyof = this.$.node("union", branches);
  }
  map(flatMapProp) {
    return this.$.node("structure", this.props.flatMap(flatMapProp).reduce((structureInner, mapped) => {
      const originalProp = this.propsByKey[mapped.key];
      if (isNode(mapped)) {
        if (mapped.kind !== "required" && mapped.kind !== "optional") {
          return throwParseError(`Map result must have kind "required" or "optional" (was ${mapped.kind})`);
        }
        structureInner[mapped.kind] = append(structureInner[mapped.kind], mapped);
        return structureInner;
      }
      const mappedKind = mapped.kind ?? originalProp?.kind ?? "required";
      // extract the inner keys from the map result in case a node was spread,
      // which would otherwise lead to invalid keys
      const mappedPropInner = flatMorph(mapped, (k, v) => k in Optional.implementation.keys ? [k, v] : []);
      structureInner[mappedKind] = append(structureInner[mappedKind], this.$.node(mappedKind, mappedPropInner));
      return structureInner;
    }, {}));
  }
  assertHasKeys(keys) {
    const invalidKeys = keys.filter(k => !typeOrTermExtends(k, this.keyof()));
    if (invalidKeys.length) {
      return throwParseError(writeInvalidKeysMessage(this.expression, invalidKeys));
    }
  }
  get(indexer, ...path) {
    let value;
    let required = false;
    const key = indexerToKey(indexer);
    if ((typeof key === "string" || typeof key === "symbol") && this.propsByKey[key]) {
      value = this.propsByKey[key].value;
      required = this.propsByKey[key].required;
    }
    this.index?.forEach(n => {
      if (typeOrTermExtends(key, n.signature)) value = value?.and(n.value) ?? n.value;
    });
    if (this.sequence && typeOrTermExtends(key, $ark.intrinsic.nonNegativeIntegerString)) {
      if (hasArkKind(key, "root")) {
        if (this.sequence.variadic)
          // if there is a variadic element and we're accessing an index, return a union
          // of all possible elements. If there is no variadic expression, we're in a tuple
          // so this access wouldn't be safe based on the array indices
          value = value?.and(this.sequence.element) ?? this.sequence.element;
      } else {
        const index = Number.parseInt(key);
        if (index < this.sequence.prevariadic.length) {
          const fixedElement = this.sequence.prevariadic[index].node;
          value = value?.and(fixedElement) ?? fixedElement;
          required ||= index < this.sequence.prefixLength;
        } else if (this.sequence.variadic) {
          // ideally we could return something more specific for postfix
          // but there is no way to represent it using an index alone
          const nonFixedElement = this.$.node("union", this.sequence.variadicOrPostfix);
          value = value?.and(nonFixedElement) ?? nonFixedElement;
        }
      }
    }
    if (!value) {
      if (this.sequence?.variadic && hasArkKind(key, "root") && key.extends($ark.intrinsic.number)) {
        return throwParseError(writeNumberIndexMessage(key.expression, this.sequence.expression));
      }
      return throwParseError(writeInvalidKeysMessage(this.expression, [key]));
    }
    const result = value.get(...path);
    return required ? result : result.or($ark.intrinsic.undefined);
  }
  pick(...keys) {
    this.assertHasKeys(keys);
    return this.$.node("structure", this.filterKeys("pick", keys));
  }
  omit(...keys) {
    this.assertHasKeys(keys);
    return this.$.node("structure", this.filterKeys("omit", keys));
  }
  optionalize() {
    const {
      required,
      ...inner
    } = this.inner;
    return this.$.node("structure", {
      ...inner,
      optional: this.props.map(prop => prop.hasKind("required") ? this.$.node("optional", prop.inner) : prop)
    });
  }
  require() {
    const {
      optional,
      ...inner
    } = this.inner;
    return this.$.node("structure", {
      ...inner,
      required: this.props.map(prop => prop.hasKind("optional") ? {
        key: prop.key,
        value: prop.value
      } : prop)
    });
  }
  merge(r) {
    const inner = this.filterKeys("omit", [r.keyof()]);
    if (r.required) inner.required = append(inner.required, r.required);
    if (r.optional) inner.optional = append(inner.optional, r.optional);
    if (r.index) inner.index = append(inner.index, r.index);
    if (r.sequence) inner.sequence = r.sequence;
    if (r.undeclared) inner.undeclared = r.undeclared;else delete inner.undeclared;
    return this.$.node("structure", inner);
  }
  filterKeys(operation, keys) {
    const result = makeRootAndArrayPropertiesMutable(this.inner);
    const shouldKeep = key => {
      const matchesKey = keys.some(k => typeOrTermExtends(key, k));
      return operation === "pick" ? matchesKey : !matchesKey;
    };
    if (result.required) result.required = result.required.filter(prop => shouldKeep(prop.key));
    if (result.optional) result.optional = result.optional.filter(prop => shouldKeep(prop.key));
    if (result.index) result.index = result.index.filter(index => shouldKeep(index.signature));
    return result;
  }
  traverseAllows = (data, ctx) => this._traverse("Allows", data, ctx);
  traverseApply = (data, ctx) => this._traverse("Apply", data, ctx);
  _traverse = (traversalKind, data, ctx) => {
    const errorCount = ctx?.currentErrorCount ?? 0;
    for (let i = 0; i < this.props.length; i++) {
      if (traversalKind === "Allows") {
        if (!this.props[i].traverseAllows(data, ctx)) return false;
      } else {
        this.props[i].traverseApply(data, ctx);
        if (ctx.failFast && ctx.currentErrorCount > errorCount) return false;
      }
    }
    if (this.sequence) {
      if (traversalKind === "Allows") {
        if (!this.sequence.traverseAllows(data, ctx)) return false;
      } else {
        this.sequence.traverseApply(data, ctx);
        if (ctx.failFast && ctx.currentErrorCount > errorCount) return false;
      }
    }
    if (!requireExhasutiveTraversal(this, traversalKind)) return true;
    const keys = Object.keys(data);
    keys.push(...Object.getOwnPropertySymbols(data));
    for (let i = 0; i < keys.length; i++) {
      const k = keys[i];
      let matched = false;
      if (this.index) {
        for (const node of this.index) {
          if (node.signature.traverseAllows(k, ctx)) {
            if (traversalKind === "Allows") {
              const result = traverseKey(k, () => node.value.traverseAllows(data[k], ctx), ctx);
              if (!result) return false;
            } else {
              traverseKey(k, () => node.value.traverseApply(data[k], ctx), ctx);
              if (ctx.failFast && ctx.currentErrorCount > errorCount) return false;
            }
            matched = true;
          }
        }
      }
      if (this.undeclared) {
        matched ||= k in this.propsByKey;
        matched ||= this.sequence !== undefined && typeof k === "string" && $ark.intrinsic.nonNegativeIntegerString.allows(k);
        if (!matched) {
          if (traversalKind === "Allows") return false;
          if (this.undeclared === "reject") {
            ctx.errorFromNodeContext({
              // TODO: this should have its own error code
              code: "predicate",
              expected: "removed",
              actual: "",
              relativePath: [k],
              meta: this.meta
            });
          } else {
            ctx.queueMorphs([data => {
              delete data[k];
              return data;
            }]);
          }
          if (ctx.failFast) return false;
        }
      }
    }
    return true;
  };
  compile(js) {
    if (js.traversalKind === "Apply") js.initializeErrorCount();
    this.props.forEach(prop => {
      js.check(prop);
      if (js.traversalKind === "Apply") js.returnIfFailFast();
    });
    if (this.sequence) {
      js.check(this.sequence);
      if (js.traversalKind === "Apply") js.returnIfFailFast();
    }
    if (requireExhasutiveTraversal(this, js.traversalKind)) {
      js.const("keys", "Object.keys(data)");
      js.line("keys.push(...Object.getOwnPropertySymbols(data))");
      js.for("i < keys.length", () => this.compileExhaustiveEntry(js));
    }
    if (js.traversalKind === "Allows") js.return(true);
  }
  compileExhaustiveEntry(js) {
    js.const("k", "keys[i]");
    if (this.undeclared) js.let("matched", false);
    this.index?.forEach(node => {
      js.if(`${js.invoke(node.signature, {
        arg: "k",
        kind: "Allows"
      })}`, () => {
        js.traverseKey("k", "data[k]", node.value);
        if (this.undeclared) js.set("matched", true);
        return js;
      });
    });
    if (this.undeclared) {
      if (this.props?.length !== 0) js.line(`matched ||= k in ${this.propsByKeyReference}`);
      if (this.sequence) {
        js.line(`matched ||= typeof k === "string" && ${arrayIndexMatcherReference}.test(k)`);
      }
      js.if("!matched", () => {
        if (js.traversalKind === "Allows") return js.return(false);
        return this.undeclared === "reject" ? js.line(
        // TODO: should have its own error code
        `ctx.errorFromNodeContext({ code: "predicate", expected: "removed", actual: "", relativePath: [k], meta: ${this.compiledMeta} })`).if("ctx.failFast", () => js.return()) : js.line(`ctx.queueMorphs([data => { delete data[k]; return data }])`);
      });
    }
    return js;
  }
  reduceJsonSchema(schema) {
    switch (schema.type) {
      case "object":
        return this.reduceObjectJsonSchema(schema);
      case "array":
        if (this.props.length || this.index) {
          return JsonSchema.throwUnjsonifiableError(`Additional properties on array ${this.expression}`);
        }
        return this.sequence?.reduceJsonSchema(schema) ?? schema;
      default:
        return JsonSchema.throwInternalOperandError("structure", schema);
    }
  }
  reduceObjectJsonSchema(schema) {
    if (this.props.length) {
      schema.properties = {};
      this.props.forEach(prop => {
        if (typeof prop.key === "symbol") {
          return JsonSchema.throwUnjsonifiableError(`Symbolic key ${prop.serializedKey}`);
        }
        schema.properties[prop.key] = prop.value.toJsonSchema();
      });
      if (this.requiredKeys.length) schema.required = this.requiredKeys;
    }
    this.index?.forEach(index => {
      if (index.signature.equals($ark.intrinsic.string)) return schema.additionalProperties = index.value.toJsonSchema();
      if (!index.signature.extends($ark.intrinsic.string)) {
        return JsonSchema.throwUnjsonifiableError(`Symbolic index signature ${index.signature.exclude($ark.intrinsic.string)}`);
      }
      index.signature.branches.forEach(keyBranch => {
        if (!keyBranch.hasKind("intersection") || keyBranch.inner.pattern?.length !== 1) {
          return JsonSchema.throwUnjsonifiableError(`Index signature ${keyBranch}`);
        }
        schema.patternProperties ??= {};
        schema.patternProperties[keyBranch.inner.pattern[0].rule] = index.value.toJsonSchema();
      });
    });
    if (this.undeclared && !schema.additionalProperties) schema.additionalProperties = false;
    return schema;
  }
}
export const Structure = {
  implementation,
  Node: StructureNode
};
const requireExhasutiveTraversal = (node, traversalKind) => {
  if (node.index || node.undeclared === "reject") return true;
  // when applying key deletion, we must queue morphs for all undeclared keys
  // when checking whether an input is allowed, they are irrelevant because it always will be
  if (node.undeclared === "delete" && traversalKind === "Apply") return true;
  return false;
};
const indexerToKey = indexable => {
  if (hasArkKind(indexable, "root") && indexable.hasKind("unit")) indexable = indexable.unit;
  if (typeof indexable === "number") indexable = `${indexable}`;
  return indexable;
};
export const writeNumberIndexMessage = (indexExpression, sequenceExpression) => `${indexExpression} is not allowed as an array index on ${sequenceExpression}. Use the 'nonNegativeIntegerString' keyword instead.`;
/** extract enumerable named props from an index signature */
export const normalizeIndex = (signature, value, $) => {
  const [enumerableBranches, nonEnumerableBranches] = spliterate(signature.branches, k => k.hasKind("unit"));
  if (!enumerableBranches.length) return {
    index: $.node("index", {
      signature,
      value
    })
  };
  const normalized = {};
  enumerableBranches.forEach(n => {
    // since required can be reduced to optional if it has a default or
    // optional meta on its value, we have to assign it depending on the
    // compiled kind
    const prop = $.node("required", {
      key: n.unit,
      value
    });
    normalized[prop.kind] = append(normalized[prop.kind], prop);
  });
  if (nonEnumerableBranches.length) {
    normalized.index = $.node("index", {
      signature: nonEnumerableBranches,
      value
    });
  }
  return normalized;
};
export const typeKeyToString = k => hasArkKind(k, "root") ? k.expression : printable(k);
export const writeInvalidKeysMessage = (o, keys) => `Key${keys.length === 1 ? "" : "s"} ${keys.map(typeKeyToString).join(", ")} ${keys.length === 1 ? "does" : "do"} not exist on ${o}`;