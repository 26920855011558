import { isKeyOf, throwInternalError, throwParseError } from "@ark/util";
import { parseOperand } from "../shift/operand/operand.js";
import { parseOperator } from "../shift/operator/operator.js";
import { parseUntilFinalizer } from "../string.js";
import { invertedComparators, minComparators, writeMultipleLeftBoundsMessage, writeOpenRangeMessage, writeUnclosedGroupMessage, writeUnmatchedGroupCloseMessage, writeUnpairableComparatorMessage } from "./shared.js";
export class DynamicState {
  // set root type to `any` so that all constraints can be applied
  root;
  branches = {
    prefixes: [],
    leftBound: null,
    intersection: null,
    union: null
  };
  finalizer;
  groups = [];
  scanner;
  ctx;
  constructor(scanner, ctx) {
    this.scanner = scanner;
    this.ctx = ctx;
  }
  error(message) {
    return throwParseError(message);
  }
  hasRoot() {
    return this.root !== undefined;
  }
  setRoot(root) {
    this.root = root;
  }
  unsetRoot() {
    const value = this.root;
    this.root = undefined;
    return value;
  }
  constrainRoot(...args) {
    this.root = this.root.constrain(args[0], args[1]);
  }
  finalize(finalizer) {
    if (this.groups.length) return this.error(writeUnclosedGroupMessage(")"));
    this.finalizeBranches();
    this.finalizer = finalizer;
  }
  reduceLeftBound(limit, comparator) {
    const invertedComparator = invertedComparators[comparator];
    if (!isKeyOf(invertedComparator, minComparators)) return this.error(writeUnpairableComparatorMessage(comparator));
    if (this.branches.leftBound) {
      return this.error(writeMultipleLeftBoundsMessage(this.branches.leftBound.limit, this.branches.leftBound.comparator, limit, invertedComparator));
    }
    this.branches.leftBound = {
      comparator: invertedComparator,
      limit
    };
  }
  finalizeBranches() {
    this.assertRangeUnset();
    if (this.branches.union) {
      this.pushRootToBranch("|");
      this.root = this.branches.union;
    } else if (this.branches.intersection) {
      this.pushRootToBranch("&");
      this.root = this.branches.intersection;
    } else this.applyPrefixes();
  }
  finalizeGroup() {
    this.finalizeBranches();
    const topBranchState = this.groups.pop();
    if (!topBranchState) return this.error(writeUnmatchedGroupCloseMessage(this.scanner.unscanned));
    this.branches = topBranchState;
  }
  addPrefix(prefix) {
    this.branches.prefixes.push(prefix);
  }
  applyPrefixes() {
    while (this.branches.prefixes.length) {
      const lastPrefix = this.branches.prefixes.pop();
      this.root = lastPrefix === "keyof" ? this.root.keyof() : throwInternalError(`Unexpected prefix '${lastPrefix}'`);
    }
  }
  pushRootToBranch(token) {
    this.assertRangeUnset();
    this.applyPrefixes();
    const root = this.root;
    this.branches.intersection = this.branches.intersection?.rawAnd(root) ?? root;
    if (token === "|") {
      this.branches.union = this.branches.union?.rawOr(this.branches.intersection) ?? this.branches.intersection;
      this.branches.intersection = null;
    }
    this.root = undefined;
  }
  parseUntilFinalizer() {
    return parseUntilFinalizer(new DynamicState(this.scanner, this.ctx));
  }
  parseOperator() {
    return parseOperator(this);
  }
  parseOperand() {
    return parseOperand(this);
  }
  assertRangeUnset() {
    if (this.branches.leftBound) {
      return this.error(writeOpenRangeMessage(this.branches.leftBound.limit, this.branches.leftBound.comparator));
    }
  }
  reduceGroupOpen() {
    this.groups.push(this.branches);
    this.branches = {
      prefixes: [],
      leftBound: null,
      union: null,
      intersection: null
    };
  }
  previousOperator() {
    return this.branches.leftBound?.comparator ?? this.branches.prefixes.at(-1) ?? (this.branches.intersection ? "&" : this.branches.union ? "|" : undefined);
  }
  shiftedByOne() {
    this.scanner.shift();
    return this;
  }
}