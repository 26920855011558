import { Disjoint, intersectNodesRoot } from "@ark/schema";
import { objectKindOrDomainOf, throwParseError } from "@ark/util";
import { shallowDefaultableMessage, shallowOptionalMessage } from "./ast/validate.js";
import { writeMissingRightOperandMessage } from "./shift/operand/unenclosed.js";
export const maybeParseTupleExpression = (def, ctx) => isIndexZeroExpression(def) ? prefixParsers[def[0]](def, ctx) : isIndexOneExpression(def) ? indexOneParsers[def[1]](def, ctx) : null;
export const parseKeyOfTuple = (def, ctx) => ctx.$.parseOwnDefinitionFormat(def[1], ctx).keyof();
const parseBranchTuple = (def, ctx) => {
  if (def[2] === undefined) return throwParseError(writeMissingRightOperandMessage(def[1], ""));
  const l = ctx.$.parseOwnDefinitionFormat(def[0], ctx);
  const r = ctx.$.parseOwnDefinitionFormat(def[2], ctx);
  if (def[1] === "|") return ctx.$.node("union", {
    branches: [l, r]
  });
  const result = intersectNodesRoot(l, r, ctx.$);
  if (result instanceof Disjoint) return result.throw();
  return result;
};
const parseArrayTuple = (def, ctx) => ctx.$.parseOwnDefinitionFormat(def[0], ctx).array();
const isIndexOneExpression = def => indexOneParsers[def[1]] !== undefined;
export const parseMorphTuple = (def, ctx) => {
  if (typeof def[2] !== "function") {
    return throwParseError(writeMalformedFunctionalExpressionMessage("=>", def[2]));
  }
  return ctx.$.parseOwnDefinitionFormat(def[0], ctx).pipe(def[2]);
};
export const writeMalformedFunctionalExpressionMessage = (operator, value) => `${operator === ":" ? "Narrow" : "Morph"} expression requires a function following '${operator}' (was ${typeof value})`;
export const parseNarrowTuple = (def, ctx) => {
  if (typeof def[2] !== "function") {
    return throwParseError(writeMalformedFunctionalExpressionMessage(":", def[2]));
  }
  return ctx.$.parseOwnDefinitionFormat(def[0], ctx).constrain("predicate", def[2]);
};
const parseAttributeTuple = (def, ctx) => ctx.$.parseOwnDefinitionFormat(def[0], ctx).configureShallowDescendants(def[2]);
const indexOneParsers = {
  "[]": parseArrayTuple,
  "|": parseBranchTuple,
  "&": parseBranchTuple,
  ":": parseNarrowTuple,
  "=>": parseMorphTuple,
  "@": parseAttributeTuple,
  // since object and tuple literals parse there via `parseProperty`,
  // they must be shallow if parsed directly as a tuple expression
  "=": () => throwParseError(shallowDefaultableMessage),
  "?": () => throwParseError(shallowOptionalMessage)
};
const prefixParsers = {
  keyof: parseKeyOfTuple,
  instanceof: (def, ctx) => {
    if (typeof def[1] !== "function") {
      return throwParseError(writeInvalidConstructorMessage(objectKindOrDomainOf(def[1])));
    }
    const branches = def.slice(1).map(ctor => typeof ctor === "function" ? ctx.$.node("proto", {
      proto: ctor
    }) : throwParseError(writeInvalidConstructorMessage(objectKindOrDomainOf(ctor))));
    return branches.length === 1 ? branches[0] : ctx.$.node("union", {
      branches
    });
  },
  "===": (def, ctx) => ctx.$.units(def.slice(1))
};
const isIndexZeroExpression = def => prefixParsers[def[0]] !== undefined;
export const writeInvalidConstructorMessage = actual => `Expected a constructor following 'instanceof' operator (was ${actual})`;