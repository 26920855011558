<h1 class="text-3xl font-bold 600 py-4 px-1 content__title">Subsets List</h1>

@if (showModalDeleteSubset()) {
  <app-modal
    (confirm)="onDeletionSubset($event, subsetToDelete()?.uuid)"
    (close)="onClosingDeletionSubset()"
    [title]="'Delete a subset'"
    [content]="'You are about to delete this subset, do you want to continue?'"
    [cancelButton]="true"
    [confirmButton]="true"
    [disableButtons]="isDeletingSubset()"
  ></app-modal>
}

<!-- Search -->
<aside class="flex items-center py-2">
  <div class="relative w-96 px-1">
    <input
      type="text"
      placeholder="Search for a subset name, Id..."
      class="w-full p-2 pr-10 bg-white border border-stone-300 gap-2.5 rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-500 text-stone-400 text-sm content__text"
      (keydown)="onKeydown($event)"
      [formControl]="searchSubset"
    />
    <button class="absolute right-3.5 top-[8px]" (click)="performSearch()">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        class="w-5 h-5 text-gray-500 hover:text-gray-700"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path d="M22 20L20 22 14 16 14 14 16 14z"></path>
        <path
          d="M9,16c-3.9,0-7-3.1-7-7c0-3.9,3.1-7,7-7c3.9,0,7,3.1,7,7C16,12.9,12.9,16,9,16z M9,4C6.2,4,4,6.2,4,9c0,2.8,2.2,5,5,5 c2.8,0,5-2.2,5-5C14,6.2,11.8,4,9,4z"
        ></path>
        <path d="M13.7 12.5H14.7V16H13.7z" transform="rotate(-44.992 14.25 14.25)"></path>
      </svg>
    </button>
  </div>

  <div class="relative w-24">
    <select
      class="block appearance-none w-full bg-white border border-blue-950 px-4 py-2 pr-8 rounded-xl leading-tight focus:outline-none focus:shadow-outline text-blue-950 content__text"
    >
      <option>{{ getYearOfConsumption() }}</option>
      <option>{{ getYearOfConsumptionMinusOne() }}</option>
    </select>
    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
      <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <path d="M5.293 7.293L10 12l4.707-4.707-1.414-1.414L10 9.172l-3.293-3.293z" />
      </svg>
    </div>
  </div>

  <a
    class="flex items-center bg-blue-950 ml-auto text-white py-2 px-4 rounded-xl hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 content__text"
    routerLink="/new-subset"
  >
    Create a new subset
    <svg class="w-4 h-4 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4"></path>
    </svg>
  </a>
</aside>

<!-- Results -->
<article>
  <table class="w-full h-9 mt-5">
    <thead class="border-b border-zinc-500">
      <tr class="text-left">
        <th class="text-zinc-500 text-sm content__text px-2">Subset name</th>
        <th class="text-zinc-500 text-sm content__text">
          <button (click)="displayStatusFilter()">
            <span class="pointer-events-none inset-y-0 right-0 px-2 inline-flex">
              id
              <svg
                class="fill-current h-4 w-4 text-gray-700 mt-auto"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M5.293 7.293L10 12l4.707-4.707-1.414-1.414L10 9.172l-3.293-3.293z" />
              </svg>
            </span>
          </button>
        </th>
        <th class="text-zinc-500 text-sm content__text">
          <span class="pointer-events-none inset-y-0 right-0 px-2 inline-flex"> Scope </span>
        </th>
        <th class="text-zinc-500 text-sm content__text">
          <button (click)="displayResponsibleFilter()">
            <span class="pointer-events-none inset-y-0 right-0 px-2 inline-flex">
              Responsible
              <svg
                class="fill-current h-4 w-4 text-gray-700 mt-auto"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M5.293 7.293L10 12l4.707-4.707-1.414-1.414L10 9.172l-3.293-3.293z" />
              </svg>
            </span>
          </button>

          <!-- popup filter responsible -->
          <div [formGroup]="responsibleForm" class="popup-filter pt-2" *ngIf="isResponsibleFilterPopupDisplayed()">
            <div
              formArrayName="checkboxesResponsible"
              class="w-[200px] py-2.5 bg-white rounded-xl shadow flex-col justify-start inline-flex"
            >
              @for (responsible of checkboxesResponsible.controls; track i; let i = $index) {
                <div class="w-[200px] px-[30px] py-2.5 bg-white justify-start items-center gap-5 inline-flex">
                  <input
                    type="checkbox"
                    [id]="i"
                    class="w-[20px] h-[20px] relative bg-white rounded-md border border-black checkbox"
                    [formControlName]="i"
                    (change)="onCheckboxResponsibleChange(i)"
                    [value]="responsible.value"
                  />
                  <label [for]="i" class="text-black text-l content__text checkbox__text">{{
                    getResponsibleName(i)
                  }}</label>
                </div>
              }
            </div>
          </div>
        </th>
        <th class="text-zinc-500 text-sm content__text text-center">
          <button (click)="displayStatusFilter()">
            <span class="pointer-events-none inset-y-0 right-0 px-2 inline-flex">
              Status
              <svg
                class="fill-current h-4 w-4 text-gray-700 mt-auto"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M5.293 7.293L10 12l4.707-4.707-1.414-1.414L10 9.172l-3.293-3.293z" />
              </svg>
            </span>
          </button>

          <!-- popup filter status -->
          <div [formGroup]="statusForm" class="popup-filter pt-2" *ngIf="isStatusFilterPopupDisplayed()">
            <div
              formArrayName="checkboxesStatus"
              class="w-[200px] py-2.5 bg-white rounded-xl shadow flex-col justify-start inline-flex"
            >
              @for (status of checkboxesStatus.controls; track i; let i = $index) {
                <div class="w-[200px] px-[30px] py-2.5 bg-white justify-start items-center gap-5 inline-flex">
                  <input
                    type="checkbox"
                    [id]="i"
                    class="w-[20px] h-[20px] relative bg-white rounded-md border border-black checkbox"
                    [formControlName]="i"
                    (change)="onCheckboxStatusChange(i)"
                    [value]="status.value"
                  />
                  <label [for]="i" class="text-black text-l content__text checkbox__text">{{
                    getStatusLabel(i)
                  }}</label>
                </div>
              }
            </div>
          </div>
        </th>
        <th class="text-zinc-500 text-sm content__text">
          <button (click)="displayStatusFilter()">
            <span class="pointer-events-none inset-y-0 right-0 px-2 inline-flex">
              Status date
              <svg
                class="fill-current h-4 w-4 text-gray-700 mt-auto"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M5.293 7.293L10 12l4.707-4.707-1.414-1.414L10 9.172l-3.293-3.293z" />
              </svg>
            </span>
          </button>
        </th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      @for (subset of subsetsDisplay(); track subset.uuid) {
        <tr class="content">
          <td
            class="relative text-blue-950 content__text h-8 pl-2 py-2 cursor-pointer"
            (click)="goToSubset(subset.uuid)"
          >
            {{ subset.name }}
          </td>
          <td class="text-blue-950 content__text pl-2 py-2 cursor-pointer" (click)="goToSubset(subset.uuid)">
            {{ subset.userSubsetId }}
          </td>
          <td
            class="relative text-blue-950 content__text h-8 pl-2 py-2 max-w-[200px] cursor-pointer"
            (mouseleave)="onMouseLeave()"
            (click)="goToSubset(subset.uuid)"
          >
            <span class="flex" (mouseenter)="onMouseEnter(subset.uuid)">{{ subset.scopesShort }}</span>

            <!-- scope popup -->
            <div class="popup" *ngIf="scopeHovered(subset.uuid)">
              <div
                class="w-[400px] min-h-[80px] max-h-[230px] p-5 bg-gray-300 rounded-xl shadow flex-col justify-start items-start gap-5 inline-flex overflow-y-scroll"
              >
                <div class="text-blue-950 text-base font-semibold content__text">Scope</div>
                <div class="w-full flex flex-wrap">
                  <div class="justify-start items-start gap-5 flex flex-wrap">
                    @for (scope of subset.scopes; track i; let i = $index) {
                      <div class="px-1.5 py-2.5 bg-blue-950 rounded-[100px] justify-center items-center gap-1.5 flex">
                        <div class="px-1 py-0.5 bg-slate-500 rounded-[20px] justify-center items-center gap-1.5 flex">
                          <div class="text-white text-sm content__text">{{ scope.plantCode }}</div>
                        </div>
                        <div class="text-white text-sm content__text">{{ scope.name }}</div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </td>
          <td
            class="relative text-blue-950 content__text h-8 pl-2 py-2 cursor-pointer"
            (click)="goToSubset(subset.uuid)"
          >
            {{ subset.responsible.getName() }}
          </td>
          <td
            class="relative text-blue-950 content__text h-8 pl-2 text-center py-2 cursor-pointer"
            (click)="goToSubset(subset.uuid)"
          >
            <app-subset-status-text status="{{ subset.status }}"></app-subset-status-text>
          </td>
          <td
            class="relative text-blue-950 content__text h-8 pl-2 py-2 cursor-pointer"
            (click)="goToSubset(subset.uuid)"
          >
            {{ subset.statusDate | date: 'dd/MM/YYYY' }}
          </td>
          <td [ngClass]="canUserDeleteSubset() && canSubsetBeDeleted(subset) ? 'cursor-default' : 'cursor-pointer'">
            @if (canUserDeleteSubset() && canSubsetBeDeleted(subset)) {
              <button (click)="startDeleteSubset(subset.uuid)" class="z-40">
                <svg width="1rem" height="1rem" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                  <g id="SVGRepo_iconCarrier">
                    <path
                      d="M6.96967 16.4697C6.67678 16.7626 6.67678 17.2374 6.96967 17.5303C7.26256 17.8232 7.73744 17.8232 8.03033 17.5303L6.96967 16.4697ZM13.0303 12.5303C13.3232 12.2374 13.3232 11.7626 13.0303 11.4697C12.7374 11.1768 12.2626 11.1768 11.9697 11.4697L13.0303 12.5303ZM11.9697 11.4697C11.6768 11.7626 11.6768 12.2374 11.9697 12.5303C12.2626 12.8232 12.7374 12.8232 13.0303 12.5303L11.9697 11.4697ZM18.0303 7.53033C18.3232 7.23744 18.3232 6.76256 18.0303 6.46967C17.7374 6.17678 17.2626 6.17678 16.9697 6.46967L18.0303 7.53033ZM13.0303 11.4697C12.7374 11.1768 12.2626 11.1768 11.9697 11.4697C11.6768 11.7626 11.6768 12.2374 11.9697 12.5303L13.0303 11.4697ZM16.9697 17.5303C17.2626 17.8232 17.7374 17.8232 18.0303 17.5303C18.3232 17.2374 18.3232 16.7626 18.0303 16.4697L16.9697 17.5303ZM11.9697 12.5303C12.2626 12.8232 12.7374 12.8232 13.0303 12.5303C13.3232 12.2374 13.3232 11.7626 13.0303 11.4697L11.9697 12.5303ZM8.03033 6.46967C7.73744 6.17678 7.26256 6.17678 6.96967 6.46967C6.67678 6.76256 6.67678 7.23744 6.96967 7.53033L8.03033 6.46967ZM8.03033 17.5303L13.0303 12.5303L11.9697 11.4697L6.96967 16.4697L8.03033 17.5303ZM13.0303 12.5303L18.0303 7.53033L16.9697 6.46967L11.9697 11.4697L13.0303 12.5303ZM11.9697 12.5303L16.9697 17.5303L18.0303 16.4697L13.0303 11.4697L11.9697 12.5303ZM13.0303 11.4697L8.03033 6.46967L6.96967 7.53033L11.9697 12.5303L13.0303 11.4697Z"
                      fill="#fa0000"
                    ></path>
                  </g>
                </svg>
              </button>
            }
          </td>
        </tr>
      }
    </tbody>
  </table>
</article>
