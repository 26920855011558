import { Component, input } from '@angular/core';
import { Row } from '@tanstack/angular-table';
import { EfaItemDTO } from '../../../services/efa-items/efa-items.service';
import { GenericGroupedTableRow } from '../../../utils/components/table/table.component';

@Component({
  selector: 'app-grouped-item-table-row',
  standalone: true,
  template: `
    <div class="inline-flex justify-between items-center w-full py-1">
      <div>{{ row().original.item_type }} {{ row().original.user_item_code }} {{ row().original.item_name }}</div>
      <div class="inline-flex items-center">
        <button
          (click)="handleClick($event)"
          class="bg-slate-600 hover:bg-slate-500 text-white rounded-lg py-2 px-4 text-xs mr-6"
        >
          Add Item Supply
        </button>
        <div class="min-w-10">({{ noSubRows() }})</div>
      </div>
    </div>
  `,
})
export class EfaGroupedItemTableRow implements GenericGroupedTableRow<EfaItemDTO> {
  row = input.required<Row<EfaItemDTO>>();
  noSubRows = input.required<number>();
  actionButtonCallback = input<(row: Row<EfaItemDTO>) => void>(() => {});

  handleClick($event: MouseEvent) {
    $event.stopPropagation();
    this.actionButtonCallback()(this.row());
  }
}
