import { Component, inject, input } from '@angular/core';
import { EfaScopeService } from '../../../../services/efa-scope/efa-scope.service';
import { ResponsibleService } from '../../../../services/responsible/responsible.service';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { EfaFormGroup, EfaFormNames } from '../../efa-management.types';
import { FormInputComponent } from '../../../../utils/components/form/input.component';
import { FormSelectComponent } from '../../../../utils/components/form/select.component';

@Component({
  selector: 'app-efa-add-edit-model-item-supply-fields',
  standalone: true,
  imports: [ReactiveFormsModule, FormInputComponent, FormSelectComponent],
  template: `<form [formGroup]="formGroup" class="flex flex-wrap [&>*]:w-full mb-4">
    <div class="inline-flex [&>*]:w-1/2 gap-5">
      <div>
        <app-form-select [group]="formGroup" label="Scope Type" controlName="scopeType" [options]="scopeTypeOptions">
        </app-form-select>
      </div>
      <div class="mb-10">
        <app-form-select [group]="formGroup" label="Scope Code" controlName="scopeCode" [options]="scopeCodeOptions">
        </app-form-select>
      </div>
    </div>
    <app-form-input label="Processus Label" [group]="formGroup" controlName="processusLabel"></app-form-input>
    <app-form-input label="Data Source" [group]="formGroup" controlName="dataSource"></app-form-input>
    <app-form-select
      [group]="formGroup"
      label="Responsible"
      controlName="responsible"
      [options]="scopeResponsibleOptions"
    >
    </app-form-select>
  </form>`,
})
export class EfaAddEditModelItemSupplyFieldsComponent {
  private efaScopeService = inject(EfaScopeService);
  private responsibleService = inject(ResponsibleService);

  public efaForm = input.required<FormGroup<EfaFormGroup>>();

  public responsiblesByIdSignal = this.responsibleService.retrieveResponsiblesByIdSignal();

  public efaScopesByScopeParentSignal = this.efaScopeService.retrieveEfaScopeByScopeParentSignal();

  get formGroup() {
    return this.efaForm().controls.itemSupply;
  }

  get scopeTypeOptions() {
    return Object.keys(this.efaScopesByScopeParentSignal()).map((value) => ({ value, label: value }));
  }

  get scopeCodeOptions() {
    const scopeTypeSelected = this.efaForm().controls[EfaFormNames.ITEM_SUPPLY].controls[EfaFormNames.SCOPE_TYPE].value;
    return (this.efaScopesByScopeParentSignal()[scopeTypeSelected] ?? []).map((value) => ({ value, label: value }));
  }

  get scopeResponsibleOptions() {
    return Object.keys(this.responsiblesByIdSignal()).map((value) => ({
      value,
      label: this.getResponsibleLabel(value),
    }));
  }

  public getResponsibleLabel(responsibleUuid: string) {
    const responsible = this.responsiblesByIdSignal()[responsibleUuid];
    if (!responsible) {
      return responsibleUuid;
    }
    return `${responsible.firstName} ${responsible.lastName}`;
  }
}
