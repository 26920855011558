import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ChangePageEvent extends Event {
  static triggerFullName = 'window:cactus__changepage';
  static trigger = 'cactus__changepage';
  private _nextRoute = '';

  set nextRoute(value: string) {
    this._nextRoute = value;
  }

  get nextRoute() {
    return this._nextRoute;
  }

  constructor() {
    super(ChangePageEvent.trigger, { cancelable: true });
  }

  canDeactivate(): boolean {
    return window.confirm('There are unsaved changes. Do you want to discard changes?');
  }

  generateEvent() {
    return this;
  }
}
