import { EfaItemDTO } from '../../services/efa-items/efa-items.service';
import { EfaTableFormNames } from './efa-management.types';

// ** Columns that are grouped must be visible by default, but are removed programatically by the table
export const defaultTableViews: {
  [EfaTableFormNames.GROUPING]: Array<keyof EfaItemDTO> | null;
  [EfaTableFormNames.COLUMN_VISIBILITY]: Record<keyof EfaItemDTO, boolean>;
}[] = [
  {
    [EfaTableFormNames.GROUPING]: ['user_item_code'],
    [EfaTableFormNames.COLUMN_VISIBILITY]: {
      source_erp_code: false,
      item_code: false,
      user_item_code: true, // **
      item_name: false,
      item_type: false,
      item_unit_code: false,
      item_supply_uuid: false,
      scope_type: true,
      scope_code: true,
      processus_label: true,
      data_source: true,
      responsible: true,
      indicator_value: true,
      indicator_code: true,
    },
  },
  {
    [EfaTableFormNames.GROUPING]: ['user_item_code', 'item_supply_uuid'],
    [EfaTableFormNames.COLUMN_VISIBILITY]: {
      source_erp_code: false,
      item_code: false,
      user_item_code: true, // **
      item_name: false,
      item_type: false,
      item_unit_code: false,
      item_supply_uuid: true, // **
      scope_type: true,
      scope_code: true,
      processus_label: false,
      data_source: false,
      responsible: false,
      indicator_value: true,
      indicator_code: true,
    },
  },
  {
    [EfaTableFormNames.GROUPING]: null,
    [EfaTableFormNames.COLUMN_VISIBILITY]: {
      source_erp_code: true,
      item_code: false,
      user_item_code: true,
      item_name: true,
      item_type: true,
      item_unit_code: true,
      item_supply_uuid: false,
      scope_type: true,
      scope_code: true,
      processus_label: true,
      data_source: true,
      responsible: true,
      indicator_value: true,
      indicator_code: true,
    },
  },
];
