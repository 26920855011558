import { Component, input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-form-select',
  standalone: true,
  imports: [ReactiveFormsModule],
  template: `
    <label [for]="'select_' + label()" class="mt-6" [formGroup]="group()">
      {{ label() }}
      <select [id]="'select_' + label()" class="w-full h-10" [formControlName]="controlName()">
        @for (option of this.options(); track option.value) {
          <option [value]="option.value">{{ option.label }}</option>
        }
      </select>
    </label>
    <p class="text-red-600">{{ error }}</p>
  `,
})
export class FormSelectComponent {
  group = input.required<FormGroup>();
  controlName = input.required<string>();
  label = input.required<string>();
  options = input.required<{ value: string; label: string }[]>();

  public get error() {
    return this.group().controls[this.controlName()].errors?.['error'] ?? '';
  }
}
