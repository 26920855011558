import { Component, input } from '@angular/core';
import { Row } from '@tanstack/angular-table';
import { EfaItemDTO } from '../../../services/efa-items/efa-items.service';
import { GenericGroupedTableRow } from '../../../utils/components/table/table.component';

@Component({
  selector: 'app-grouped-item-supply-table-row',
  standalone: true,
  imports: [],
  template: `
    <div class="inline-flex justify-between items-center w-full py-1">
      <div>{{ row().original.scope_code }} {{ row().original.scope_type }} {{ row().original.processus_label }}</div>
      <div>
        <div class="min-w-10">({{ noSubRows() }})</div>
      </div>
    </div>
  `,
})
export class EfaGroupedItemSupplyTableRow implements GenericGroupedTableRow<EfaItemDTO> {
  row = input.required<Row<EfaItemDTO>>();
  noSubRows = input.required<number>();
  actionButtonCallback = input<(row: Row<EfaItemDTO>) => void>(() => {});
}
