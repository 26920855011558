import { Callable, appendUnique, flatMorph, includes, isArray, isEmptyObject, stringifyPath, throwError } from "@ark/util";
import { basisKinds, constraintKinds, precedenceOfKind, refinementKinds, rootKinds, structuralKinds } from "./shared/implement.js";
import { $ark } from "./shared/registry.js";
import { Traversal } from "./shared/traversal.js";
import { isNode } from "./shared/utils.js";
export class BaseNode extends Callable {
  attachments;
  $;
  constructor(attachments, $) {
    super((data, pipedFromCtx) => {
      if (!this.includesMorph && !this.allowsRequiresContext && this.allows(data)) return data;
      if (pipedFromCtx) {
        this.traverseApply(data, pipedFromCtx);
        return pipedFromCtx.hasError() ? pipedFromCtx.errors : pipedFromCtx.data;
      }
      const ctx = new Traversal(data, this.$.resolvedConfig);
      this.traverseApply(data, ctx);
      return ctx.finalize();
    }, {
      attach: attachments
    });
    this.attachments = attachments;
    this.$ = $;
  }
  withMeta(meta) {
    return this.$.node(this.kind, {
      ...this.inner,
      meta: typeof meta === "function" ? meta({
        ...this.meta
      }) : meta
    });
  }
  includesMorph = this.kind === "morph" || this.hasKind("optional") && this.hasDefault() || this.hasKind("sequence") && this.includesDefaultable() || this.hasKind("structure") && this.inner.undeclared === "delete" || this.children.some(child => child.includesMorph);
  hasContextualPredicate =
  // if a predicate accepts exactly one arg, we can safely skip passing context
  this.hasKind("predicate") && this.inner.predicate.length !== 1 || this.children.some(child => child.hasContextualPredicate);
  isCyclic = this.kind === "alias" || this.children.some(child => child.isCyclic);
  allowsRequiresContext = this.hasContextualPredicate || this.isCyclic;
  referencesById = this.children.reduce((result, child) => Object.assign(result, child.referencesById), {
    [this.id]: this
  });
  compiledMeta = JSON.stringify(this.metaJson);
  cacheGetter(name, value) {
    Object.defineProperty(this, name, {
      value
    });
    return value;
  }
  get description() {
    return this.cacheGetter("description", this.meta?.description ?? this.$.resolvedConfig[this.kind].description(this));
  }
  // we don't cache this currently since it can be updated once a scope finishes
  // resolving cyclic references, although it may be possible to ensure it is cached safely
  get references() {
    return Object.values(this.referencesById);
  }
  get shallowReferences() {
    return this.cacheGetter("shallowReferences", this.hasKind("structure") ? [this, ...this.children] : this.children.reduce((acc, child) => appendUniqueNodes(acc, child.shallowReferences), [this]));
  }
  get shallowMorphs() {
    return this.cacheGetter("shallowMorphs", this.shallowReferences.filter(n => n.hasKind("morph")).sort((l, r) => l.expression < r.expression ? -1 : 1));
  }
  // overriden by structural kinds so that only the root at each path is added
  get flatRefs() {
    return this.cacheGetter("flatRefs", this.children.reduce((acc, child) => appendUniqueFlatRefs(acc, child.flatRefs), []).sort((l, r) => l.path.length > r.path.length ? 1 : l.path.length < r.path.length ? -1 : l.propString > r.propString ? 1 : l.propString < r.propString ? -1 : l.node.expression < r.node.expression ? -1 : 1));
  }
  precedence = precedenceOfKind(this.kind);
  precompilation;
  allows = data => {
    if (this.allowsRequiresContext) {
      return this.traverseAllows(data, new Traversal(data, this.$.resolvedConfig));
    }
    return this.traverseAllows(data);
  };
  traverse(data) {
    return this(data);
  }
  get in() {
    return this.cacheGetter("in", this.getIo("in"));
  }
  get out() {
    return this.cacheGetter("out", this.getIo("out"));
  }
  // Should be refactored to use transform
  // https://github.com/arktypeio/arktype/issues/1020
  getIo(ioKind) {
    if (!this.includesMorph) return this;
    const ioInner = {};
    for (const [k, v] of this.innerEntries) {
      const keySchemaImplementation = this.impl.keys[k];
      if (keySchemaImplementation.reduceIo) keySchemaImplementation.reduceIo(ioKind, ioInner, v);else if (keySchemaImplementation.child) {
        const childValue = v;
        ioInner[k] = isArray(childValue) ? childValue.map(child => child[ioKind]) : childValue[ioKind];
      } else ioInner[k] = v;
    }
    return this.$.node(this.kind, ioInner);
  }
  toJSON() {
    return this.json;
  }
  toString() {
    return this.expression;
  }
  equals(r) {
    const rNode = isNode(r) ? r : this.$.parseDefinition(r);
    return this.innerHash === rNode.innerHash;
  }
  ifEquals(r) {
    return this.equals(r) ? this : undefined;
  }
  hasKind(kind) {
    return this.kind === kind;
  }
  assertHasKind(kind) {
    if (this.kind !== kind) throwError(`${this.kind} node was not of asserted kind ${kind}`);
    return this;
  }
  hasKindIn(...kinds) {
    return kinds.includes(this.kind);
  }
  assertHasKindIn(...kinds) {
    if (!includes(kinds, this.kind)) throwError(`${this.kind} node was not one of asserted kinds ${kinds}`);
    return this;
  }
  isBasis() {
    return includes(basisKinds, this.kind);
  }
  isConstraint() {
    return includes(constraintKinds, this.kind);
  }
  isStructural() {
    return includes(structuralKinds, this.kind);
  }
  isRefinement() {
    return includes(refinementKinds, this.kind);
  }
  isRoot() {
    return includes(rootKinds, this.kind);
  }
  isUnknown() {
    return this.hasKind("intersection") && this.children.length === 0;
  }
  isNever() {
    return this.hasKind("union") && this.children.length === 0;
  }
  hasUnit(value) {
    return this.hasKind("unit") && this.allows(value);
  }
  hasOpenIntersection() {
    return this.impl.intersectionIsOpen;
  }
  get nestableExpression() {
    return this.expression;
  }
  firstReference(filter) {
    return this.references.find(n => n !== this && filter(n));
  }
  firstReferenceOrThrow(filter) {
    return this.firstReference(filter) ?? throwError(`${this.id} had no references matching predicate ${filter}`);
  }
  firstReferenceOfKind(kind) {
    return this.firstReference(node => node.hasKind(kind));
  }
  firstReferenceOfKindOrThrow(kind) {
    return this.firstReference(node => node.kind === kind) ?? throwError(`${this.id} had no ${kind} references`);
  }
  transform(mapper, opts) {
    return this._transform(mapper, {
      ...opts,
      seen: {},
      path: [],
      parseOptions: {
        prereduced: opts?.prereduced ?? false
      },
      undeclaredKeyHandling: undefined
    });
  }
  _transform(mapper, ctx) {
    const $ = ctx.bindScope ?? this.$;
    if (ctx.seen[this.id])
      // Cyclic handling needs to be made more robust
      // https://github.com/arktypeio/arktype/issues/944
      return this.$.lazilyResolve(ctx.seen[this.id]);
    if (ctx.shouldTransform?.(this, ctx) === false) return this;
    let transformedNode;
    ctx.seen[this.id] = () => transformedNode;
    if (this.hasKind("structure") && this.undeclared !== ctx.undeclaredKeyHandling) {
      ctx = {
        ...ctx,
        undeclaredKeyHandling: this.undeclared
      };
    }
    const innerWithTransformedChildren = flatMorph(this.inner, (k, v) => {
      if (!this.impl.keys[k].child) return [k, v];
      const children = v;
      if (!isArray(children)) {
        const transformed = children._transform(mapper, ctx);
        return transformed ? [k, transformed] : [];
      }
      // if the value was previously explicitly set to an empty list,
      // (e.g. branches for `never`), ensure it is not pruned
      if (children.length === 0) return [k, v];
      const transformed = children.flatMap(n => {
        const transformedChild = n._transform(mapper, ctx);
        return transformedChild ?? [];
      });
      return transformed.length ? [k, transformed] : [];
    });
    delete ctx.seen[this.id];
    const transformedInner = mapper(this.kind, innerWithTransformedChildren, ctx);
    if (transformedInner === null) return null;
    if (isNode(transformedInner)) return transformedNode = transformedInner;
    if (isEmptyObject(transformedInner) &&
    // if inner was previously an empty object (e.g. unknown) ensure it is not pruned
    !isEmptyObject(this.inner)) return null;
    if ((this.kind === "required" || this.kind === "optional" || this.kind === "index") && !("value" in transformedInner)) {
      return ctx.undeclaredKeyHandling ? {
        ...transformedInner,
        value: $ark.intrinsic.unknown
      } : null;
    }
    if (this.kind === "morph") {
      ;
      transformedInner.in ??= $ark.intrinsic.unknown;
    }
    return transformedNode = $.node(this.kind, transformedInner, ctx.parseOptions);
  }
  configureShallowDescendants(meta) {
    return this.$.finalize(this.transform((kind, inner) => ({
      ...inner,
      meta
    }), {
      shouldTransform: node => node.kind !== "structure"
    }));
  }
}
export const typePathToPropString = path => stringifyPath(path, {
  stringifyNonKey: node => node.expression
});
export const flatRef = (path, node) => ({
  path,
  node,
  propString: typePathToPropString(path)
});
export const flatRefsAreEqual = (l, r) => l.propString === r.propString && l.node.equals(r.node);
export const appendUniqueFlatRefs = (existing, refs) => appendUnique(existing, refs, {
  isEqual: flatRefsAreEqual
});
export const appendUniqueNodes = (existing, refs) => appendUnique(existing, refs, {
  isEqual: (l, r) => l.equals(r)
});