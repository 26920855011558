import { Injectable, Signal } from '@angular/core';
import { map, Observable } from 'rxjs';

import { CactusService } from '../cactus.service';
import { environment } from '../../../environments/environment';
import { scopeListSeed } from '../../../tests/seed/scopeList.seed';
import { ScopeModel, SubsetScope, SubsetScopeDatabase } from './model/scope.model';
import { toSignal } from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root',
})
export class ScopesService extends CactusService<SubsetScope> {
  retrievePlantCodesByIdSignal() {
    return toSignal(
      this.retrieveAllObservable().pipe(
        map((observable) =>
          observable.reduce<Record<string, SubsetScope>>((prev, curr) => ({ ...prev, [curr.plantCode]: curr }), {}),
        ),
      ),
      {
        initialValue: {} as Record<string, SubsetScope>,
      },
    );
  }

  retrieveAllObservable(): Observable<SubsetScope[]> {
    if (['test'].includes(environment.name) || this.useInMemory) {
      return new Observable<SubsetScope[]>((observer) => {
        if (!this.useInMemory) {
          this.inMemoryData = scopeListSeed;
        }
        observer.next(this.inMemoryData);
        observer.complete();
      });
    }
    return this.getSource();
  }

  private getSource() {
    if (this.useInMemory) {
      return new Observable<SubsetScope[]>((observer) => {
        observer.next(this.inMemoryData);
        observer.complete();
      });
    }
    return this.http.get<{ data: SubsetScopeDatabase[] }>(`${environment.api}retrieveScopeList`).pipe(
      map(({ data }) => {
        const inMemoryScopeList: SubsetScope[] = [];
        for (const scopeQueried of data) {
          const scope = ScopeModel.builder()
            .withPlantCode(scopeQueried.plant_code)
            .withName(scopeQueried.plant_name)
            .build();
          inMemoryScopeList.push(scope);
        }
        return this.feedWith(inMemoryScopeList);
      }),
    );
  }
}
