import { inject, Injectable } from '@angular/core';
import { delayWhen, expand, last, map, Observable, of, takeWhile } from 'rxjs';
import { HttpParams } from '@angular/common/http';

import { CactusService } from '../cactus.service';
import { environment } from '../../../environments/environment';
import { efaItemSupplyListSeed } from '../../../tests/seed/efaItemSupplyList.seed';
import { ItemSupply, ItemSupplyModel } from '../../pages/efa-management/model/itemSupply';
import { Pagination } from '../../utils/pagination/pagination';
import { ResponsibleService } from '../responsible/responsible.service';
import { Responsible } from '../responsible/model/responsible.model';

interface IRetrieveItemSupplyList {
  data: {
    itemSupplyUuid: string;
    itemCode: string;
    scopeType: string;
    scopeCode: string;
    itemSupplyStatus: string;
    updateBy: string;
    updateDate: string;
    dataSource: string;
    processusLabel: string;
    responsible: string;
    comment: string;
    indicatorCode: string;
    indicatorValue: string;
  }[];
  metadata: {
    totalCount: number;
    page: number;
    pageSize: number;
  };
}

@Injectable({
  providedIn: 'root',
})
export class EfaItemSupplyService extends CactusService<ItemSupply> {
  private responsibleService: ResponsibleService = inject(ResponsibleService);
  private pagination: Pagination = inject(Pagination);
  private _inMemoryData: ItemSupply[] = [];
  private needToFetchNextPage = false;
  private nextPage: number;

  retrieveAllObservable(responsibleList: Responsible[]): Observable<ItemSupply[]> {
    if (['test'].includes(environment.name)) {
      return new Observable<ItemSupply[]>((observer) => {
        if (!this.useInMemory) {
          this.inMemoryData = efaItemSupplyListSeed;
        }
        observer.next(this.inMemoryData);
        observer.complete();
      });
    }
    return new Observable<ItemSupply[]>((observer) => {
      this.getRetrieveAllSource(responsibleList, this.nextPage)
        .pipe(
          expand(() => {
            if (this.needToFetchNextPage) {
              return this.getRetrieveAllSource(responsibleList, this.nextPage);
            } else {
              return of(null);
            }
          }),
          takeWhile((response) => response !== null),
          last(),
        )
        .subscribe((itemSupplies) => {
          observer.next(itemSupplies);
          observer.complete();
        });
    });
  }

  private getRetrieveAllSource(responsibleList: Responsible[], page = 1, pageSize = 7000): Observable<ItemSupply[]> {
    if (this.useInMemory) {
      return new Observable<ItemSupply[]>((observer) => {
        observer.next(this.inMemoryData);
        observer.complete();
      });
    }
    const params = new HttpParams().set('pageSize', `${pageSize}`).append('page', `${page}`);
    this.nextPage = page + 1;

    return this.http.get<IRetrieveItemSupplyList>(`${environment.api}retrieveItemSupplyList`, { params }).pipe(
      delayWhen(() => this.responsibleService.retrieveAllObservable()),
      map((result) => {
        const itemSupplies = this.addResultToInMemory(result, responsibleList);

        if (this.pagination.isThereNextPage(result.metadata)) {
          this.needToFetchNextPage = true;
        } else {
          this.needToFetchNextPage = false;
        }

        return itemSupplies;
      }),
      map((itemSupplies) => {
        if (this.needToFetchNextPage) {
          return itemSupplies;
        }
        return this.feedWith(itemSupplies);
      }),
    );
  }

  private addResultToInMemory(result: IRetrieveItemSupplyList, responsibleList: Responsible[]): ItemSupply[] {
    const inMemoryData: ItemSupply[] = [];
    for (const dataQueried of result.data) {
      const itemSupply = ItemSupplyModel.builder()
        .withUuid(dataQueried.itemSupplyUuid)
        .withItemCode(dataQueried.itemCode)
        .withScopeType(dataQueried.scopeType)
        .withScopeCode(dataQueried.scopeCode)
        .withStatus(dataQueried.itemSupplyStatus)
        .withUpdateBy(dataQueried.updateBy)
        .withDataSource(dataQueried.dataSource)
        .withProcessusLabel(dataQueried.processusLabel)
        .withComment(dataQueried.comment)
        .withIndicatorCode(dataQueried.indicatorCode)
        .withIndicatorValue(Number(dataQueried.indicatorValue.replace(',', '.')));

      const responsible = responsibleList.find((responsible) => responsible?.uuid === dataQueried.responsible);
      if (responsible) {
        itemSupply.withResponsible(responsible as Responsible);
      }

      if (dataQueried.updateDate) {
        itemSupply.withUpdateDate(dataQueried.updateDate);
      }

      inMemoryData.push(itemSupply.build());
    }

    this._inMemoryData = [...this._inMemoryData, ...inMemoryData];
    return this._inMemoryData;
  }

  upsert(itemSupplies: ItemSupply[]) {
    if (['test'].includes(environment.name)) {
      return new Observable((observer) => {
        for (const itemSupply of itemSupplies) {
          const isAnUpdateIndex = this.inMemoryData.findIndex(
            (saved) => saved.itemSupplyUuid === itemSupply.itemSupplyUuid,
          );
          if (isAnUpdateIndex > -1) {
            this.inMemoryData.splice(isAnUpdateIndex, 1, itemSupply);
          } else {
            this.inMemoryData.push(itemSupply);
          }
        }
        observer.next();
        observer.complete();
      });
    }
    return new Observable((observer) => {
      this.http.post(`${environment.api}upsertItemSupply`, itemSupplies).subscribe(() => {
        observer.next();
        observer.complete();
      });
    });
  }

  remove(itemSupplies: ItemSupply[]) {
    if (['test'].includes(environment.name)) {
      return new Observable((observer) => {
        for (const itemSupply of itemSupplies) {
          const isDeletedIndex = this.inMemoryData.findIndex(
            (saved) => saved.itemSupplyUuid === itemSupply.itemSupplyUuid,
          );
          if (isDeletedIndex > -1) {
            this.inMemoryData.splice(isDeletedIndex, 1);
          }
          observer.next();
          observer.complete();
        }
      });
    }
    return new Observable((observer) => {
      this.http.delete(`${environment.api}removeItemSupply`, { body: itemSupplies }).subscribe(() => {
        observer.next();
        observer.complete();
      });
    });
  }
}
