import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { CactusService } from '../cactus.service';
import { environment } from '../../../environments/environment';
import { responsibleListSeed } from '../../../tests/seed/responsibleList.seed';
import { Responsible, ResponsibleDatabase, ResponsibleModel } from './model/responsible.model';
import { toSignal } from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root',
})
export class ResponsibleService extends CactusService<Responsible> {
  retrieveResponsiblesByIdSignal() {
    return toSignal(
      this.retrieveAllObservable().pipe(
        map((observable) =>
          observable.reduce<Record<string, Responsible>>((prev, curr) => ({ ...prev, [curr.uuid]: curr }), {}),
        ),
      ),
      {
        initialValue: {} as Record<string, Responsible>,
      },
    );
  }

  retrieveAllObservable(): Observable<Responsible[]> {
    if (['test'].includes(environment.name)) {
      return new Observable<Responsible[]>((observer) => {
        if (!this.useInMemory) {
          this.inMemoryData = responsibleListSeed;
        }
        observer.next(this.inMemoryData);
        observer.complete();
      });
    }
    return this.retrieveAllSource();
  }

  private retrieveAllSource() {
    if (this.useInMemory) {
      return new Observable<Responsible[]>((observer) => {
        observer.next(this.inMemoryData);
        observer.complete();
      });
    }
    return this.http.get<{ data: ResponsibleDatabase[] }>(`${environment.api}retrieveResponsibleList`).pipe(
      map(({ data }) => {
        const inMemoryData: Responsible[] = [];
        for (const responsibleQueried of data) {
          const responsible = ResponsibleModel.builder()
            .withUuid(responsibleQueried.user_uuid)
            .withFirstName(responsibleQueried.first_name)
            .withLastName(responsibleQueried.last_name)
            .withEmail(responsibleQueried.mail_address)
            .build();
          inMemoryData.push(responsible);
        }
        return this.feedWith(inMemoryData);
      }),
    );
  }
}
