import { Component, input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { EfaFormGroup, EfaFormNames } from '../../efa-management.types';
import { FormInputComponent } from '../../../../utils/components/form/input.component';
import { FormSelectComponent } from '../../../../utils/components/form/select.component';

@Component({
  selector: 'app-efa-add-edit-model-indicator-fields',
  standalone: true,
  imports: [ReactiveFormsModule, FormInputComponent, FormSelectComponent],
  template: `<form [formGroup]="formGroup" class="inline-flex [&>*]:w-1/2 gap-5 mb-4">
    <app-form-input
      label="Indicator Value"
      inputType="number"
      [group]="formGroup"
      controlName="indicatorValue"
    ></app-form-input>
    <app-form-select [group]="formGroup" label="Indicator Code" controlName="indicatorCode" [options]="indicatorCodes">
    </app-form-select>
  </form>`,
})
export class EfaAddEditModelIndicatorFieldsComponent {
  public efaForm = input.required<FormGroup<EfaFormGroup>>();

  get formGroup() {
    return this.efaForm().controls[EfaFormNames.INDICATORS];
  }

  // TODO: fetch from db
  get indicatorCodes() {
    return [{ value: 'EFA_TOTAL', label: 'Total' }];
  }
}
