import { Component, inject, output, signal } from '@angular/core';
import { ModalComponent } from '../../../utils/components/modal/modal.component';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { ExcelService } from '../../../services/excel-service/excel-service.service';
import { Item, ItemsService } from '../../../services/items/items.service';
import { FormSelectComponent } from '../../../utils/components/form/select.component';
import { ScopesService } from '../../../services/scopes/scopes.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { switchMap } from 'rxjs';
import { NgClass } from '@angular/common';
import { AuthService } from '../../../services/auth/auth.service';
import { EfaItemsService } from '../../../services/efa-items/efa-items.service';
import {
  EfaBulkUploadErrorTableData,
  EfaBulkUploadPreviewTableData,
} from '../../../services/excel-service/excel-service.schemas';
import { ColumnDef } from '@tanstack/angular-table';
import { TableComponent } from '../../../utils/components/table/table.component';
import { ResponsibleService } from '../../../services/responsible/responsible.service';

@Component({
  selector: 'app-efa-bulk-upload-modal',
  standalone: true,
  imports: [ModalComponent, ReactiveFormsModule, FormSelectComponent, NgClass, TableComponent],
  template: `<app-modal (close)="handleClose()" [width]="showErrorsTable || showPreviewTable ? 90 : 50">
    <ng-container title
      >Bulk Upload
      @if (showErrorsTable) {
        - Errors
      }
      @if (showPreviewTable) {
        - Preview
      }
    </ng-container>
    <ng-container body>
      @if (showErrorsTable) {
        <app-table [tableData]="errorsTableData()" [tableColumns]="errorTableColumns"></app-table>
      } @else if (showPreviewTable) {
        <app-table
          [tableData]="previewTableData()"
          [tableColumns]="previewTableColumns"
          [grouping]="['id']"
        ></app-table>
      } @else {
        <div class="flex w-full">
          <div class="flex flex-col w-1/2 border-r-2 border-slate-300 px-6">
            <h4 class="text-xl font-medium text-center mb-4">Start</h4>
            <app-form-select
              [group]="bulkUploadForm"
              [options]="plantCodeOptions"
              label="Select a plant code"
              controlName="plantCodesSelected"
            ></app-form-select>
            <button
              [disabled]="downloadButtonDisabled"
              [ngClass]="
                downloadButtonDisabled
                  ? 'bg-slate-400 cursor-not-allowed'
                  : 'bg-slate-600 hover:bg-slate-500 cursor-pointer'
              "
              class="text-white rounded-lg py-1 px-3 mt-4"
              (click)="handleDownloadBulkUploadFile()"
            >
              Click to download
            </button>
          </div>
          <div class="flex flex-col w-1/2 px-6">
            <h4 class="text-xl text-center font-medium mb-4">Process</h4>
            <label for="bulk_efa_file_upload">
              Upload your completed file
              <input type="file" id="bulk_efa_file_upload" class="h-10" (change)="onFileSelect($event)" />
            </label>
            <button
              [disabled]="processButtonDisabled"
              [ngClass]="
                processButtonDisabled
                  ? 'bg-slate-400 cursor-not-allowed'
                  : 'bg-slate-600 hover:bg-slate-500 cursor-pointer'
              "
              class="text-white rounded-lg py-1 px-3 mt-4"
              (click)="handleSaveBulkUpload()"
            >
              Save Bulk Upload
            </button>
          </div>
        </div>
      }
    </ng-container>
    <ng-container footer>
      @if (showPreviewTable || showErrorsTable) {
        <button
          class="bg-slate-600 hover:bg-slate-500 cursor-pointer text-white rounded-lg py-1 px-3 mt-4"
          (click)="handleBack()"
        >
          Back
        </button>
        @if (showPreviewTable) {
          <button
            (click)="handleCompleteBulkUpload()"
            class="bg-slate-600 hover:bg-slate-500 cursor-pointer text-white rounded-lg py-1 px-3 mt-4"
          >
            Complete
          </button>
        }
      }
    </ng-container>
  </app-modal>`,
})
export class EfaBulkUploadModal {
  private authService = inject(AuthService);
  public excelService = inject(ExcelService);
  private itemsService = inject(ItemsService);
  private formBuilder = inject(FormBuilder);
  private scopeService = inject(ScopesService);
  private efaItemsService = inject(EfaItemsService);
  private responsibleService = inject(ResponsibleService);

  public previewTableData = signal<EfaBulkUploadPreviewTableData[]>([]);
  public errorsTableData = signal<EfaBulkUploadErrorTableData[]>([]);

  public close = output<void>();

  private plantCodesByIdSignal = this.scopeService.retrievePlantCodesByIdSignal();
  public responsibleList = toSignal(this.responsibleService.retrieveAllObservable(), {
    initialValue: [],
  });

  public bulkUploadForm = this.formBuilder.group({
    plantCodesSelected: this.formBuilder.nonNullable.control<string>(''),
    uploadedFile: this.formBuilder.control<File | null>(null),
  });

  public handleClose() {
    this.bulkUploadForm.reset();
    this.close.emit();
  }

  public handleBack() {
    this.bulkUploadForm.controls.uploadedFile.setValue(null);
    this.previewTableData.set([]);
    this.errorsTableData.set([]);
  }

  public get plantCodeOptions() {
    return Object.values(this.plantCodesByIdSignal()).map(({ plantCode, name }) => ({
      value: plantCode,
      label: `${plantCode} ${name}`,
    }));
  }

  public get downloadButtonDisabled() {
    return !this.bulkUploadForm.controls.plantCodesSelected.value;
  }

  public get processButtonDisabled() {
    return !this.bulkUploadForm.controls.uploadedFile.value;
  }

  public get showPreviewTable() {
    return this.previewTableData().length > 0;
  }

  public get showErrorsTable() {
    return this.errorsTableData().length > 0;
  }

  private items$ = this.bulkUploadForm.controls.plantCodesSelected.valueChanges.pipe(
    switchMap((plantCodesSelected) => this.itemsService.retrieveAllObservable({ plantCodesSelected })),
  );

  public itemsSignal = toSignal<Item[]>(this.items$, { initialValue: [] as any });

  public handleDownloadBulkUploadFile() {
    this.excelService.generateEfaBulkUploadExcel(
      this.itemsSignal(),
      `efa_bulk_upload_${this.bulkUploadForm.controls.plantCodesSelected.value}.xlsx`,
    );
  }

  onFileSelect(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      this.bulkUploadForm.controls.uploadedFile.setValue(file);
    }
  }

  public async handleSaveBulkUpload() {
    const currentUserUuid = this.authService.getCurrentUserUuid(this.responsibleList());
    if (this.bulkUploadForm.controls.uploadedFile.value && currentUserUuid) {
      const validatedItemSupplies = await this.excelService.readAndValidateEfaBulkUploadExcel(
        this.bulkUploadForm.controls.uploadedFile.value,
        currentUserUuid,
      );

      if (!Array.isArray(validatedItemSupplies)) {
        this.errorsTableData.set(this.excelService.buildErrorTableDataForEfaBulkUpload(validatedItemSupplies));
        return;
      }
      this.previewTableData.set(this.excelService.buildPreviewTableDataForEfaBulkUpload(validatedItemSupplies));
    }
  }

  public handleCompleteBulkUpload() {
    this.efaItemsService.createEfaItemSupplies(this.excelService.combinedDataMemory).subscribe({
      error: (err) => {
        const errors: EfaBulkUploadErrorTableData[] = (err.error.data.result as any[]).map(
          ({ item_code, scope_type, scope_code }) => ({
            location: 'Database',
            row: 0,
            cell: '-',
            error: `Combination already exists: ${item_code} ${scope_type} ${scope_code}`,
          }),
        );
        this.previewTableData.set([]);
        this.errorsTableData.set(errors);
        console.error('Error occurred while creating item supplies:', err.error.data.result);
        alert(`Failed to create item supplies: ${err.error.data.message || 'Unknown error'}`);
      },
    });
  }

  public previewTableColumns: ColumnDef<EfaBulkUploadPreviewTableData>[] = [
    {
      accessorKey: 'id',
      header: 'Id',
    },
    {
      accessorKey: 'itemCode',
      header: 'Item Code',
      size: 10,
    },

    {
      accessorKey: 'scopeType',
      header: 'Scope Type',
      size: 8,
    },
    {
      accessorKey: 'scopeCode',
      header: 'Scope Code',
      size: 8,
    },
    {
      accessorKey: 'processusLabel',
      header: 'Processus Label',
    },
    {
      accessorKey: 'dataSource',
      header: 'Data Source',
    },
    {
      accessorKey: 'indicatorValue',
      header: 'Indicator Value',
      size: 12,
      cell: (ctx) => {
        const value = ctx.getValue<string>();
        if (!value) return '';
        return `${value} teqCO2`;
      },
    },
    {
      accessorKey: 'indicatorCode',
      header: 'Indicator Code',
      size: 8,
    },
  ];

  public errorTableColumns: ColumnDef<EfaBulkUploadErrorTableData>[] = [
    {
      accessorKey: 'location',
      header: 'Location',
    },
    {
      accessorKey: 'row',
      header: 'Row',
    },
    {
      accessorKey: 'cell',
      header: 'Cell',
    },
    {
      accessorKey: 'error',
      header: 'Error',
    },
  ];
}
