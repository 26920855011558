import { inject, Injectable } from '@angular/core';

import { DateProvider } from './model/date-provider';

@Injectable({
  providedIn: 'root',
})
export class YearOfConsumptionService {
  private dateProvider: DateProvider = inject(DateProvider);

  getYearOfConsumption(): string {
    if (this.dateProvider.isYearBefore('15/02')) {
      return this.dateProvider.getYearMinusTwo();
    }
    return this.dateProvider.getYearMinusOne();
  }

  getPreviousYearOfConsumption(): string {
    if (this.dateProvider.isYearBefore('15/02')) {
      return this.dateProvider.getYearMinusThree();
    }
    return this.dateProvider.getYearMinusTwo();
  }
}
