import { Injectable } from '@angular/core';
import { AuthCactusSession } from './authCactusSession';
import { fetchAuthSession, fetchUserAttributes, FetchUserAttributesOutput } from 'aws-amplify/auth';

interface AWSCredentials {
  accessKeyId: string;
  secretAccessKey: string;
  sessionToken?: string;
  expiration?: Date;
}

interface JWT {
  payload: unknown;
  toString(): string;
}

interface AuthTokens {
  idToken?: JWT;
  accessToken: JWT;
  signInDetails?: unknown;
}

export interface AuthSession {
  tokens?: AuthTokens;
  credentials?: AWSCredentials;
  identityId?: string;
  userSub?: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthSessionService {
  private fetchAuthSession: () => Promise<AuthSession> = fetchAuthSession;
  private fetchUserAttributes: () => Promise<FetchUserAttributesOutput> = fetchUserAttributes;
  private getQueryString: () => URLSearchParams = () => new URLSearchParams(window.location.search);

  feedWith(authCactusSession: AuthCactusSession) {
    this.fetchAuthSession = (): Promise<AuthSession> => {
      const authSession = authCactusSession.getAmplifyAuthSession();
      return Promise.resolve(authSession);
    };
    this.fetchUserAttributes = (): Promise<FetchUserAttributesOutput> => {
      const amplifyAttributes = authCactusSession.getAmplifyAttributes();
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      return Promise.resolve(amplifyAttributes as FetchUserAttributesOutput);
    };
    this.getQueryString = (): URLSearchParams => {
      return new URLSearchParams(`?response_type=code&client_id=yyy&redirect_uri=xxx`);
    };
  }

  fetch(): Promise<AuthSession> {
    return this.fetchAuthSession();
  }

  getAttributes(): Promise<FetchUserAttributesOutput> {
    return this.fetchUserAttributes();
  }

  getSSOQueryString() {
    return this.getQueryString();
  }
}
