import { EfaItemType } from '../../../services/efa-item-type/efa-item-type.service';
import { SourceERPCode } from '../../../services/source-erp/source-erp.service';

type SourceERPCodeWithEmpty = SourceERPCode | '_';

export interface EfaItem {
  sourceErpCode: SourceERPCodeWithEmpty;
  itemCode: string;
  userItemCode: string;
  itemName: string;
  itemType: EfaItemType;
  itemUnitCode: string;
}

export class EfaItemModel {
  props: EfaItem = {
    sourceErpCode: '_',
    itemCode: '',
    userItemCode: '',
    itemName: '',
    itemType: 'Other',
    itemUnitCode: '',
  };

  constructor(props: Partial<EfaItem>) {
    Object.assign(this.props, props);
  }

  static builder(): EfaItemBuilder {
    return new EfaItemBuilder();
  }

  getValues() {
    return this.props;
  }
}

class EfaItemBuilder {
  private sourceErpCode: SourceERPCode;
  private itemCode: string;
  private userItemCode: string;
  private itemName: string;
  private itemType: EfaItemType;
  private itemUnitCode: string;

  build() {
    return new EfaItemModel({
      sourceErpCode: this.sourceErpCode,
      itemCode: this.itemCode,
      userItemCode: this.userItemCode,
      itemName: this.itemName,
      itemType: this.itemType,
      itemUnitCode: this.itemUnitCode,
    }).getValues();
  }

  withCode(code: string) {
    this.itemCode = code;
    return this;
  }

  withUserItemCode(userItemCode: string) {
    this.userItemCode = userItemCode;
    return this;
  }

  withSourceErpCode(sourceErpCode: SourceERPCode) {
    this.sourceErpCode = sourceErpCode;
    return this;
  }

  withName(name: string) {
    this.itemName = name;
    return this;
  }

  withType(type: EfaItemType) {
    this.itemType = type;
    return this;
  }

  withUnitCode(unitCode: string) {
    this.itemUnitCode = unitCode;
    return this;
  }
}
