<main class="main">
  <!--  header-->
  <div class="w-full h-20 relative bg-white border-b border-black flex-col justify-start items-start grid grid-cols-6">
    <div class="justify-start items-center inline-flex mt-auto mb-auto col-span-2">
      <img
        ngSrc="../assets/ARKEMA_Logo_RVB.jpg"
        class="w-[180px] h-[54.04px]"
        alt="Arkema Logo"
        (load)="onLogoLoad()"
        height="97"
        width="320"
        priority
      />
      <div class="text-[#28285f] text-base font-semibold font-['Work Sans']">Cactus</div>
    </div>
    @if (isLoaded()) {
      <div class="justify-start items-start mt-auto mb-auto gap-5 inline-flex col-span-2">
        <div class="flex-col justify-start items-center gap-1 inline-flex">
          <a
            class="text-base font-['Work Sans'] text-[#28285f] font-semibold"
            [routerLink]="'/'"
            routerLinkActive="active-link"
            (click)="dispatchChangePageEvent('/')"
            >Home</a
          >
        </div>
        <div class="flex-col justify-start items-center gap-1 inline-flex">
          <a
            class="text-base font-['Work Sans'] text-[#28285f] font-semibold"
            [routerLink]="'list'"
            routerLinkActive="active-link"
            (click)="dispatchChangePageEvent('/list')"
            >Subsets list</a
          >
        </div>
        <div class="flex-col justify-start items-center gap-1 inline-flex">
          <a
            class="text-base font-['Work Sans'] text-[#28285f] font-semibold"
            [routerLink]="'efa'"
            routerLinkActive="active-link"
            (click)="dispatchChangePageEvent('/efa')"
            >EFA Management</a
          >
        </div>
      </div>
      <button
        (click)="isUserPopupDisplayed.set(!isUserPopupDisplayed())"
        class="w-10 h-10 px-[9px] py-3 mt-auto mb-auto ml-auto mr-3 rounded-[40px] justify-center items-center inline-flex col-span-2"
        [ngClass]="isProfileAdvanced() ? 'bg-[#188d6d]' : 'bg-[#28285f]'"
      >
        <div class="text-white text-sm font-semibold font-['Work Sans']">{{ getShortName() }}</div>
      </button>
    }
  </div>

  @if (isLoaded() && isUserPopupDisplayed()) {
    <div
      class="popup w-[300px] h-[160px] p-5 bg-white rounded-xl shadow flex-col justify-start items-end gap-3 inline-flex"
    >
      <button class="w-6 h-6 relative" (click)="isUserPopupDisplayed.set(false)">
        <svg width="10" height="10" viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
          <line x1="1" y1="1" x2="9" y2="9" stroke="black" stroke-width="2" />
          <line x1="1" y1="9" x2="9" y2="1" stroke="black" stroke-width="2" />
        </svg>
      </button>
      <div class="self-stretch h-[100px] flex-col justify-start items-end gap-5 flex ml-3">
        <div class="self-stretch justify-start items-center gap-3 inline-flex">
          <div
            class="px-[11px] py-[17px] rounded-[50px] justify-center items-center flex w-[50px] h-[50px]"
            [ngClass]="isProfileAdvanced() ? 'bg-[#188d6d]' : 'bg-[#28285f]'"
          >
            <div class="text-white text-xl font-semibold font-['Inter'] leading-relaxed">{{ getShortName() }}</div>
          </div>
          <div class="w-[180px] flex-col justify-start items-start gap-2 inline-flex">
            <div class="self-stretch text-black text-xl font-medium font-['Inter'] leading-relaxed">
              {{ getName() }}
            </div>
            <div
              class="px-3 py-1.5 rounded-[20px] justify-start items-center gap-2.5 inline-flex"
              [ngClass]="isProfileAdvanced() ? 'bg-[#c5e2da]' : 'bg-[#c9c9d7]'"
            >
              <div
                class="text-center text-xs font-medium font-['Work Sans']"
                [ngClass]="isProfileAdvanced() ? 'text-[#188d6d]' : 'text-[#28285f]'"
              >
                {{ isProfileAdvanced() ? 'Advanced' : 'Standard' }}
              </div>
            </div>
          </div>
        </div>
        <!--        <div class="self-stretch h-[0px] border border-[#d8e0e5]"></div>-->
        <!--        <div class="text-[#648296] text-base font-medium font-['Work Sans']">Version 1.4.1</div>-->
      </div>
    </div>
  }

  <!--  body-->
  @if (isLoaded()) {
    <router-outlet></router-outlet>
  } @else {
    <h2>Connecting to cactus...</h2>
  }
</main>
