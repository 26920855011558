import { Component, effect, inject, signal, untracked, WritableSignal } from '@angular/core';
import { DatePipe, NgClass, NgIf } from '@angular/common';
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { toSignal } from '@angular/core/rxjs-interop';
import { lastValueFrom, map } from 'rxjs';
import { Router, RouterLink } from '@angular/router';

import { AuthService } from '../../services/auth/auth.service';
import { DateProvider } from '../../services/year-of-consumption/model/date-provider';
import { ModalComponent } from '../../utils/components/modal/modal.component';
import { Responsible } from '../../services/responsible/model/responsible.model';
import { ResponsibleService } from '../../services/responsible/responsible.service';
import { StatusLabel, Subset } from '../../services/subset/model/subset.model';
import { SubsetStatusTextComponent } from '../../utils/components/subset-status-text/subset-status-text.component';
import { SubsetService } from '../../services/subset/subset.service';
import { YearOfConsumptionService } from '../../services/year-of-consumption/year-of-consumption.service';

@Component({
  selector: 'app-subset-list',
  standalone: true,
  imports: [NgClass, NgIf, RouterLink, SubsetStatusTextComponent, ReactiveFormsModule, DatePipe, ModalComponent],
  providers: [DateProvider, YearOfConsumptionService],
  templateUrl: './subset-list.component.html',
  styleUrls: ['./subset-list.component.css'],
})
export class SubsetListComponent {
  /**
   * Dependencies Injection
   */
  private authService: AuthService = inject(AuthService);
  private formBuilder: FormBuilder = inject(FormBuilder);
  private subsetService: SubsetService = inject(SubsetService);
  private responsibleService: ResponsibleService = inject(ResponsibleService);
  private yearOfConsumptionService: YearOfConsumptionService = inject(YearOfConsumptionService);
  private scopeHovering: WritableSignal<string> = signal('');
  private router: Router = inject(Router);

  /**
   * Column responsible
   */
  private responsibleFilter: WritableSignal<string[]> = signal([]);
  public isResponsibleFilterPopupDisplayed: WritableSignal<boolean> = signal(false);
  public responsibles: WritableSignal<Responsible[]> = signal([]);
  public responsibleForm: FormGroup<{ checkboxesResponsible: FormArray }>;

  /**
   * All subsets
   */
  private _allRequests = toSignal(
    this.responsibleService.retrieveAllObservable().pipe(
      map((responsibleList) => {
        this.responsibles.set(responsibleList);
        return responsibleList;
      }),
      map((responsibleList) => this.subsetService.retrieveAll(responsibleList)),
      map(async (subsetList) => {
        const result = await lastValueFrom(subsetList);
        this.subsetsDisplay.set(result);
        this._subsetMemory = result;
      }),
    ),
  );
  public subsetsDisplay: WritableSignal<Subset[]> = signal([]);
  public searchSubset: FormControl = new FormControl();

  private _subsetMemory: Subset[] = [];
  private statusFilter: WritableSignal<string[]> = signal([]);

  /**
   * Column status
   */
  public subsetStatus: StatusLabel[] = ['Created', 'In progress', 'Published', 'Rework'];
  public isStatusFilterPopupDisplayed: WritableSignal<boolean> = signal(false);
  public statusForm: FormGroup<{ checkboxesStatus: FormArray }> = this.formBuilder.group({
    checkboxesStatus: this.formBuilder.array(
      this.subsetStatus.map(() => this.formBuilder.control({ value: true, disabled: false })),
    ),
  });

  private currentUserUuid: string;

  /**
   * Delete subset
   */
  public showModalDeleteSubset: WritableSignal<boolean> = signal(false);
  public subsetToDelete: WritableSignal<Subset | undefined> = signal(void 0);
  public isDeletingSubset: WritableSignal<boolean> = signal(false);

  constructor() {
    effect(() => {
      if (this.responsibles().length) {
        untracked(() => {
          this.currentUserUuid = this.authService.getCurrentUserUuid(this.responsibles()) || '';
          this.responsibleForm = this.formBuilder.group({
            checkboxesResponsible: this.formBuilder.array(
              this.responsibles().map(() => this.formBuilder.control({ value: true, disabled: false })),
            ),
          });
        });
      }
    });
  }

  get checkboxesResponsible(): FormArray {
    return this.responsibleForm.get('checkboxesResponsible') as FormArray;
  }

  get checkboxesStatus(): FormArray {
    return this.statusForm.get('checkboxesStatus') as FormArray;
  }

  scopeHovered(subsetUuid: string): boolean {
    return subsetUuid === this.scopeHovering();
  }

  onMouseEnter(subsetUuid: string) {
    this.scopeHovering.set(subsetUuid);
  }

  onMouseLeave() {
    this.scopeHovering.set('');
  }

  onKeydown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.performSearch();
    }
  }

  performSearch() {
    const regExp = new RegExp(this.normalize(this.searchSubset.value));
    this.subsetsDisplay.set(
      this._subsetMemory.filter(
        (subset: Subset) =>
          regExp.test(this.normalize(subset.name)) ||
          subset.scopes?.some((scope) => regExp.test(this.normalize(scope.plantCode))) ||
          subset.scopes?.some((scope) => regExp.test(this.normalize(scope.name))) ||
          regExp.test(this.normalize(subset.responsible.firstName + ' ' + subset.responsible.lastName)) ||
          regExp.test(this.normalize(subset.status)),
      ),
    );

    this.resetForms();
  }

  /**
   * Reset columns filter may be useful after performSearch
   */
  private resetForms() {
    this.isResponsibleFilterPopupDisplayed.set(false);
    this.isStatusFilterPopupDisplayed.set(false);

    this.responsibleFilter.set([]);
    this.statusFilter.set([]);

    this.responsibleForm.controls.checkboxesResponsible.clear();
    this.responsibleForm = this.formBuilder.group({
      checkboxesResponsible: this.formBuilder.array(
        this.responsibles().map(() => this.formBuilder.control({ value: true, disabled: false })),
      ),
    });

    this.statusForm.controls.checkboxesStatus.clear();
    this.statusForm = this.formBuilder.group({
      checkboxesStatus: this.formBuilder.array(
        this.subsetStatus.map(() => this.formBuilder.control({ value: true, disabled: false })),
      ),
    });
  }

  /**
   * Transform: "Hélène-Frànçoise" -> "helene-francoise"
   */
  private normalize(value = ''): string {
    return value
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
  }

  getResponsibleName(i: number): string {
    const { firstName, lastName } = this.responsibles()[i];
    return `${firstName} ${lastName}`;
  }

  onCheckboxResponsibleChange(i: number) {
    const isChecked = this.checkboxesResponsible.controls[i].value;

    let currentFilter: string[] = this.responsibleFilter();
    if (!isChecked) {
      currentFilter.push(this.getResponsibleName(i));
    } else {
      currentFilter = currentFilter.filter((r) => r !== this.getResponsibleName(i));
    }
    this.responsibleFilter.set(currentFilter);

    this.applyFilters();
  }

  getStatusLabel(i: number): StatusLabel {
    return this.subsetStatus[i];
  }

  onCheckboxStatusChange(i: number) {
    const isChecked = this.checkboxesStatus.controls[i].value;

    let currentFilter: string[] = this.statusFilter();
    if (!isChecked) {
      currentFilter.push(this.getStatusLabel(i));
    } else {
      currentFilter = currentFilter.filter((r) => r !== this.getStatusLabel(i));
    }
    this.statusFilter.set(currentFilter);

    this.applyFilters();
  }

  /**
   * Must apply columns filters
   * @private
   */
  private applyFilters() {
    this.subsetsDisplay.set(
      this._subsetMemory.filter(
        (subset: Subset) =>
          !this.statusFilter().includes(subset.status) &&
          !this.responsibleFilter().includes(subset.responsible.firstName + ' ' + subset.responsible.lastName),
      ),
    );
  }

  displayResponsibleFilter() {
    this.isResponsibleFilterPopupDisplayed.set(!this.isResponsibleFilterPopupDisplayed());
  }

  displayStatusFilter() {
    this.isStatusFilterPopupDisplayed.set(!this.isStatusFilterPopupDisplayed());
  }

  goToSubset(subsetUuid: string) {
    return this.router.navigate([`/subset/${subsetUuid}`]);
  }

  getYearOfConsumption() {
    return this.yearOfConsumptionService.getYearOfConsumption();
  }

  getYearOfConsumptionMinusOne() {
    return this.yearOfConsumptionService.getPreviousYearOfConsumption();
  }

  async onDeletionSubset(isConfirm: boolean, subsetUuid?: string) {
    if (!this.canUserDeleteSubset()) {
      return;
    }
    if (isConfirm) {
      const subset = this.subsetsDisplay().find(({ uuid }) => uuid === subsetUuid);
      if (subset && ['Created', 'In progress'].includes(subset.status)) {
        this.isDeletingSubset.set(true);
        await lastValueFrom(this.subsetService.deleteSubset(subsetUuid as string, this.currentUserUuid));
        this.isDeletingSubset.set(false);
        this.onClosingDeletionSubset();
      }
    }
  }

  onClosingDeletionSubset() {
    if (!this.isDeletingSubset()) {
      this.showModalDeleteSubset.set(false);
    }
  }

  canUserDeleteSubset() {
    return this.authService.getProfile() === 'advanced';
  }

  canSubsetBeDeleted(subset: Subset) {
    return ['Created', 'In progress'].includes(subset.status);
  }

  startDeleteSubset(subsetUuid: string) {
    const subset: Subset | undefined = this.subsetsDisplay().find(({ uuid }) => uuid === subsetUuid);
    if (subset) {
      this.subsetToDelete.set(subset);
      this.showModalDeleteSubset.set(true);
    }
  }
}
