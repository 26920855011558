import { Component, inject, signal, WritableSignal } from '@angular/core';
import { DatePipe, NgClass, NgIf } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { toSignal } from '@angular/core/rxjs-interop';
import { lastValueFrom, map } from 'rxjs';
import { Router, RouterLink } from '@angular/router';

import { AuthService } from '../../services/auth/auth.service';
import { DateProvider } from '../../services/year-of-consumption/model/date-provider';
import { ModalComponent } from '../../utils/components/modal/modal.component';
import { Responsible } from '../../services/responsible/model/responsible.model';
import { ResponsibleService } from '../../services/responsible/responsible.service';
import { SubsetStatus, Subset } from '../../services/subset/model/subset.model';
import { SubsetStatusTextComponent } from '../../utils/components/subset-status-text/subset-status-text.component';
import { SubsetService } from '../../services/subset/subset.service';
import { PopoverComponent } from '../../utils/components/popover/popover.component';
import SessionStorageService from '../../services/session-storage/session-storage.service';
import _reduce from 'lodash.reduce';

enum FormFieldNames {
  SEARCH = 'search',
  FISCAL_YEAR = 'fiscalYear',
  RESPONSIBLE = 'responsible',
  STATUS = 'status',
}

type CheckboxGroup = Record<string, boolean>;

type LoadedCheckboxGroup = Record<string, FormControl<boolean>>;

type PartialDeep<T> = {
  [K in keyof T]?: T[K] extends object ? PartialDeep<T[K]> : T[K];
};

interface FilterFormFields<loaded extends boolean = false> {
  [FormFieldNames.SEARCH]: loaded extends true ? FormControl<string> : string;
  [FormFieldNames.FISCAL_YEAR]: loaded extends true ? FormControl<string> : string;
  [FormFieldNames.RESPONSIBLE]: loaded extends true ? FormGroup<LoadedCheckboxGroup> : CheckboxGroup;
  [FormFieldNames.STATUS]: loaded extends true ? FormGroup<LoadedCheckboxGroup> : CheckboxGroup;
}

type PartialFormFields = PartialDeep<FilterFormFields>;

@Component({
  selector: 'app-subset-list',
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    RouterLink,
    SubsetStatusTextComponent,
    ReactiveFormsModule,
    DatePipe,
    ModalComponent,
    PopoverComponent,
  ],
  providers: [DateProvider],
  templateUrl: './subset-list.component.html',
  styleUrls: ['./subset-list.component.css'],
})
export class SubsetListComponent {
  /**
   * Dependencies Injection
   */
  private authService: AuthService = inject(AuthService);
  private formBuilder: FormBuilder = inject(FormBuilder);
  private subsetService: SubsetService = inject(SubsetService);
  private responsibleService: ResponsibleService = inject(ResponsibleService);
  private sessionStorageService: SessionStorageService<PartialFormFields> = inject(SessionStorageService);
  private scopeHovering: WritableSignal<string> = signal('');
  private router: Router = inject(Router);

  public subsetsDisplay: WritableSignal<Subset[]> = signal([]);
  private _subsetMemory: Subset[] = [];
  private _responsiblesMemory: Responsible[] = [];

  /**
   * Delete subset
   */
  public showModalDeleteSubset: WritableSignal<boolean> = signal(false);
  public subsetToDelete: WritableSignal<Subset | undefined> = signal(void 0);
  public isDeletingSubset: WritableSignal<boolean> = signal(false);

  /**
   * Filter
   */
  public isStatusFilterPopupDisplayed: WritableSignal<boolean> = signal(false);
  public isResponsibleFilterPopupDisplayed: WritableSignal<boolean> = signal(false);

  private filterFunctions = [
    (formValues: PartialFormFields) => (subset: Subset) =>
      formValues[FormFieldNames.SEARCH]
        ? this.buildSubsetSearchString(subset).includes(this.normalize(formValues[FormFieldNames.SEARCH]))
        : true,
    (formValues: PartialFormFields) => (subset: Subset) =>
      formValues[FormFieldNames.FISCAL_YEAR] ? subset.fiscalYear === formValues[FormFieldNames.FISCAL_YEAR] : true,
    (formValues: PartialFormFields) => (subset: Subset) =>
      (formValues[FormFieldNames.RESPONSIBLE] ?? {})[subset.responsible.uuid],
    (formValues: PartialFormFields) => (subset: Subset) => (formValues[FormFieldNames.STATUS] ?? {})[subset.status],
  ];

  public searchFilter = this.formBuilder.nonNullable.control<string>(
    this.sessionStorageService.get(FormFieldNames.SEARCH) ?? '',
  );

  public fiscalYearFilter = this.formBuilder.nonNullable.control<string>('');

  public statusFilter: FormGroup<LoadedCheckboxGroup> = this.formBuilder.group(
    this.buildDefaultStatusCheckboxesWithSessionStoredValue(this.sessionStorageService.get(FormFieldNames.STATUS)),
  );

  public responsibleFilter: FormGroup<LoadedCheckboxGroup> = this.formBuilder.group({});

  public filterForm = this.formBuilder.group({
    [FormFieldNames.SEARCH]: this.searchFilter,
    [FormFieldNames.FISCAL_YEAR]: this.fiscalYearFilter,
    [FormFieldNames.STATUS]: this.statusFilter,
    [FormFieldNames.RESPONSIBLE]: this.responsibleFilter,
  });

  /**
   * All subsets
   */
  private _allRequests = toSignal(
    this.responsibleService.retrieveAllObservable().pipe(
      map((responsibleList) => {
        this._responsiblesMemory = responsibleList;
        return responsibleList;
      }),
      map((responsibleList) => this.subsetService.retrieveAll(responsibleList)),
      map(async (subsetList) => {
        const result = await lastValueFrom(subsetList);
        this.subsetsDisplay.set(result);
        this._subsetMemory = result;
        this.initializeForm();
      }),
    ),
  );

  constructor() {
    this.filterForm.valueChanges.subscribe((value) => {
      this.sessionStorageService.store(value);
      this.performFilter(value);
    });
  }

  get statusFilterControls() {
    return Object.entries(this.filterForm.controls[FormFieldNames.STATUS].controls);
  }

  get statusFilterNumberSelected() {
    const statusFilterValues = Object.values(this.filterForm.value[FormFieldNames.STATUS] ?? {});
    const valuesSelected = statusFilterValues.filter((bool) => bool).length;
    if (statusFilterValues.length === valuesSelected) {
      return undefined;
    }
    return valuesSelected;
  }

  get responsibleFilterControls() {
    return Object.entries(this.filterForm.controls[FormFieldNames.RESPONSIBLE].controls);
  }

  get responsibleFilterNumberSelected() {
    const responsibleFilterValues = Object.values(this.filterForm.value[FormFieldNames.RESPONSIBLE] ?? {});
    const valuesSelected = responsibleFilterValues.filter((bool) => bool).length;
    if (responsibleFilterValues.length === valuesSelected) {
      return undefined;
    }
    return valuesSelected;
  }

  performFilter(formValues: PartialFormFields) {
    this.subsetsDisplay.set(
      this._subsetMemory.filter((subset) => this.filterFunctions.every((fn) => fn(formValues)(subset))),
    );
  }

  /**
   * Use on component initialisation only
   */
  initializeForm() {
    const fiscalYearDefaultValue = this.sessionStorageService.get(FormFieldNames.FISCAL_YEAR);
    const responsibleDefaultValue = this.sessionStorageService.get(FormFieldNames.RESPONSIBLE);

    this.filterForm.setControl(
      FormFieldNames.FISCAL_YEAR,
      this.formBuilder.nonNullable.control(fiscalYearDefaultValue ?? this.computeFiscalYearOptions().at(-1) ?? ''),
    );

    this.filterForm.setControl(
      FormFieldNames.RESPONSIBLE,
      this.formBuilder.group(this.buildDefaultResponsibleCheckboxesWithSessionStoredValue(responsibleDefaultValue)),
    );
  }

  resetForm() {
    this.sessionStorageService.store({});

    this.isResponsibleFilterPopupDisplayed.set(false);
    this.isStatusFilterPopupDisplayed.set(false);

    this.filterForm.reset({
      [FormFieldNames.SEARCH]: '',
      [FormFieldNames.FISCAL_YEAR]: this.computeFiscalYearOptions().at(-1),
      [FormFieldNames.RESPONSIBLE]: this._responsiblesMemory.reduce(
        (prev, curr) => ({ ...prev, [curr.uuid]: this.formBuilder.nonNullable.control(true) }),
        {},
      ),
      [FormFieldNames.STATUS]: Object.values(SubsetStatus).reduce(
        (prev, curr) => ({ ...prev, [curr]: this.formBuilder.nonNullable.control(true) }),
        {},
      ),
    });
  }

  private buildSubsetSearchString(subset: Subset): string {
    const valuesToSearch = subset.scopes.reduce((prev, curr) => prev + `:${curr.plantCode}:${curr.name}`, subset.name);
    return this.normalize(valuesToSearch);
  }

  /**
   * To be used on form reinitialization or reset, it builds the checkboxes whilst also taking into account any session stored value
   * Starting with the entire responsibles memory alows us to handle edge cases where that value might have changed between sessions
   * @param sessionStoredValue - A value retrieved from localStorage / sessionStorage that can overwrite existant keys.
   * @returns Default checkboxes to be pass to a formBuilder
   */
  private buildDefaultResponsibleCheckboxesWithSessionStoredValue(
    sessionStoredValue: PartialDeep<CheckboxGroup> = {},
  ): LoadedCheckboxGroup {
    return _reduce(
      sessionStoredValue,
      (prev, storedValue, storedResponsibleUUID) => ({
        ...prev,
        [storedResponsibleUUID]: this.formBuilder.nonNullable.control(storedValue),
      }),
      this.buildDefaultResponsibleCheckboxes(),
    );
  }

  private buildDefaultResponsibleCheckboxes() {
    return this._responsiblesMemory.reduce(
      (prev, curr) => ({ ...prev, [curr.uuid]: this.formBuilder.nonNullable.control(true) }),
      {},
    );
  }

  /**
   * To be used on form reinitialization or reset, it builds the checkboxes whilst also taking into account any session stored value
   * Starting with the every subset alows us to handle edge cases where that value might have changed between sessions
   * @param sessionStoredValue - A value retrieved from localStorage / sessionStorage that can overwrite existant keys.
   * @returns Default checkboxes to be pass to a formBuilder
   */
  private buildDefaultStatusCheckboxesWithSessionStoredValue(
    sessionStoredValue: PartialDeep<CheckboxGroup> = {},
  ): LoadedCheckboxGroup {
    return _reduce(
      sessionStoredValue,
      (prev, value, key) => ({ ...prev, [key]: this.formBuilder.nonNullable.control(value) }),
      this.buildDefaultStatusCheckboxes(),
    );
  }

  private buildDefaultStatusCheckboxes() {
    return Object.values(SubsetStatus).reduce(
      (prev, curr) => ({ ...prev, [curr]: this.formBuilder.nonNullable.control(true) }),
      {},
    );
  }

  public computeFiscalYearOptions(): string[] {
    return [...this._subsetMemory.reduce<Set<string>>((prev, curr) => prev.add(curr.fiscalYear), new Set())];
  }

  /**
   * Transform: "Hélène-Frànçoise" -> "helene-francoise"
   */
  private normalize(value = ''): string {
    return value
      .toLowerCase()
      .normalize('NFD')
      .replace(/ /g, '')
      .replace(/[\u0300-\u036f]/g, '');
  }

  scopeHovered(subsetUuid: string): boolean {
    return subsetUuid === this.scopeHovering();
  }

  onMouseEnter(subsetUuid: string) {
    this.scopeHovering.set(subsetUuid);
  }

  onMouseLeave() {
    this.scopeHovering.set('');
  }

  getResponsibleName(i: number): string {
    const { firstName, lastName } = this._responsiblesMemory[i];
    return `${firstName} ${lastName}`;
  }

  displayResponsibleFilter() {
    this.isResponsibleFilterPopupDisplayed.set(!this.isResponsibleFilterPopupDisplayed());
  }

  displayStatusFilter() {
    this.isStatusFilterPopupDisplayed.set(!this.isStatusFilterPopupDisplayed());
  }

  goToSubset(subsetUuid: string) {
    return this.router.navigate([`/subset/${subsetUuid}`]);
  }

  async onDeletionSubset(subsetUuid?: string) {
    if (!this.isUserAdvanced()) {
      return;
    }

    const subset = this.subsetsDisplay().find(({ uuid }) => uuid === subsetUuid);
    const currentUserUuid = this.authService.getCurrentUserUuid(this._responsiblesMemory);
    if (
      subset &&
      currentUserUuid &&
      subsetUuid &&
      [SubsetStatus.CREATED, SubsetStatus.IN_PROGRESS].includes(subset.status)
    ) {
      this.isDeletingSubset.set(true);
      await lastValueFrom(this.subsetService.deleteSubset(subsetUuid, currentUserUuid)).then(async () => {
        const result = await lastValueFrom(this.subsetService.retrieveAll(this._responsiblesMemory));
        this.subsetsDisplay.set(result);
        this._subsetMemory = result;
        this.initializeForm();
      });
      this.isDeletingSubset.set(false);
      this.onClosingDeletionSubset();
    }
  }

  onClosingDeletionSubset() {
    if (!this.isDeletingSubset()) {
      this.showModalDeleteSubset.set(false);
    }
  }

  isUserAdvanced() {
    return this.authService.getProfile() === 'advanced';
  }

  canSubsetBeDeleted(subset: Subset) {
    return [SubsetStatus.CREATED, SubsetStatus.IN_PROGRESS].includes(subset.status);
  }

  startDeleteSubset(event: Event, subsetUuid: string) {
    event.stopPropagation();
    const subset: Subset | undefined = this.subsetsDisplay().find(({ uuid }) => uuid === subsetUuid);
    if (subset) {
      this.subsetToDelete.set(subset);
      this.showModalDeleteSubset.set(true);
    }
  }
}
