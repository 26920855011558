import { domainOf } from "./domain.js";
import { throwInternalError } from "./errors.js";
import { tryCatch } from "./functions.js";
import { FileConstructor, objectKindOf } from "./objectKinds.js";
// Eventually we can just import from package.json in the source itself
// but for now, import assertions are too unstable and it wouldn't support
// recent node versions (https://nodejs.org/api/esm.html#json-modules).
// For now, we assert this matches the package.json version via a unit test.
export const arkUtilVersion = "0.39.0";
export const initialRegistryContents = {
  version: arkUtilVersion,
  filename: tryCatch(() => import.meta.filename ?? undefined, () => globalThis.__filename ?? "unknown"),
  FileConstructor
};
export const registry = initialRegistryContents;
const namesByResolution = new WeakMap();
const nameCounts = Object.create(null);
export const register = value => {
  const existingName = namesByResolution.get(value);
  if (existingName) return existingName;
  let name = baseNameFor(value);
  if (nameCounts[name]) name = `${name}${nameCounts[name]++}`;else nameCounts[name] = 1;
  registry[name] = value;
  namesByResolution.set(value, name);
  return name;
};
export const isDotAccessible = keyName => /^[a-zA-Z_$][a-zA-Z_$0-9]*$/.test(keyName);
const baseNameFor = value => {
  switch (typeof value) {
    case "object":
      {
        if (value === null) break;
        const prefix = objectKindOf(value) ?? "object";
        // convert to camelCase
        return prefix[0].toLowerCase() + prefix.slice(1);
      }
    case "function":
      return isDotAccessible(value.name) ? value.name : "fn";
    case "symbol":
      return value.description && isDotAccessible(value.description) ? value.description : "symbol";
  }
  return throwInternalError(`Unexpected attempt to register serializable value of type ${domainOf(value)}`);
};