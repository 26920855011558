import { genericNode, intrinsic, node } from "@ark/schema";
import { Hkt } from "@ark/util";
import { Scope } from "../scope.js";
export const arkTsKeywords = Scope.module({
  bigint: intrinsic.bigint,
  boolean: intrinsic.boolean,
  false: intrinsic.false,
  never: intrinsic.never,
  null: intrinsic.null,
  number: intrinsic.number,
  object: intrinsic.object,
  string: intrinsic.string,
  symbol: intrinsic.symbol,
  true: intrinsic.true,
  unknown: intrinsic.unknown,
  undefined: intrinsic.undefined
});
export const unknown = Scope.module({
  root: intrinsic.unknown,
  any: intrinsic.unknown
});
export const json = Scope.module({
  root: intrinsic.json,
  stringify: node("morph", {
    in: intrinsic.json,
    morphs: data => JSON.stringify(data),
    declaredOut: intrinsic.string
  })
});
export const object = Scope.module({
  root: intrinsic.object,
  json
});
class RecordHkt extends Hkt {
  description = "a generic type that instantiates an object from a single index signature and corresponding value type";
}
const Record = genericNode(["K", intrinsic.key], "V")(args => ({
  domain: "object",
  index: {
    signature: args.K,
    value: args.V
  }
}), RecordHkt);
class PickHkt extends Hkt {}
const Pick = genericNode(["T", intrinsic.object], ["K", intrinsic.key])(args => args.T.pick(args.K), PickHkt);
class OmitHkt extends Hkt {}
const Omit = genericNode(["T", intrinsic.object], ["K", intrinsic.key])(args => args.T.omit(args.K), OmitHkt);
class PartialHkt extends Hkt {}
const Partial = genericNode(["T", intrinsic.object])(args => args.T.partial(), PartialHkt);
class RequiredHkt extends Hkt {}
const Required = genericNode(["T", intrinsic.object])(args => args.T.required(), RequiredHkt);
class ExcludeHkt extends Hkt {}
const Exclude = genericNode("T", "U")(args => args.T.exclude(args.U), ExcludeHkt);
class ExtractHkt extends Hkt {}
const Extract = genericNode("T", "U")(args => args.T.extract(args.U), ExtractHkt);
export const arkTsGenerics = Scope.module({
  Exclude,
  Extract,
  Omit,
  Partial,
  Pick,
  Record,
  Required
});