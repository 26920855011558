import { Component, inject, input, output, WritableSignal } from '@angular/core';
import { ModalComponent } from '../../../utils/components/modal/modal.component';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { EfaAddEditModelItemSupplyFieldsComponent } from './efa-add-edit-modal-fields/efa-add-edit-modal-item-supply-fields.component';
import { EfaItemSearch } from './efa-item-search/efa-item-search.component';
import { EfaAddEditModelIndicatorFieldsComponent } from './efa-add-edit-modal-fields/efa-add-edit-modal-indicator-fields.component';
import { EfaFormGroup, EfaFormNames } from '../efa-management.types';
import { EfaItemsService } from '../../../services/efa-items/efa-items.service';
import { FormSubmitComponent } from '../../../utils/components/form/submit.component';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-efa-add-edit-model',
  standalone: true,
  imports: [
    ModalComponent,
    EfaAddEditModelItemSupplyFieldsComponent,
    ReactiveFormsModule,
    EfaItemSearch,
    EfaAddEditModelIndicatorFieldsComponent,
    EfaAddEditModelIndicatorFieldsComponent,
    FormSubmitComponent,
  ],
  template: `
    @if (efaItem.value != null) {
      <app-modal (close)="onClose()">
        <ng-container title>{{ itemDisplayTitle }}</ng-container>
        <ng-container body>
          @if (!hideItemSupplyForm()) {
            <p class="border-b-[1px] border-slate-200 w-full pb-2 my-2 font-medium">Item Supply</p>
            <app-efa-add-edit-model-item-supply-fields
              [efaForm]="efaForm()"
            ></app-efa-add-edit-model-item-supply-fields>
          }
          <p class="border-b-[1px] border-slate-200 w-full pb-2 my-2 font-medium">Emissions Factor</p>
          <app-efa-add-edit-model-indicator-fields [efaForm]="efaForm()"></app-efa-add-edit-model-indicator-fields>
        </ng-container>
        @if (efaItemSupplyUuid != null) {
          <ng-container footer>
            <button (click)="onClose()" class="bg-slate-600 hover:bg-slate-500 text-white py-1 px-3 rounded-md my-2">
              Cancel
            </button>
            <button
              (click)="handleUpdate()"
              class="bg-slate-600 hover:bg-slate-500 text-white py-1 px-3 rounded-md my-2"
            >
              Update
            </button>
          </ng-container>
        } @else {
          <ng-container footer>
            <button (click)="onBack()" class="bg-slate-600 hover:bg-slate-500 text-white py-1 px-3 rounded-md my-2">
              Back
            </button>
            <app-form-submit [group]="efaForm()" (click)="handleConfirmCreateItemSupply()">Create</app-form-submit>
          </ng-container>
        }
      </app-modal>
    } @else {
      <app-efa-item-search (close)="onClose()" [efaForm]="efaForm()"></app-efa-item-search>
    }
  `,
})
export class EfaAddEditModalComponent {
  efaItemsService = inject(EfaItemsService);
  efaForm = input.required<FormGroup<EfaFormGroup>>();
  close = output<void>();
  createOrUpdateTriggered = output<void>();

  public get efaItem() {
    return this.efaForm().controls.item;
  }

  public get efaItemSupplyUuid() {
    return this.efaForm().controls.itemSupplyUuid.value;
  }

  public get itemDisplayTitle() {
    return this.efaItem.value?.user_item_code + ' ' + this.efaItem.value?.item_name;
  }

  /**
   * Avoids having multiple signals: "create | edit" mode signal, one open | closed signal, show item_supply form, and the faff of syncing the states
   */
  public hideItemSupplyForm() {
    return (
      this.efaForm().controls.itemSupply.controls[EfaFormNames.SCOPE_TYPE].value.length === 0 &&
      this.efaForm().controls.indicators.controls[EfaFormNames.INDICATOR_VALUE].value > 0
    );
  }

  public handleConfirmCreateItemSupply() {
    const { item, itemSupply, indicators } = this.efaForm().getRawValue();
    const itemCode = item?.item_code;
    if (itemCode) {
      this.efaItemsService
        .createEfaItemSupplies([
          {
            ...itemSupply,
            itemCode,
            indicators: [
              {
                indicatorValue: indicators[EfaFormNames.INDICATOR_VALUE],
                indicatorCode: indicators[EfaFormNames.INDICATOR_CODE],
              },
            ],
          },
        ])
        .subscribe({
          next: () => {
            this.efaForm().reset();
            this.createOrUpdateTriggered.emit();
            this.close.emit();
          },
        });
    }
  }

  public handleUpdate() {
    const { item, itemSupplyUuid, itemSupply, indicators } = this.efaForm().getRawValue();
    if (itemSupplyUuid) {
      if (item?.item_code && !this.hideItemSupplyForm()) {
        forkJoin({
          updateEfa: this.efaItemsService.updateCharacterisationFactor({
            itemSupplyUuid,
            ...indicators,
          }),
          updateItemsService: this.efaItemsService.updateEfaItemSupply({
            itemCode: item.item_code,
            itemSupplyUuid,
            ...itemSupply,
          }),
        }).subscribe({
          next: () => {
            this.efaForm().reset();
            this.createOrUpdateTriggered.emit();
            this.close.emit();
          },
        });
        return;
      }

      if (itemSupplyUuid) {
        this.efaItemsService
          .updateCharacterisationFactor({
            itemSupplyUuid,
            ...indicators,
          })
          .subscribe({
            next: () => {
              this.efaForm().reset();
              this.createOrUpdateTriggered.emit();
              this.close.emit();
            },
          });
      }
    }
  }

  onClose() {
    this.efaForm().reset();
    this.close.emit();
  }

  onBack() {
    this.efaForm().reset();
  }
}
