<aside class="mb-4">
  <div class="flex justify-between items-center gap-3.5 py-2">
    <button class="w-[98px] h-[46px] bg-slate-600 text-white rounded-xl" (click)="goToSubsetList()">Back</button>
    <div class="[&>button]:mx-2">
      @if (canEditSubset()) {
        <button
          class="w-[98px] h-[46px] px-5 py-4 bg-white rounded-xl border-2 border-solid border-blue-950 justify-center items-center inline-flex"
          (click)="goToSubsetEdit()"
        >
          <div class="text-blue-950 text-sm font-semibold font-['Work Sans']">Edit</div>
          <div class="w-4 h-4 relative ml-3">
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="SVGRepo_bgCarrier" stroke-width="0" stroke="black"></g>
              <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="black"></g>
              <g id="SVGRepo_iconCarrier">
                <path
                  d="M9.65661 17L6.99975 17L6.99975 14M6.10235 14.8974L17.4107 3.58902C18.1918 2.80797 19.4581 2.80797 20.2392 3.58902C21.0202 4.37007 21.0202 5.6364 20.2392 6.41745L8.764 17.8926C8.22794 18.4287 7.95992 18.6967 7.6632 18.9271C7.39965 19.1318 7.11947 19.3142 6.8256 19.4723C6.49475 19.6503 6.14115 19.7868 5.43395 20.0599L3 20.9998L3.78312 18.6501C4.05039 17.8483 4.18403 17.4473 4.3699 17.0729C4.53497 16.7404 4.73054 16.424 4.95409 16.1276C5.20582 15.7939 5.50466 15.4951 6.10235 14.8974Z"
                  stroke="black"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </g>
            </svg>
          </div>
        </button>
      }

      <button
        class="w-[246px] h-[46px] px-5 py-4 bg-blue-600 rounded-xl justify-center items-center inline-flex"
        (click)="launchCalculation()"
      >
        <div class="text-white text-sm font-semibold font-['Work Sans']">Launch a new calculation</div>
        <div class="w-4 h-4 relative ml-3">
          <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="SVGRepo_bgCarrier" stroke-width="0" stroke="white"></g>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="white"></g>
            <g id="SVGRepo_iconCarrier">
              <path
                d="M20 4L4 20"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
              ></path>
              <path
                d="M4 7H7M10 7H7M7 7V4M7 7V10"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
              ></path>
              <path
                d="M14 17H17H20"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
              ></path>
            </g>
          </svg>
        </div>
      </button>

      <button
        class="w-[197px] h-[46px] px-5 py-4 mr-[5px] bg-blue-950 rounded-xl justify-center items-center inline-flex"
        (click)="publishResult()"
      >
        <div class="text-white text-sm font-semibold font-['Work Sans']">Publish the result</div>
        <div class="w-5 h-5 relative ml-3">
          <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="SVGRepo_bgCarrier" stroke-width="0" stroke="white"></g>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="white"></g>
            <g id="SVGRepo_iconCarrier">
              <path
                d="M4 12.6111L8.92308 17.5L20 6.5"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </g>
          </svg>
        </div>
      </button>
    </div>
  </div>
</aside>

@if (showCalculationPopup()) {
  <app-modal (close)="closeCalculationPopup()">
    <ng-container title>Launch calculation</ng-container>
    <ng-container body>{{ calculationContent() }}</ng-container>
  </app-modal>
}

@if (showPublishResultPopup()) {
  <app-modal (close)="closePublishResultPopup()" [title]="'Publishing result'">
    <ng-container title>Publishing result</ng-container>
    <ng-container body>{{ publishContent() }}</ng-container>
  </app-modal>
}

<div class="flex flex-wrap w-full">
  <div class="inline-flex justify-between w-full md:w-3/5">
    <h3 class="font-semibold text-2xl text-blue-600 text-ellipsis break-words overflow-hidden">
      Name: {{ subsetDisplay()?.name }}
    </h3>
    <div class="min-w-24 text-center">
      <h3 class="text-white bg-blue-600 rounded-xl py-1 px-4">Id: {{ subsetDisplay()?.userSubsetId }}</h3>
    </div>
  </div>
  <div class="flex flex-col w-full md:w-3/5 p-6">
    <div class="inline-flex flex-start my-3">
      <app-subset-status-text [status]="subsetDisplay()?.status"></app-subset-status-text>
    </div>
    <div class="grid grid-cols-[0.4fr_1fr] py-4 fancy-grid gap-y-2">
      <div>Year</div>
      <div>{{ subsetDisplay()?.fiscalYear }}</div>
      <div>Responsible</div>
      <div>{{ getResponsibleName(subsetDisplay()?.responsible?.uuid || '') }}</div>
      <div>Status Date</div>
      <div>{{ subsetDisplay()?.statusDate | date: 'dd/MM/YYYY' }}</div>
      <div>Update Date</div>
      <div>{{ subsetDisplay()?.updateDate | date: 'dd/MM/YYYY' }}</div>
      <div>Updated By</div>
      <div>{{ getResponsibleName(subsetDisplay()?.updateBy?.uuid || '') }}</div>
      <div>Creation Date</div>
      <div>{{ subsetDisplay()?.creationDate | date: 'dd/MM/YYYY' }}</div>
    </div>
    <h3 class="border-t-[1px] border-slate-300 py-4">Comment: {{ subsetDisplay()?.comment }}</h3>
    @if (!isNaN(subsetDisplay()?.lastCalculationIteration || NaN) && subsetDisplay()?.lastCalculationDate) {
      <div class="inline-flex justify-between border-t-[1px] border-slate-300 pt-4">
        <h3 class="text-xl text-emerald-600 font-semibold">Last Calculation</h3>
        <button class="bg-emerald-600 text-white py-2 px-4 rounded-xl" (click)="retrieveCalculationReport()">
          See the calculation results
        </button>
      </div>
      <div class="grid grid-cols-[0.4fr_1fr] py-4 gap-y-2 fancy-grid">
        <div>Iteration</div>
        <div>{{ subsetDisplay()?.lastCalculationIteration }}</div>
        <div>Calculation Date</div>
        <div>{{ subsetDisplay()?.lastCalculationDate | date: 'dd/MM/YYYY' }}</div>
        <div>Launched By</div>
        <div>{{ getResponsibleName(subsetDisplay()?.lastCalculationBy?.uuid || '') }}</div>
      </div>
    }
    @if (!isNaN(subsetDisplay()?.publishedCalculationIteration || NaN) && subsetDisplay()?.publicationDate) {
      <h3 class="text-xl text-emerald-600 font-semibold">Last Publication</h3>
      <div class="grid grid-cols-[0.4fr_1fr] pt-4 gap-y-2 fancy-grid">
        <div>Iteration</div>
        <div>{{ subsetDisplay()?.publishedCalculationIteration }}</div>
        <div>Publication Date</div>
        <div>{{ subsetDisplay()?.publicationDate | date: 'dd/MM/YYYY' }}</div>
        <div>Launched By</div>
        <div>{{ getResponsibleName(subsetDisplay()?.publicationBy?.uuid || '') }}</div>
      </div>
    }
  </div>
  <div class="flex flex-col w-full md:w-2/5 p-6">
    <h5 class="text-xl font-semibold">Scope:</h5>
    <div
      class="grid grid-cols-[auto_1fr] [&>div]:flex [&>div]:justify-left [&>div]:items-center [&>div]:p-3 [&>div]:text-slate-800 fancy-grid"
    >
      @for (scope of scopesList(); track i; let i = $index) {
        @if (scope && scope.plantCode) {
          <div>{{ scope.plantCode }}</div>
          <div class="text-sm">{{ scope.name }}</div>
        }
      }
    </div>
  </div>
</div>
