export interface SubsetScope {
  name: string;
  plantCode: string;
}

export interface SubsetScopeDatabase {
  erp_code: string;
  plant_code: string;
  plant_name: string;

  country_code: string;
}

export class ScopeModel {
  constructor(private props: SubsetScope) {}

  static builder(): ScopeModelBuilder {
    return new ScopeModelBuilder();
  }

  getValues() {
    return this.props;
  }
}

class ScopeModelBuilder {
  private scopeName: string;
  private plantCode: string;

  withName(name: string) {
    this.scopeName = name;
    return this;
  }

  build() {
    return new ScopeModel({ name: this.scopeName, plantCode: this.plantCode }).getValues();
  }

  withPlantCode(plantCode: string) {
    this.plantCode = plantCode;
    return this;
  }
}
