@if (scope()) {
  <div class="px-5 py-3.5 bg-blue-950 rounded-[100px] justify-center items-center gap-2.5 flex">
    <div class="px-2.5 py-1 bg-slate-500 rounded-[20px] justify-center items-center gap-2.5 flex">
      <div class="text-white text-xs font-normal font-['Work Sans']" data-testid="plant-code__plantCode">
        {{ scope()?.plantCode }}
      </div>
    </div>
    <div class="text-white text-xs font-normal font-['Work Sans'] whitespace-nowrap" data-testid="plant-code__name">
      {{ scope()?.name }}
    </div>
    <button
      class="w-5 h-5 relative bg-orange-600 rounded-[20px] flex justify-center items-center"
      (click)="clickOnCross()"
    >
      <svg width="10" height="10" viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
        <line x1="1" y1="1" x2="9" y2="9" stroke="white" stroke-width="2" />
        <line x1="1" y1="9" x2="9" y2="1" stroke="white" stroke-width="2" />
      </svg>
    </button>
  </div>
}
