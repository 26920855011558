import { ItemSupplyModel } from '../../app/pages/efa-management/model/itemSupply';
import { UniqueId } from '../uniqueId';
import { responsibleListSeed } from './responsibleList.seed';
import { Responsible } from '../../app/services/responsible/model/responsible.model';

const responsible = responsibleListSeed.find(({ firstName }) => firstName.toLowerCase() === 'ando');

export const efaItemSupplyListSeed = [
  ItemSupplyModel.builder()
    .withUuid(UniqueId.nextId())
    .withItemCode('N800529')
    .withScopeType('REG')
    .withScopeCode('EMEA')
    .withStatus('KO')
    .withDataSource('CRRA')
    .withIndicatorCode('EFA_TOTAL')
    .withIndicatorValue(1.1)
    .withProcessusLabel('Electricity, medium voltage {RER}| market group for | APOS, U')
    .withResponsible(responsible as Responsible)
    .build(),
  ItemSupplyModel.builder()
    .withUuid(UniqueId.nextId())
    .withItemCode('N800529')
    .withScopeType('CTY')
    .withScopeCode('DE')
    .withStatus('OK')
    .withDataSource('CRRA')
    .withIndicatorCode('EFA_TOTAL')
    .withIndicatorValue(1.2)
    .withProcessusLabel('Electricity, medium voltage {DE}| market for | APOS, U')
    .withResponsible(responsible as Responsible)
    .build(),
];
