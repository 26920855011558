export interface Responsible {
  firstName: string;
  lastName: string;
  uuid: string;
  email: string;

  getName(): string;
}

export interface ResponsibleDatabase {
  user_uuid: string;
  first_name: string;
  last_name: string;
  mail_address: string;
  profile_name: string;
  creation_date: string;
  last_connection_date: string;
}

export class ResponsibleModel {
  private readonly props: Responsible = {
    firstName: '',
    lastName: '',
    uuid: '',
    email: '',

    getName() {
      return this.firstName + ' ' + this.lastName;
    },
  };
  constructor(props: Partial<Responsible>) {
    this.props = Object.assign(this.props, props);
  }

  static builder(): ResponsibleBuilder {
    return new ResponsibleBuilder();
  }

  getValues() {
    return this.props;
  }
}

class ResponsibleBuilder {
  private firstName: string;
  private lastName: string;
  private uuid: string;
  private email: string;

  withResponsible(responsible: Responsible) {
    this.firstName = responsible.firstName;
    this.lastName = responsible.lastName;
    this.uuid = responsible.uuid;
    this.email = responsible.email;
    return this;
  }

  withFirstName(firstName: string) {
    this.firstName = firstName;
    return this;
  }

  withLastName(lastName: string) {
    this.lastName = lastName;
    return this;
  }

  withUuid(uuid: string) {
    this.uuid = uuid;
    return this;
  }

  withEmail(email: string) {
    this.email = email;
    return this;
  }

  build() {
    return new ResponsibleModel({
      firstName: this.firstName,
      lastName: this.lastName,
      uuid: this.uuid,
      email: this.email,
    }).getValues();
  }
}
