import { ActivatedRoute, Router } from '@angular/router';
import {
  Component,
  computed,
  effect,
  inject,
  OnDestroy,
  signal,
  Signal,
  untracked,
  WritableSignal,
} from '@angular/core';
import { DatePipe } from '@angular/common';
import { catchError, lastValueFrom, map, Subject, takeUntil } from 'rxjs';
import { toSignal } from '@angular/core/rxjs-interop';

import { AuthService } from '../../services/auth/auth.service';
import { IRequestCalculationStatus } from '../../services/subset/calculation/status/requestCalculationStatus.interface';
import { ModalComponent } from '../../utils/components/modal/modal.component';
import { Responsible } from '../../services/responsible/model/responsible.model';
import { ResponsibleService } from '../../services/responsible/responsible.service';
import { ScopesService } from '../../services/scopes/scopes.service';
import { SubsetScope } from '../../services/scopes/model/scope.model';
import { SubsetService } from '../../services/subset/subset.service';
import { SubsetStatusTextComponent } from '../../utils/components/subset-status-text/subset-status-text.component';
import { Subset } from '../../services/subset/model/subset.model';

@Component({
  selector: 'app-subset-details',
  standalone: true,
  imports: [SubsetStatusTextComponent, DatePipe, ModalComponent],
  templateUrl: './subset-details.component.html',
  styleUrl: './subset-details.component.css',
})
export class SubsetDetailsComponent implements OnDestroy {
  /**
   * Dependencies Injection
   */
  private activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private authService: AuthService = inject(AuthService);
  private responsibleService: ResponsibleService = inject(ResponsibleService);
  private router: Router = inject(Router);
  private scopeService: ScopesService = inject(ScopesService);
  private subsetService: SubsetService = inject(SubsetService);

  /**
   * prepare cache
   */
  public scopeAllList: Signal<SubsetScope[]> = toSignal(this.scopeService.retrieveAllObservable(), {
    initialValue: [],
  });

  /**
   * core
   */
  private timeout$: Subject<void> = new Subject<void>();
  private destroy$: Subject<void> = new Subject<void>();
  public subsetUuid: string = this.activatedRoute.snapshot.paramMap.get('uuid') || '';
  public responsibleList: WritableSignal<Responsible[]> = signal([]);
  public subset: WritableSignal<Subset | undefined> = signal(void 0);
  public subsetDisplay: WritableSignal<Subset | undefined> = signal(void 0);
  public scopesList: Signal<SubsetScope[]> = computed(() => this.subset()?.scopes || []);
  public showCalculationPopup: WritableSignal<boolean> = signal(false);
  public showPublishResultPopup: WritableSignal<boolean> = signal(false);
  public isPopupAbleToClose: WritableSignal<boolean> = signal(false);
  public calculationContent: WritableSignal<string> = signal('');
  public publishContent: WritableSignal<string> = signal('');
  private userClosedPopup$: Subject<void> = new Subject<void>();
  private currentUserUuid: string;

  /**
   * returns promise undefined since I failed to return correctly Observable<Subset[]> for this.subset()
   */
  private _allRequest: Signal<Promise<void>> = toSignal(
    this.responsibleService.retrieveAllObservable().pipe(
      map((responsibleList) => {
        this.responsibleList.set(responsibleList);
        return responsibleList;
      }),
      map((responsibleList) => this.subsetService.retrieveAll(responsibleList)),
      map(async (subsetList) => {
        const result: Subset[] = await lastValueFrom(subsetList);
        const subset = result.find(({ uuid }) => uuid === this.subsetUuid);
        if (subset) {
          this.subset.set(subset);
        }
      }),
    ),
    { initialValue: Promise.resolve() },
  );

  /**
   * used for html to access is not a number
   */
  protected readonly isNaN = isNaN;
  protected readonly NaN = NaN;

  constructor() {
    effect(() => {
      this.scopeAllList();
      if (this.responsibleList()) {
        this.currentUserUuid = this.authService.getCurrentUserUuid(this.responsibleList()) || '';
      }
      if (this.subset()) {
        untracked(() => {
          this.subsetDisplay.set(this.subset());
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.timeout$.next();
    this.timeout$.complete();
    this.userClosedPopup$.next();
    this.userClosedPopup$.complete();
  }

  goToSubsetEdit() {
    return this.router.navigate([`/subset-edit/${this.subsetUuid}`]);
  }

  goToSubsetList() {
    return this.router.navigate([`/`]);
  }

  launchCalculation() {
    this.showCalculationPopup.set(true);
    this.calculationContent.set('Requesting calculation for ' + this.subsetDisplay()?.name + '...');
    this.subsetService.launchCalculation(this.subset() as Subset, this.currentUserUuid).subscribe(() => {
      this.subsetService
        .pollingUntilCalculationFinished({
          request: this.subsetService.retrieveCalculationStatus(this.subset() as Subset).pipe(
            catchError((err, caught) => {
              this.timeout$.next();
              this.timeout$.complete();
              this.updateSubsetDetailCalculation(false, err.error.data?.error);
              return caught;
            }),
            takeUntil(this.timeout$),
          ),
          timeout: this.timeout$ as Subject<unknown>,
          callback: ({ pending }: IRequestCalculationStatus) => {
            if (pending) {
              // pending
            }
          },
        })
        .subscribe(() => {
          this.updateSubsetDetailCalculation(false, `Calculation process completed with success`);
        });
    });
  }

  private updateSubsetDetailCalculation(closePopup = true, contentToDisplay = '') {
    if (closePopup) {
      this.showCalculationPopup.set(false);
      this.calculationContent.set('');
    } else {
      this.isPopupAbleToClose.set(true);
      this.calculationContent.set(contentToDisplay);
    }

    this.userClosedPopup$.subscribe(() => {
      this.subsetService.refreshAll(this.responsibleList()).subscribe((subsetList) => {
        const __subset = subsetList.find(({ uuid }) => this.subsetUuid === uuid);
        if (__subset) {
          this.subsetDisplay.set(__subset);
        }
      });
    });
  }

  publishResult() {
    this.showPublishResultPopup.set(true);
    this.publishContent.set('Requesting publication for ' + this.subsetDisplay()?.name + '...');
    this.subsetService.publishResult(this.subset() as Subset, this.currentUserUuid).subscribe(() => {
      this.subsetService
        .pollingUntilCalculationFinished({
          request: this.subsetService.retrievePublicationStatus(this.subset() as Subset).pipe(
            catchError((err, caught) => {
              this.timeout$.next();
              this.timeout$.complete();
              this.updatePublicationSubset(false, err.error.data?.error);
              return caught;
            }),
            takeUntil(this.timeout$),
          ),
          timeout: this.timeout$ as Subject<unknown>,
          callback: ({ pending }: IRequestCalculationStatus) => {
            if (pending) {
              // pending
            }
          },
        })
        .subscribe(() => {
          this.updatePublicationSubset(false, 'Publication process completed with success');
        });
    });
  }

  private updatePublicationSubset(closePopup = true, contentToDisplay = '') {
    if (closePopup) {
      this.showPublishResultPopup.set(false);
      this.publishContent.set('');
    } else {
      this.isPopupAbleToClose.set(true);
      this.publishContent.set(contentToDisplay);
    }

    this.userClosedPopup$.subscribe(() => {
      this.subsetService.refreshAll(this.responsibleList()).subscribe((subsetList) => {
        const __subset = subsetList.find(({ uuid }) => this.subsetUuid === uuid);
        if (__subset) {
          this.subsetDisplay.set(__subset);
        }
      });
    });
  }

  canEditSubset(): boolean {
    return this.authService.getProfile() === 'advanced';
  }

  closeCalculationPopup() {
    this.showCalculationPopup.set(false);
    this.userClosedPopup$.next(void 0);
    this.isPopupAbleToClose.set(false);
  }

  closePublishResultPopup() {
    this.showPublishResultPopup.set(false);
    this.userClosedPopup$.next(void 0);
    this.isPopupAbleToClose.set(false);
  }

  getResponsibleName(responsibleUuid: string): string {
    const responsible = this.responsibleList().find(({ uuid }) => uuid === responsibleUuid);
    if (responsible) {
      return `${responsible.firstName} ${responsible.lastName}`;
    }
    return '';
  }

  retrieveCalculationReport() {
    this.subsetService.retrieveCalculationReport(this.subset()!).subscribe((url) => {
      window.open(url);
    });
  }
}
