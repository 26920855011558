import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DateProvider {
  private yearIndex: number;
  private monthIndex: number;
  private dayIndex: number;

  /**
   * ex: "15/02/2024" for February 15th 2024
   * @param date {string}
   */
  parseDate(date: string) {
    const [day, month, year] = date.split('/');
    this.yearIndex = Number(year);
    this.monthIndex = Number(month) + 1; // JS Date month start at 0
    this.dayIndex = Number(day);

    return new Date(this.yearIndex, this.monthIndex, this.dayIndex);
  }

  /**
   * @returns {Date | null}
   * @param date {string} example: "2024-09-23 12:54:11.422669"
   */
  parse(date: string | null): Date | null {
    if (!date) return null;
    return new Date(date);
  }

  /**
   * Useful for creation date that must be defined
   * @returns {Date}
   * @param date example: "2024-09-23 12:54:11.422669"
   */
  parseStrict(date: string): Date {
    if (!date) throw new Error('Date is invalid: ' + date);
    return new Date(date);
  }

  getYearMinusOne(): string {
    this.setYearIfUndefined();
    return `${this.yearIndex - 1}`;
  }

  private setYearIfUndefined() {
    if (!this.yearIndex) {
      const date = new Date();
      this.parseDate(`${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`);
    }
  }

  getYearMinusTwo() {
    this.setYearIfUndefined();
    return `${this.yearIndex - 2}`;
  }

  getYearMinusThree() {
    this.setYearIfUndefined();
    return `${this.yearIndex - 3}`;
  }

  /**
   * ex: "15/02" for February 15th
   * @param monthday {string}
   */
  isYearBefore(monthday: string) {
    this.setYearIfUndefined();
    const [day, month] = monthday.split('/');
    const monthIndex = Number(month) + 1; // JS Date month start at 0

    if (this.monthIndex < monthIndex) {
      return true;
    } else if (this.monthIndex === monthIndex) {
      return this.dayIndex <= Number(day);
    }

    return false;
  }

  static now() {
    return new Date();
  }
}
