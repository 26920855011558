<select [formControl]="responsibleSelected" [attr.data-testid]="dataTestidPrefix() + 'responsible-list__select'">
  <option [value]="null" disabled selected>Select a responsible</option>
  @for (responsible of responsibleList(); track $index) {
    <option
      class="text-black text-lg font-normal font-['Work Sans']"
      [attr.data-testid]="dataTestidPrefix() + 'responsible-selectable-option-' + responsible.uuid"
      [value]="responsible.uuid"
    >
      {{ responsible.firstName }} {{ responsible.lastName }}
    </option>
  }
</select>
