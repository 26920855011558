import { Component } from '@angular/core';

@Component({
  selector: 'app-subset-publish',
  standalone: true,
  imports: [],
  templateUrl: './subset-publish.component.html',
  styleUrl: './subset-publish.component.css',
})
export class SubsetPublishComponent {}
