import { IEnv } from './environment.interface';

export const environment: IEnv = {
  name: 'dev',
  aws: {
    auth: {
      region: 'eu-west-1',
      userPoolId: 'eu-west-1_Or6ad5BPx',
      userPoolWebClientId: '4prevrssuc5edn5eg6sg6ohaih',
      oauth: {
        domain: `cactus-dev.auth.eu-west-1.amazoncognito.com`,
        scopes: ['openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: ['https://cactus-dev.faktory.digital'],
        redirectSignOut: ['https://cactus-dev.faktory.digital'],
        responseType: 'code',
        providers: [{ custom: 'cactusdevAutgnitoentraId3AE8B091' }],
      },
    },
  },
  api: 'https://z8xsd63663.execute-api.eu-west-1.amazonaws.com/dev/',
  pollingInterval: 5000,
};
