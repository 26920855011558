import { Component, input } from '@angular/core';

@Component({
  selector: 'app-efa-edit-button',
  template: `
    <button class="bg-slate-600 hover:bg-slate-500 text-white text-sm py-1 px-3 rounded-md my-2" (click)="callback()()">
      Edit
    </button>
  `,
})
export class EfaEditButtonComponent {
  callback = input<() => void>(() => {});
}
