import { inject, Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable, of, tap } from 'rxjs';

import { CactusService } from '../cactus.service';
import { environment } from '../../../environments/environment';

import { CacheService } from '../cache.service';

export interface Item {
  item_code: string;
  user_item_code: string;
  item_name: string;
}

interface ItemListDTO {
  data: Item[];
}

interface ItemsServiceDTO {
  searchString?: string;
  plantCodesSelected?: string;
}

@Injectable({ providedIn: 'root' })
export class ItemsService extends CactusService<Item> {
  private cacheService = inject(CacheService);

  retrieveAllObservable(params: ItemsServiceDTO) {
    return new Observable<Item[]>((observer) =>
      this.searchItems(params).subscribe((items) => {
        observer.next(items.data);
        observer.complete();
      }),
    );
  }

  private searchItems(searchParams: ItemsServiceDTO): Observable<ItemListDTO> {
    const endpoint = 'retrieveItems';
    const queryParams = {
      ...(() => ('searchString' in searchParams ? { searchString: searchParams.searchString } : {}))(),
      ...(() =>
        'plantCodesSelected' in searchParams ? { plantCodesSelected: searchParams.plantCodesSelected } : {})(),
    } as Record<string, string | number | boolean | ReadonlyArray<string | number | boolean>>;
    const params = new HttpParams().appendAll(queryParams);
    const cacheString = `${endpoint}_${params.toString()}`;
    const cachedData = this.cacheService.get(cacheString);
    if (cachedData) {
      return of(cachedData);
    }

    return this.http
      .get<ItemListDTO>(`${environment.api}${endpoint}`, {
        params,
      })
      .pipe(
        tap((response) => {
          this.cacheService.set(cacheString, response);
        }),
      );
  }
}
