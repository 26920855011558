import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  private cache = new Map<
    string,
    {
      data: any;
      timestamp: number;
      expiresIn: number;
    }
  >();

  set(key: string, data: any, expiresInMinutes = 30) {
    this.cache.set(key, {
      data,
      timestamp: Date.now(),
      expiresIn: expiresInMinutes * 60 * 1000,
    });
  }

  get(key: string): any {
    const item = this.cache.get(key);

    if (!item) return null;

    const isExpired = Date.now() > item.timestamp + item.expiresIn;
    if (isExpired) {
      this.cache.delete(key);
      return null;
    }

    return item.data;
  }

  clear(key?: string) {
    if (key) {
      this.cache.delete(key);
    } else {
      this.cache.clear();
    }
  }
}
