import { v4 as uuid } from 'uuid';

export class UniqueId {
  private static lastId: string;

  static nextId(): string {
    UniqueId.lastId = uuid();
    return UniqueId.lastId;
  }

  static reset() {
    UniqueId.lastId = uuid();
  }
}
