import { flatMorph, hasDomain, isEmptyObject, isKeyOf, throwParseError } from "@ark/util";
import { constraintKeyParser, flattenConstraints, intersectConstraints } from "../constraint.js";
import { Disjoint } from "../shared/disjoint.js";
import { implementNode, structureKeys } from "../shared/implement.js";
import { intersectOrPipeNodes } from "../shared/intersections.js";
import { hasArkKind, isNode } from "../shared/utils.js";
import { BaseRoot } from "./root.js";
import { defineRightwardIntersections } from "./utils.js";
const implementation = implementNode({
  kind: "intersection",
  hasAssociatedError: true,
  normalize: rawSchema => {
    if (isNode(rawSchema)) return rawSchema;
    const {
      structure,
      ...schema
    } = rawSchema;
    const hasRootStructureKey = !!structure;
    const normalizedStructure = structure ?? {};
    const normalized = flatMorph(schema, (k, v) => {
      if (isKeyOf(k, structureKeys)) {
        if (hasRootStructureKey) {
          throwParseError(`Flattened structure key ${k} cannot be specified alongside a root 'structure' key.`);
        }
        normalizedStructure[k] = v;
        return [];
      }
      return [k, v];
    });
    if (hasArkKind(normalizedStructure, "constraint") || !isEmptyObject(normalizedStructure)) normalized.structure = normalizedStructure;
    return normalized;
  },
  finalizeInnerJson: ({
    structure,
    ...rest
  }) => hasDomain(structure, "object") ? {
    ...structure,
    ...rest
  } : rest,
  keys: {
    domain: {
      child: true,
      parse: (schema, ctx) => ctx.$.node("domain", schema)
    },
    proto: {
      child: true,
      parse: (schema, ctx) => ctx.$.node("proto", schema)
    },
    structure: {
      child: true,
      parse: (schema, ctx) => ctx.$.node("structure", schema),
      serialize: node => {
        if (!node.sequence?.minLength) return node.collapsibleJson;
        const {
          sequence,
          ...structureJson
        } = node.collapsibleJson;
        const {
          minVariadicLength,
          ...sequenceJson
        } = sequence;
        const collapsibleSequenceJson = sequenceJson.variadic && Object.keys(sequenceJson).length === 1 ? sequenceJson.variadic : sequenceJson;
        return {
          ...structureJson,
          sequence: collapsibleSequenceJson
        };
      }
    },
    divisor: {
      child: true,
      parse: constraintKeyParser("divisor")
    },
    max: {
      child: true,
      parse: constraintKeyParser("max")
    },
    min: {
      child: true,
      parse: constraintKeyParser("min")
    },
    maxLength: {
      child: true,
      parse: constraintKeyParser("maxLength")
    },
    minLength: {
      child: true,
      parse: constraintKeyParser("minLength")
    },
    exactLength: {
      child: true,
      parse: constraintKeyParser("exactLength")
    },
    before: {
      child: true,
      parse: constraintKeyParser("before")
    },
    after: {
      child: true,
      parse: constraintKeyParser("after")
    },
    pattern: {
      child: true,
      parse: constraintKeyParser("pattern")
    },
    predicate: {
      child: true,
      parse: constraintKeyParser("predicate")
    }
  },
  // leverage reduction logic from intersection and identity to ensure initial
  // parse result is reduced
  reduce: (inner, $) =>
  // we cast union out of the result here since that only occurs when intersecting two sequences
  // that cannot occur when reducing a single intersection schema using unknown
  intersectIntersections({}, inner, {
    $,
    invert: false,
    pipe: false
  }),
  defaults: {
    description: node => {
      if (node.children.length === 0) return "unknown";
      if (node.structure) return node.structure.description;
      const childDescriptions = [];
      if (node.basis && !node.refinements.some(r => r.impl.obviatesBasisDescription)) childDescriptions.push(node.basis.description);
      if (node.refinements.length) {
        const sortedRefinementDescriptions = node.refinements
        // override alphabetization to describe min before max
        .toSorted((l, r) => l.kind === "min" && r.kind === "max" ? -1 : 0).map(r => r.description);
        childDescriptions.push(...sortedRefinementDescriptions);
      }
      if (node.inner.predicate) {
        childDescriptions.push(...node.inner.predicate.map(p => p.description));
      }
      return childDescriptions.join(" and ");
    },
    expected: source => `  • ${source.errors.map(e => e.expected).join("\n  • ")}`,
    problem: ctx => `(${ctx.actual}) must be...\n${ctx.expected}`
  },
  intersections: {
    intersection: (l, r, ctx) => intersectIntersections(l.inner, r.inner, ctx),
    ...defineRightwardIntersections("intersection", (l, r, ctx) => {
      // if l is unknown, return r
      if (l.children.length === 0) return r;
      const {
        domain,
        proto,
        ...lInnerConstraints
      } = l.inner;
      const lBasis = proto ?? domain;
      const basis = lBasis ? intersectOrPipeNodes(lBasis, r, ctx) : r;
      return basis instanceof Disjoint ? basis : l?.basis?.equals(basis) ?
      // if the basis doesn't change, return the original intesection
      l
      // given we've already precluded l being unknown, the result must
      // be an intersection with the new basis result integrated
      : l.$.node("intersection", {
        ...lInnerConstraints,
        [basis.kind]: basis
      }, {
        prereduced: true
      });
    })
  }
});
export class IntersectionNode extends BaseRoot {
  basis = this.inner.domain ?? this.inner.proto ?? null;
  refinements = this.children.filter(node => node.isRefinement());
  structure = this.inner.structure;
  expression = describeIntersection(this);
  get shortDescription() {
    return this.basis?.shortDescription ?? "present";
  }
  innerToJsonSchema() {
    return this.children.reduce(
    // cast is required since TS doesn't know children have compatible schema prerequisites
    (schema, child) => child.isBasis() ? child.toJsonSchema() : child.reduceJsonSchema(schema), {});
  }
  traverseAllows = (data, ctx) => this.children.every(child => child.traverseAllows(data, ctx));
  traverseApply = (data, ctx) => {
    const errorCount = ctx.currentErrorCount;
    if (this.basis) {
      this.basis.traverseApply(data, ctx);
      if (ctx.currentErrorCount > errorCount) return;
    }
    if (this.refinements.length) {
      for (let i = 0; i < this.refinements.length - 1; i++) {
        this.refinements[i].traverseApply(data, ctx);
        if (ctx.failFast && ctx.currentErrorCount > errorCount) return;
      }
      this.refinements.at(-1).traverseApply(data, ctx);
      if (ctx.currentErrorCount > errorCount) return;
    }
    if (this.structure) {
      this.structure.traverseApply(data, ctx);
      if (ctx.currentErrorCount > errorCount) return;
    }
    if (this.inner.predicate) {
      for (let i = 0; i < this.inner.predicate.length - 1; i++) {
        this.inner.predicate[i].traverseApply(data, ctx);
        if (ctx.failFast && ctx.currentErrorCount > errorCount) return;
      }
      this.inner.predicate.at(-1).traverseApply(data, ctx);
    }
  };
  compile(js) {
    if (js.traversalKind === "Allows") {
      this.children.forEach(child => js.check(child));
      js.return(true);
      return;
    }
    js.initializeErrorCount();
    if (this.basis) {
      js.check(this.basis);
      // we only have to return conditionally if this is not the last check
      if (this.children.length > 1) js.returnIfFail();
    }
    if (this.refinements.length) {
      for (let i = 0; i < this.refinements.length - 1; i++) {
        js.check(this.refinements[i]);
        js.returnIfFailFast();
      }
      js.check(this.refinements.at(-1));
      if (this.structure || this.inner.predicate) js.returnIfFail();
    }
    if (this.structure) {
      js.check(this.structure);
      if (this.inner.predicate) js.returnIfFail();
    }
    if (this.inner.predicate) {
      for (let i = 0; i < this.inner.predicate.length - 1; i++) {
        js.check(this.inner.predicate[i]);
        // since predicates can be chained, we have to fail immediately
        // if one fails
        js.returnIfFail();
      }
      js.check(this.inner.predicate.at(-1));
    }
  }
}
export const Intersection = {
  implementation,
  Node: IntersectionNode
};
const describeIntersection = node => {
  let expression = node.structure?.expression || `${node.basis ? node.basis.nestableExpression + " " : ""}${node.refinements.join(" & ")}` || "unknown";
  if (expression === "Array == 0") expression = "[]";
  return expression;
};
const intersectIntersections = (l, r, ctx) => {
  const baseInner = {};
  const lBasis = l.proto ?? l.domain;
  const rBasis = r.proto ?? r.domain;
  const basisResult = lBasis ? rBasis ? intersectOrPipeNodes(lBasis, rBasis, ctx) : lBasis : rBasis;
  if (basisResult instanceof Disjoint) return basisResult;
  if (basisResult) baseInner[basisResult.kind] = basisResult;
  return intersectConstraints({
    kind: "intersection",
    baseInner,
    l: flattenConstraints(l),
    r: flattenConstraints(r),
    roots: [],
    ctx
  });
};