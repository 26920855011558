import { inject, Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { catchError, Observable, of, tap, throwError } from 'rxjs';

import { CactusService } from '../cactus.service';
import { environment } from '../../../environments/environment';
import { SubsetScope } from '../scopes/model/scope.model';
import reduce from 'lodash.reduce';
import { CacheService } from '../cache.service';

export enum EfaFilterNames {
  PAGE = 'page',
  PAGE_SIZE = 'pageSize',
  SEARCH_STRING = 'searchString',
  PLANT_CODES_SEARCH = 'plantCodesSearch',
  PLANT_CODES_SELECTED = 'plantCodesSelected',
  ERP_CODES = 'erpCodes',
  ITEM_TYPE = 'itemTypes',
  RESPONSIBLE = 'responsibles',
  UNITS = 'units',
}

export interface FilterValues {
  [EfaFilterNames.PAGE]: number;
  [EfaFilterNames.PAGE_SIZE]: number;
  [EfaFilterNames.SEARCH_STRING]: string;
  [EfaFilterNames.PLANT_CODES_SEARCH]: string;
  [EfaFilterNames.PLANT_CODES_SELECTED]: Record<string, SubsetScope>;
  [EfaFilterNames.ERP_CODES]: Partial<Record<string, boolean>>;
  [EfaFilterNames.ITEM_TYPE]: Partial<Record<string, boolean>>;
  [EfaFilterNames.RESPONSIBLE]: Partial<Record<string, boolean>>;
  [EfaFilterNames.UNITS]: Partial<Record<string, boolean>>;
}

export interface EfaItemDTO {
  source_erp_code: string;
  item_code: string;
  user_item_code: string;
  item_name: string;
  item_type: string;
  item_unit_code: string;
  item_supply_uuid: string;
  scope_type: string;
  scope_code: string;
  processus_label: string;
  data_source: string;
  responsible: string;
  indicator_value: string;
  indicator_code: string;
}

interface RetrieveEfaItemListDTO {
  data: EfaItemDTO[];
  metadata: {
    totalCount: number;
    page: number;
    pageSize: number;
  };
}

export interface CreateItemSupplyDTO {
  itemCode: string;
  scopeType: string;
  scopeCode: string;
  dataSource: string;
  processusLabel: string;
  responsible: string;
  indicators: { indicatorValue: number; indicatorCode: string }[];
}

export interface UpdateItemSupplyDTO {
  itemSupplyUuid: string;
  itemCode: string;
  scopeType: string;
  scopeCode: string;
  dataSource: string;
  processusLabel: string;
  responsible: string;
}

export interface UpdateCharacterisationFactorDTO {
  itemSupplyUuid: string;
  indicatorValue: number;
  indicatorCode: string;
}

@Injectable({
  providedIn: 'root',
})
export class EfaItemsService extends CactusService<EfaItemDTO> {
  private cacheService = inject(CacheService);

  public retrieveAllObservable({ filters }: { filters: Partial<FilterValues> }) {
    return new Observable<RetrieveEfaItemListDTO>((observer) =>
      this.getEfaItems(filters).subscribe((items) => {
        observer.next(items);
        observer.complete();
      }),
    );
  }

  private getEfaItems(filters: Partial<FilterValues>): Observable<RetrieveEfaItemListDTO> {
    const httpParams = this.buildHttpParams(filters);
    const endpoint = 'retrieveEfaItemList';
    const cacheString = `${endpoint}_${httpParams.toString()}`;
    const cachedData = this.cacheService.get(cacheString);
    if (cachedData) {
      return of(cachedData);
    }

    return this.http
      .get<RetrieveEfaItemListDTO>(`${environment.api}${endpoint}`, {
        params: this.buildHttpParams(filters),
      })
      .pipe(
        tap((response) => {
          this.cacheService.set(cacheString, response);
        }),
      );
  }

  private buildHttpParams(filters: Partial<FilterValues>) {
    const query = Object.values(EfaFilterNames).reduce((prev, key) => {
      if ([EfaFilterNames.PAGE, EfaFilterNames.PAGE_SIZE, EfaFilterNames.SEARCH_STRING].includes(key)) {
        return filters[key] ? { ...prev, [key]: filters[key] } : prev;
      }
      if (key == EfaFilterNames.PLANT_CODES_SELECTED) {
        const queryString = this.buildPlantCodesQueryString(filters[key]);
        return queryString ? { ...prev, [key]: queryString } : prev;
      }
      if (
        [EfaFilterNames.ERP_CODES, EfaFilterNames.ITEM_TYPE, EfaFilterNames.RESPONSIBLE, EfaFilterNames.UNITS].includes(
          key,
        )
      ) {
        const queryString = this.buildCheckboxQueryString(filters[key]);
        return queryString ? { ...prev, [key]: queryString } : prev;
      }
      return prev;
    }, {});

    return new HttpParams().appendAll(query);
  }

  private isCheckboxType(v: FilterValues[keyof FilterValues] | undefined): v is Record<string, boolean> {
    return v != null && typeof v === 'object' && Object.values(v).every((b) => typeof b === 'boolean');
  }

  private buildCheckboxQueryString(filter: FilterValues[keyof FilterValues] | undefined): string {
    if (!this.isCheckboxType(filter)) return '';
    const values = reduce<Record<string, boolean>, string[]>(
      filter,
      (prev, value, key) => (value ? [...prev, key] : prev),
      [],
    );
    if (values.length === 0 || values.length === Object.keys(filter).length) {
      return '';
    }
    return values.join(',');
  }

  private buildPlantCodesQueryString(filter: Record<string, SubsetScope> | undefined): string {
    const values = Object.values(filter ?? {}).map(({ plantCode }) => plantCode);
    if (values.length === 0) {
      return '';
    }
    return values.join(',');
  }

  public createEfaItemSupplies(itemSupplies: CreateItemSupplyDTO[]): Observable<void> {
    return new Observable((observer) => {
      this.http
        .post(`${environment.api}createItemSupplies`, { itemSupplies })
        .pipe(
          catchError((error) => {
            return throwError(() => error);
          }),
        )
        .subscribe({
          next: () => {
            this.cacheService.clear();
            observer.next();
            observer.complete();
          },
          error: (err) => {
            observer.error(err);
          },
        });
    });
  }

  public updateEfaItemSupply(itemSupply: UpdateItemSupplyDTO) {
    return new Observable((observer) => {
      this.http.put(`${environment.api}updateItemSupply`, itemSupply).subscribe(() => {
        this.cacheService.clear();
        observer.next();
        observer.complete();
      });
    });
  }

  public updateCharacterisationFactor(characterisationFactor: UpdateCharacterisationFactorDTO) {
    return new Observable((observer) => {
      this.http.put(`${environment.api}updateCharacterisationFactor`, characterisationFactor).subscribe(() => {
        this.cacheService.clear();
        observer.next();
        observer.complete();
      });
    });
  }
}
