import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { CactusService } from '../cactus.service';
import { environment } from '../../../environments/environment';
import { sourceErpCodeList } from '../../../tests/seed/sourceErpCodeList.seed';

export type SourceERPCode = 'B' | 'N' | 'P';

@Injectable({
  providedIn: 'root',
})
export class SourceErpService extends CactusService<SourceERPCode> {
  public inMemorySourceERP: SourceERPCode[] = [];

  retrieveAllObservable(): Observable<SourceERPCode[]> {
    if (['test'].includes(environment.name)) {
      return new Observable<SourceERPCode[]>((observer) => {
        if (!this.useInMemory) {
          this.inMemorySourceERP = sourceErpCodeList;
        }
        observer.next(this.inMemorySourceERP.sort());
        observer.complete();
      });
    }
    return this.getSource();
  }

  private getSource() {
    if (this.useInMemory) {
      return new Observable<SourceERPCode[]>((observer) => {
        observer.next(this.inMemoryData);
        observer.complete();
      });
    }
    return this.http
      .get<{
        data: { sourceErpCode: SourceERPCode }[];
      }>(`${environment.api}retrieveSourceErpCodeList`)
      .pipe(
        map(({ data }) => {
          const inMemorySourceERPCodeList: SourceERPCode[] = [];
          for (const dataQueried of data) {
            inMemorySourceERPCodeList.push(dataQueried.sourceErpCode);
          }
          return this.feedWith(inMemorySourceERPCodeList.sort());
        }),
      );
  }
}
