import { ArkErrors, type } from 'arktype';

export enum EfaBulkUploadSheetName {
  ITEMS = 'items',
  ITEM_SUPPLIES = 'item_supplies',
  CHARACTERISATION_FACTORS = 'characterisation_factors',
}

const EFA_BULK_UPLOAD_BASE_SCHEMA = type({
  itemCode: 'string > 0',
  scopeType: "'WLD' | 'REG' | 'CTY'| 'PLT'",
  scopeCode: 'string > 0',
});

export const EFA_BULK_UPLOAD_ITEM_SUPPLY_SCHEMA = EFA_BULK_UPLOAD_BASE_SCHEMA.and({
  processusLabel: 'string',
  dataSource: 'string',
});

export type EfaBulkUploadItemSupplyRow = typeof EFA_BULK_UPLOAD_ITEM_SUPPLY_SCHEMA.infer;

export const parseIfNotNumber = type.string.or(type.number).pipe((value) => {
  if (typeof value === 'number') {
    return value;
  }
  const parsedNumber = Number.parseFloat(value.replace(',', '.'));
  return parsedNumber;
}, type.number);

const EFA_INDICATOR_VALUE_BASE_SCHEMA = type({
  indicatorValue: parseIfNotNumber,
  indicatorCode: "'EFA_TOTAL'",
});

export const EFA_BULK_UPLOAD_CHARACTERISATION_FACTOR_ROW_SCHEMA = EFA_BULK_UPLOAD_BASE_SCHEMA.and(
  EFA_INDICATOR_VALUE_BASE_SCHEMA,
);

export type EfaBulkUploadCharacterisationFactorRow = typeof EFA_BULK_UPLOAD_CHARACTERISATION_FACTOR_ROW_SCHEMA.infer;

export const EFA_BULK_UPLOAD_SCHEMA = EFA_BULK_UPLOAD_ITEM_SUPPLY_SCHEMA.and({
  responsible: 'string > 0',
  indicators: EFA_INDICATOR_VALUE_BASE_SCHEMA.array().atLeastLength(1),
});

export type EfaBulkUpload = typeof EFA_BULK_UPLOAD_SCHEMA.infer;

export type EfaBulkUploadPreviewTableData = EfaBulkUploadItemSupplyRow &
  typeof EFA_INDICATOR_VALUE_BASE_SCHEMA.infer & { id: string };

export interface EfaBulkUploadErrors {
  [EfaBulkUploadSheetName.ITEM_SUPPLIES]?: ArkErrors;
  [EfaBulkUploadSheetName.CHARACTERISATION_FACTORS]?: ArkErrors;
  combined?: ArkErrors;
}

export interface EfaBulkUploadErrorTableData {
  location: string;
  row: number;
  cell: string;
  error: string;
}
