import { DateProvider } from '../../year-of-consumption/model/date-provider';
import { Responsible, ResponsibleModel } from '../../responsible/model/responsible.model';
import { SubsetScope } from '../../scopes/model/scope.model';
import { UniqueId } from '../../../../tests/uniqueId';

export type StatusLabel = 'Created' | 'In progress' | 'Published' | 'Rework';

export interface Subset {
  name: string;
  uuid: string;
  userSubsetId: number;
  scopes: SubsetScope[];
  scopesShort: string;
  fiscalYear: string;
  lastCalculationIteration: number;
  lastCalculationDate: Date | null;
  lastCalculationBy: Responsible;
  publishedCalculationIteration: number;
  publicationDate: Date | null;
  publicationBy: Responsible;
  creationDate: Date;
  updateDate: Date | null;
  updateBy: Responsible;
  responsible: Responsible;
  status: StatusLabel;
  statusDate: Date | null;
  comment: string;
}

/**
 * fixme: temporary, will be refactored
 * @deprecated
 */
export interface SubsetDatabase {
  subset_uuid: string;
  user_subset_id: number;
  subset_name: string;
  fiscal_year: string;
  responsible: string;
  subset_status: StatusLabel;
  status_date: string | null;
  comment: string;
  last_calculation_iteration: number;
  last_calculation_by: string;
  last_calculation_date: string | null;
  published_calculation_iteration: number;
  publication_by: string;
  publication_date: string | null;
  creation_date: string;
  update_by: string;
  update_date: string | null;
  scopes_info: string;
}

export class SubsetModel {
  private readonly props: Subset = {
    name: '',
    uuid: '',
    userSubsetId: NaN,
    scopes: [],
    scopesShort: '',
    fiscalYear: '',
    lastCalculationIteration: NaN,
    lastCalculationDate: null,
    lastCalculationBy: ResponsibleModel.builder().build(),
    publishedCalculationIteration: NaN,
    publicationDate: null,
    publicationBy: ResponsibleModel.builder().build(),
    creationDate: DateProvider.now(),
    updateDate: null,
    updateBy: ResponsibleModel.builder().build(),
    responsible: ResponsibleModel.builder().build(),
    status: 'Created',
    statusDate: null,
    comment: '',
  };

  constructor(param: Partial<Subset>) {
    this.props = Object.assign(this.props, param);
  }

  static builder(): SubsetModelBuilder {
    return new SubsetModelBuilder();
  }

  getValues() {
    return this.props;
  }
}

class SubsetModelBuilder {
  private name: string;
  private uuid: string = UniqueId.nextId();
  private userSubsetId: number;
  private scopes: SubsetScope[] = [];
  private responsible: Responsible = ResponsibleModel.builder().build();
  private status: StatusLabel = 'Created';
  private statusDate: Date | null;
  private scopesShort = '';
  private comment = '';
  private fiscalYear: string;
  private lastCalculationIteration: number;
  private lastCalculationDate: Date | null;
  private lastCalculationBy: Responsible;
  private publishedCalculationIteration: number;
  private publicationDate: Date | null;
  private publicationBy: Responsible;
  private creationDate: Date;
  private updateDate: Date | null;
  private updateBy: Responsible = ResponsibleModel.builder().build();

  build() {
    const subset = new SubsetModel({
      name: this.name,
      uuid: this.uuid,
      userSubsetId: this.userSubsetId,
      scopes: this.scopes,
      scopesShort: this.scopesShort,
      fiscalYear: this.fiscalYear,
      lastCalculationIteration: this.lastCalculationIteration,
      lastCalculationDate: this.lastCalculationDate,
      lastCalculationBy: this.lastCalculationBy,
      publishedCalculationIteration: this.publishedCalculationIteration,
      publicationDate: this.publicationDate,
      publicationBy: this.publicationBy,
      creationDate: this.creationDate,
      updateDate: this.updateDate,
      updateBy: this.updateBy,
      responsible: this.responsible,
      status: this.status,
      statusDate: this.statusDate,
      comment: this.comment,
    });
    return subset.getValues();
  }

  withName(name: string) {
    this.name = name;
    return this;
  }

  withUuid(uuid: string) {
    this.uuid = uuid;
    return this;
  }

  withUserSubsetId(id: number) {
    this.userSubsetId = id;
    return this;
  }

  withScope(scope: SubsetScope) {
    if (!this.scopes) {
      this.scopes = [];
    }
    this.scopes.push(scope);

    const scopes = this.scopes.map(
      ({ plantCode, name }) => `${plantCode} - ${name.substring(0, 1).toUpperCase()}${name.substring(1).toLowerCase()}`,
    );
    this.scopesShort = scopes.join(', ');

    if (this.scopesShort.length > 40) {
      this.scopesShort = this.scopesShort.substring(0, 40) + '…';
    }

    return this;
  }

  withResponsible(responsible: Responsible) {
    this.responsible = responsible;
    return this;
  }

  withStatus(status: StatusLabel) {
    this.status = status;
    return this;
  }

  withComment(comment: string) {
    this.comment = comment;
    return this;
  }

  withYear(fiscalYear: string) {
    this.fiscalYear = fiscalYear;
    return this;
  }

  withLastCalculationIteration(iteration: number) {
    this.lastCalculationIteration = iteration;
    return this;
  }

  withLastCalculationDate(date: Date | null) {
    this.lastCalculationDate = date;
    return this;
  }

  withLastCalculationBy(responsible: Responsible) {
    this.lastCalculationBy = responsible;
    return this;
  }

  withPublicationIteration(iteration: number) {
    this.publishedCalculationIteration = iteration;
    return this;
  }

  withPublicationDate(date: Date | null) {
    this.publicationDate = date;
    return this;
  }

  withPublicationBy(responsible: Responsible) {
    this.publicationBy = responsible;
    return this;
  }

  withCreationDate(date: Date) {
    this.creationDate = date;
    return this;
  }

  withUpdateDate(date: Date | null) {
    this.updateDate = date;
    return this;
  }

  withUpdateBy(responsible: Responsible) {
    this.updateBy = responsible;
    return this;
  }

  withStatusDate(date: Date | null) {
    this.statusDate = date;
    return this;
  }

  withSubset(subset: Subset) {
    Object.assign(this, subset);
    return this;
  }
}
