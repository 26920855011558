import { Component, computed, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';
import { NgClass, NgIf, NgOptimizedImage } from '@angular/common';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { AuthService } from './services/auth/auth.service';
import { ChangePageEvent } from './utils/cactus-events/changepage';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, AmplifyAuthenticatorModule, NgIf, RouterLink, RouterLinkActive, NgClass, NgOptimizedImage],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly authService = inject(AuthService);
  private changePageEvent: ChangePageEvent = inject(ChangePageEvent);
  isLoginChecked = signal(false);
  isLogoLoaded = signal(false);
  isLoaded = computed(() => this.isLoginChecked() && this.isLogoLoaded());
  isUserPopupDisplayed = signal(false);
  public ngOnInit(): void {
    this.authService.initialize();
    this.authService.login().finally(() => {
      this.isLoginChecked.set(this.authService.isLoginChecked());
    });
  }

  dispatchChangePageEvent(page: string) {
    this.changePageEvent.nextRoute = page;
    window.dispatchEvent(this.changePageEvent.generateEvent());
  }

  public ngOnDestroy(): void {
    this.authService.destroy();
  }

  onLogoLoad() {
    this.isLogoLoaded.set(true);
  }

  isProfileAdvanced() {
    return this.authService.getProfile() === 'advanced';
  }

  getShortName() {
    const { firstName, lastName } = this.authService.getName();
    return firstName.substring(0, 1).toUpperCase() + lastName.substring(0, 1).toUpperCase();
  }

  getName() {
    const { firstName, lastName } = this.authService.getName();
    return `${firstName} ${lastName.toUpperCase()}`;
  }
}
