<table class="w-full border-collapse rounded-t-2xl mt-6">
  <thead class="bg-emerald-600 text-white h-12 sticky top-0">
    @for (headerGroup of table.getHeaderGroups(); track headerGroup.id) {
      <tr>
        @if ((table.getRowModel().rows[0]?.subRows ?? []).length > 0) {
          @for (header of headerGroup.headers; track header.id; let first = $first) {
            @if (!(grouping() ?? []).includes(header.id)) {
              <th
                [attr.colSpan]="header.colSpan"
                [style.width.%]="header.column.columnDef.size === 150 ? undefined : header.column.columnDef.size"
              >
                @if (!header.isPlaceholder) {
                  <ng-container *flexRender="header.column.columnDef.header; props: header.getContext(); let header">
                    {{ header }}
                  </ng-container>
                }
              </th>
            }
          }
        } @else {
          @for (header of headerGroup.headers; track header.id) {
            <th
              [attr.colSpan]="header.colSpan"
              [style.width.%]="header.column.columnDef.size === 150 ? undefined : header.column.columnDef.size"
            >
              @if (!header.isPlaceholder) {
                <ng-container *flexRender="header.column.columnDef.header; props: header.getContext(); let header">
                  {{ header }}
                </ng-container>
              }
            </th>
          }
        }
      </tr>
    }
  </thead>
  <tbody>
    <!-- We have table grouping-->
    @if ((table.getGroupedRowModel().rows[0]?.subRows ?? []).length > 0) {
      @for (row of table.getGroupedRowModel().rows; track row.id) {
        <app-recursive-grouped-rows
          [row]="row"
          [groupedTableRows]="groupedTableRows()"
          [actionButtonCallbacks]="actionButtonCallbacks()"
          [grouping]="grouping() ?? []"
        ></app-recursive-grouped-rows>
      }
    } @else {
      @for (row of table.getRowModel().rows; track row.id) {
        <tr>
          @for (cell of row.getVisibleCells(); track cell.id) {
            <td class="text-center">
              <ng-container *flexRender="cell.column.columnDef.cell; props: cell.getContext(); let cell">
                {{ cell }}
              </ng-container>
            </td>
          }
        </tr>
      }
    }
  </tbody>
</table>
