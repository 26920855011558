import { NgClass } from '@angular/common';
import { Component, input, InputSignal } from '@angular/core';

@Component({
  selector: 'app-popover',
  standalone: true,
  imports: [],
  template: `
    <!-- Wrapper must be relative to use this component -->
    <div
      class="block absolute top-full left-0 bg-white z-40 p-4 shadow-lg max-h-60 overflow-y-auto rounded-lg overflow-x-hidden"
    >
      <ng-content></ng-content>
    </div>
  `,
})
export class PopoverComponent {}
