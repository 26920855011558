import { Component, input, output } from '@angular/core';
import {
  CommandComponent,
  InputDirective,
  ListComponent,
  EmptyDirective,
  CmdkService,
  ItemDirective,
  LoaderDirective,
} from '@ngxpert/cmdk';
import { ModalComponent } from '../modal/modal.component';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Item } from '../../../services/items/items.service';

@Component({
  selector: 'app-cmdk-item-search',
  standalone: true,
  imports: [
    CommandComponent,
    InputDirective,
    ListComponent,
    EmptyDirective,
    ModalComponent,
    ReactiveFormsModule,
    ItemDirective,
    LoaderDirective,
  ],
  providers: [CmdkService],
  template: `
    <app-modal (close)="onClose()">
      <ng-container title>Search for an item...</ng-container>
      <cmdk-command body [loading]="loading()" class="linear mt-7">
        <input cmdkInput [formControl]="searchStringControl()" />
        <div *cmdkLoader>Fetching...</div>
        <cmdk-list>
          <div *cmdkEmpty>No results found.</div>
          @for (item of items(); track item.user_item_code) {
            <button cmdkItem (selected)="onSelect(item)">{{ item.user_item_code }} {{ item.item_name }}</button>
          }
        </cmdk-list>
      </cmdk-command>
    </app-modal>
  `,
})
export class CmdkItemSearch {
  searchStringControl = input.required<FormControl<string>>();
  loading = input.required<boolean>();
  items = input.required<Item[]>();
  onSelectCallback = input.required<(item: Item) => void>();
  close = output<void>();

  onSelect(item: Item) {
    this.onSelectCallback()(item);
  }

  onClose() {
    this.close.emit();
  }
}
