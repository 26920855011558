import { Injectable } from '@angular/core';

interface ICactusMetadata {
  totalCount: number;
  page: number; // positive number starting from 1
  pageSize: number; // positive number starting from 1
}

@Injectable({
  providedIn: 'root',
})
export class Pagination {
  isThereNextPage({ totalCount, pageSize, page }: ICactusMetadata): boolean {
    const totalNumberOfPages = Math.ceil(totalCount / pageSize);
    if (totalCount - pageSize > 0) {
      return page < totalNumberOfPages;
    }
    return false;
  }
}
