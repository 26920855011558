<div class="flex justify-center mt-20">
  <div class="h-[465px] justify-start items-center gap-[169px] inline-flex">
    <div class="w-[965px] text-[#188d6d] text-[100px] font-semibold font-['Work Sans']">Welcome to Cactus</div>
    <img
      class="w-[463px] h-[465px]"
      ngSrc="../../../assets/homepage-big-logo.png"
      height="223"
      width="222"
      alt="cactus app logo"
    />
  </div>
</div>
