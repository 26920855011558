import { AbstractControl, ValidationErrors } from '@angular/forms';
import { Type, type } from 'arktype';

export function validateWithSchema(schema: Type) {
  return (control: AbstractControl) => {
    const out = schema(control.getRawValue());
    if (out instanceof type.errors) {
      out.forEach((err) => {
        const cont = control.get(err.path.join('.'));
        if (cont && cont.dirty) {
          cont.setErrors({ error: err.message });
        }
      });
      /** The key doesn't matter, we are only checking the length @see submit.component.ts */
      return out.reduce<ValidationErrors>((prev, curr, index) => {
        prev[`${index}`] = curr;
        return prev;
      }, {});
    }
    return null;
  };
}
