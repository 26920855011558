import { NgIf } from '@angular/common';
import { Component, input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';

type SupportedInputTypes =
  | 'email'
  | 'number'
  | 'password'
  | 'search'
  | 'tel'
  | 'text'
  | 'url'
  | 'date'
  | 'datetime-local'
  | 'month'
  | 'year'
  | 'week';

@Component({
  selector: 'app-form-input',
  standalone: true,
  imports: [ReactiveFormsModule, NgIf],
  /** For some reason angular doesn't like a dynamic type @see https://stackoverflow.com/questions/72569862/angular-custom-input-with-reactiveform-returns-strings-instead-of-numbers */
  template: `
    <label [for]="'input_' + label()" class="mt-6" [formGroup]="group()">
      {{ label() }}
      <input
        *ngIf="inputType() === 'email'"
        [id]="'input_' + label()"
        [formControlName]="controlName()"
        type="email"
        [class]="inputStyles"
      />
      <input
        *ngIf="inputType() === 'number'"
        [id]="'input_' + label()"
        [formControlName]="controlName()"
        type="number"
        [class]="inputStyles"
      />
      <input
        *ngIf="inputType() === 'password'"
        [id]="'input_' + label()"
        [formControlName]="controlName()"
        type="password"
        [class]="inputStyles"
      />
      <input
        *ngIf="inputType() === 'search'"
        [id]="'input_' + label()"
        [formControlName]="controlName()"
        type="search"
        [class]="inputStyles"
      />
      <input
        *ngIf="inputType() === 'tel'"
        [id]="'input_' + label()"
        [formControlName]="controlName()"
        type="tel"
        [class]="inputStyles"
      />
      <input
        *ngIf="inputType() === 'text'"
        [id]="'input_' + label()"
        [formControlName]="controlName()"
        type="text"
        [class]="inputStyles"
      />
      <input
        *ngIf="inputType() === 'url'"
        [id]="'input_' + label()"
        [formControlName]="controlName()"
        type="url"
        [class]="inputStyles"
      />
      <input
        *ngIf="inputType() === 'date'"
        [id]="'input_' + label()"
        [formControlName]="controlName()"
        type="date"
        [class]="inputStyles"
      />
      <input
        *ngIf="inputType() === 'datetime-local'"
        [id]="'input_' + label()"
        [formControlName]="controlName()"
        type="datetime-local"
        [class]="inputStyles"
      />
      <input
        *ngIf="inputType() === 'month'"
        [id]="'input_' + label()"
        [formControlName]="controlName()"
        type="month"
        [class]="inputStyles"
      />
      <input
        *ngIf="inputType() === 'year'"
        [id]="'input_' + label()"
        [formControlName]="controlName()"
        type="year"
        [class]="inputStyles"
      />
      <input
        *ngIf="inputType() === 'week'"
        [id]="'input_' + label()"
        [formControlName]="controlName()"
        type="week"
        [class]="inputStyles"
      />
    </label>
    <p class="text-red-600">{{ error }}</p>
  `,
})
export class FormInputComponent {
  group = input.required<FormGroup>();
  controlName = input.required<string>();
  label = input.required<string>();
  inputType = input<SupportedInputTypes>('text');

  public get error() {
    const err = this.group().controls[this.controlName()].errors?.['error'] ?? '';

    if (!err) {
      return '';
    }
    const [key, ...rest] = err.split(' ');
    return [this.label(), ...rest].join(' ');
  }

  public inputStyles = 'h-10';
}
