import { inject, Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { expand, last, map, Observable, of, takeWhile } from 'rxjs';

import { CactusService } from '../cactus.service';
import { EfaItem, EfaItemModel } from '../../pages/efa-management/model/item';
import { efaItemSeed } from '../../../tests/seed/efaItem.seed';
import { environment } from '../../../environments/environment';
import { EfaItemType } from '../efa-item-type/efa-item-type.service';
import { Pagination } from '../../utils/pagination/pagination';
import { SourceERPCode } from '../source-erp/source-erp.service';

interface IRetrieveItem {
  data: EfaItem[];
  metadata: {
    totalCount: number;
    page: number;
    pageSize: number;
  };
}

@Injectable({
  providedIn: 'root',
})
export class EfaItemsService extends CactusService<EfaItem> {
  private pagination: Pagination = inject(Pagination);
  private _inMemoryData: EfaItem[] = [];
  private needToFetchNextPage = false;
  private nextPage: number;

  retrieveAllObservable({ itemTypes }: { itemTypes?: EfaItemType[] }): Observable<EfaItem[]> {
    if (['test'].includes(environment.name)) {
      return new Observable<EfaItem[]>((observer) => {
        if (!this.useInMemory) {
          this.inMemoryData = efaItemSeed;
        }
        observer.next(this.inMemoryData.filter((item) => (itemTypes ? itemTypes.includes(item.itemType) : true)));
        observer.complete();
      });
    }
    return new Observable<EfaItem[]>((observer) => {
      this.getHttpRequest({ page: this.nextPage, pageSize: 9000, itemTypes })
        .pipe(
          expand(() => {
            if (this.needToFetchNextPage) {
              return this.getHttpRequest({ page: this.nextPage, pageSize: 9000, itemTypes });
            } else {
              return of(null);
            }
          }),
          takeWhile((response) => response !== null),
          last(),
        )
        .pipe(map((result) => this.feedWith(result)))
        .subscribe((items) => {
          observer.next(items);
          observer.complete();
        });
    });
  }

  private getHttpRequest({
    plantCodes,
    itemTypes,
    sourcesErpCodes,
    itemName,
    pageSize = 9000,
    page = 1,
  }: {
    plantCodes?: string[];
    itemTypes?: EfaItemType[];
    sourcesErpCodes?: string[];
    itemName?: string;
    pageSize?: number;
    page?: number;
  }) {
    let params = new HttpParams().set('pageSize', `${pageSize}`).append('page', `${page}`);
    if (plantCodes) {
      params = params.append('plantCodes', plantCodes.join(','));
    }
    if (itemTypes) {
      params = params.append('type', itemTypes.join(','));
    }
    if (sourcesErpCodes) {
      params = params.append('sourcesErpCodes', sourcesErpCodes.join(','));
    }
    if (itemName) {
      params = params.append('itemName', itemName);
    }
    this.nextPage = page + 1;
    return this.http
      .get<IRetrieveItem>(`${environment.api}retrieveEfaItemList`, {
        params,
      })
      .pipe(
        map((result) => {
          const items: EfaItem[] = this.addResultToInMemory(result.data);

          if (this.pagination.isThereNextPage(result?.metadata)) {
            this.needToFetchNextPage = true;
          } else {
            this.needToFetchNextPage = false;
          }

          return items;
        }),
      );
  }

  private addResultToInMemory(data: EfaItem[]) {
    const inMemoryEfaItemList: EfaItem[] = [];
    for (const efaItemQueried of data) {
      const efaItem = EfaItemModel.builder()
        .withSourceErpCode(efaItemQueried.sourceErpCode as SourceERPCode)
        .withCode(efaItemQueried.itemCode)
        .withUserItemCode(efaItemQueried.userItemCode)
        .withName(efaItemQueried.itemName)
        .withType(efaItemQueried.itemType)
        .withUnitCode(efaItemQueried.itemUnitCode)
        .build();
      inMemoryEfaItemList.push(efaItem);
    }

    this._inMemoryData = [...this._inMemoryData, ...inMemoryEfaItemList];
    return this._inMemoryData;
  }

  retrieveFromFilter(params: {
    plantCodes?: string[];
    itemTypes?: EfaItemType[];
    sourcesErpCodes?: SourceERPCode[];
    itemName?: string;
    pageSize?: number;
    page?: number;
  }): Observable<EfaItem[]> {
    if (['test'].includes(environment.name)) {
      return new Observable<EfaItem[]>((observer) => {
        const items = this.inMemoryData
          .filter((item) => (params.itemTypes?.length ? params.itemTypes.includes(item.itemType) : true))
          .filter((item) =>
            params.sourcesErpCodes?.length
              ? params.sourcesErpCodes.includes(item.sourceErpCode as SourceERPCode)
              : true,
          );

        observer.next(items);
        observer.complete();
      });
    }
    this._inMemoryData = [];
    return this.getHttpRequest(params);
  }
}
