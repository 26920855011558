import { NgClass } from '@angular/common';
import { Component, input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-form-submit',
  standalone: true,
  imports: [ReactiveFormsModule, NgClass],
  template: `
    <button
      [ngClass]="disabled ? 'bg-slate-400 cursor-not-allowed' : 'bg-slate-600 hover:bg-slate-500 cursor-pointer'"
      class=" text-white py-1 px-3 rounded-md my-2"
      type="submit"
      [disabled]="disabled"
    >
      <ng-content></ng-content>
    </button>
  `,
})
export class FormSubmitComponent {
  group = input.required<FormGroup>();

  public get disabled() {
    return Object.keys(this.group().errors ?? []).length;
  }
}
