import { Component, input, InputSignal, output, Type } from '@angular/core';
import {
  createAngularTable,
  ColumnDef,
  getCoreRowModel,
  getGroupedRowModel,
  FlexRenderDirective,
  getExpandedRowModel,
  VisibilityState,
  Row,
  Table,
} from '@tanstack/angular-table';
import { RecursiveGroupedRowsComponent } from './recursive-grouped-rows/recursive-grouped-rows-component';

export interface GenericGroupedTableRow<T> {
  row: InputSignal<Row<T>>;
  noSubRows: InputSignal<number>;
  actionButtonCallback: InputSignal<(row: Row<T>) => void>;
}

export type GroupedTableRowComponent<T> = Type<GenericGroupedTableRow<T>>;

@Component({
  selector: 'app-table',
  standalone: true,
  imports: [FlexRenderDirective, RecursiveGroupedRowsComponent],
  templateUrl: './table.component.html',
})
export class TableComponent<T extends Record<string, any>> {
  tableData = input.required<T[] | undefined>();
  tableColumns = input.required<ColumnDef<T>[]>();

  grouping = input<string[] | null>(null);
  columnVisibility = input<VisibilityState>({});

  groupedTableRows = input<Array<GroupedTableRowComponent<T>>>([]);
  actionButtonCallbacks = input<Array<(row: Row<T>) => void>>([]);

  table = createAngularTable(() => ({
    data: this.tableData() ?? [],
    columns: this.tableColumns(),
    state: {
      columnVisibility: this.columnVisibility(),
      ...(() => {
        const grouping = this.grouping();
        return grouping != null ? { grouping: grouping } : {};
      })(),
    },
    getCoreRowModel: getCoreRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  }));

  public get tableRows() {
    return this.table.getRowModel().rows[0];
  }

  public expandAllRows() {
    this.table.toggleAllRowsExpanded();
  }
}
