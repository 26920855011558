@if (bulkUploadModelOpenSignal()) {
  <app-efa-bulk-upload-modal (close)="bulkUploadModelOpenSignal.set(false)"></app-efa-bulk-upload-modal>
}
@if (addOrEditModelOpenSignal()) {
  <app-efa-add-edit-model
    (close)="addOrEditModelOpenSignal.set(false)"
    (createOrUpdateTriggered)="performManualSearch()"
    [efaForm]="efaForm"
  >
  </app-efa-add-edit-model>
}

<app-efa-filter-drawer
  [open]="filterDrawerOpen()"
  [invertFilterDrawerOpenStatus]="invertFilterDrawerOpenStatus"
  [filterForm]="filterForm"
></app-efa-filter-drawer>
<form [formGroup]="filterForm" class="inline-flex w-full justify-between">
  <div class="inline-flex items-end">
    <button
      class="bg-slate-600 hover:bg-slate-500 text-white text-3xl w-10 h-10 rounded-lg mb-2 mr-8"
      (click)="invertFilterDrawerOpenStatus()"
    >
      &#8704;
    </button>
    <label for="searchString"
      >Search
      <input
        id="searchString"
        formControlName="searchString"
        placeholder="Search by item code or item name..."
        class="h-10"
      />
    </label>
  </div>
  <div class="inline-flex items-center">
    <button
      (click)="bulkUploadModelOpenSignal.set(true)"
      class="bg-slate-600 hover:bg-slate-500 text-white rounded-lg py-2 px-4 text-sm mt-4 mr-6"
    >
      Bulk Upload
    </button>
    <button
      (click)="addOrEditModelOpenSignal.set(true)"
      class="bg-slate-600 hover:bg-slate-500 text-white rounded-lg py-2 px-4 text-sm mt-4 mr-6"
    >
      Add Item Supply
    </button>
    <div class="relative">
      <h5 class="text-slate-400 text-xs">Table View</h5>
      <div
        class="border-[1px] border-slate-300 rounded-lg inline-flex justify-between items-center py-1 [&>*]:mx-2 pb-1"
      >
        <button
          (click)="invertShowColumnVisibilityPopover()"
          class="bg-slate-600 hover:bg-slate-500 text-white text-4xl w-10 h-10 rounded-lg"
        >
          &#10872;
        </button>
        <button
          class="bg-slate-600 hover:bg-slate-500 text-white text-3xl w-10 h-10 rounded-lg"
          (click)="toggleTableView()"
        >
          &#8801;
        </button>
        <button
          (click)="tableComponent.expandAllRows()"
          class="bg-slate-600 hover:bg-slate-500 text-white text-3xl w-10 h-10 rounded-lg pb-1"
        >
          &#9781;
        </button>
      </div>
      @if (showColumnVisibilityPopover()) {
        <app-popover>
          <form [formGroup]="tableForm.controls.columnVisibility" class="grid grid-cols-[auto_1fr] w-52">
            @for (column of columnVisibilityControls; track i; let i = $index) {
              @if (!columnIsGrouped(column[0])) {
                <input
                  type="checkbox"
                  [id]="column[0] + '_checkbox'"
                  class="w-[20px] h-[20px] accent-emerald-600 mr-4"
                  [formControlName]="column[0]"
                  [value]="column[1].value"
                />
                <label [for]="column[0] + '_checkbox'" class="text-left">{{ columnHeaders[column[0]] }}</label>
              }
            }
          </form>
        </app-popover>
      }
    </div>
    <div class="flex flex-col ml-12">
      <div class="inline-flex justify-between text-slate-400 text-xs">
        <h5>Pagination</h5>
        @if (totalResultsFound() !== undefined) {
          <h5>Found: {{ totalResultsFound() }}</h5>
        }
      </div>
      <div class="border-[1px] border-slate-300 rounded-lg inline-flex justify-between items-center py-1 [&>*]:mx-2">
        <button
          class="bg-slate-600 hover:bg-slate-500 text-white text-3xl w-10 h-10 rounded-lg"
          (click)="decrementPageSize(); performManualSearch()"
        >
          &#8249;
        </button>
        <div class="mx-4 text-xl font-semibold w-4 flex items-center justify-center">
          {{ filterForm.controls['page'].value }}
        </div>
        <button
          class="bg-slate-600 hover:bg-slate-500 text-white text-3xl w-10 h-10 rounded-lg"
          (click)="incrementPageSize(); performManualSearch()"
        >
          &#8250;
        </button>
        <select class="w-20 h-10" formControlName="pageSize" (change)="performManualSearch()">
          @for (size of this.pageSizeOptions; track size) {
            <option>{{ size }}</option>
          }
        </select>
      </div>
    </div>
  </div>
</form>
<app-table
  [tableData]="tableData()"
  [tableColumns]="columns"
  [grouping]="groupingValue"
  [groupedTableRows]="groupedTableRows"
  [actionButtonCallbacks]="actionButtonCallbacks"
  [columnVisibility]="columnVisibilityValue"
></app-table>
