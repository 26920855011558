import { Amplify } from 'aws-amplify';
import { Hub } from 'aws-amplify/utils';
import type { HubCallback } from '@aws-amplify/core';
import { inject, Injectable, signal, WritableSignal } from '@angular/core';
import { Router } from '@angular/router';
import { signInWithRedirect, signOut } from 'aws-amplify/auth';

import { AuthSessionService } from './session/auth-session.service';
import { AuthCactus } from './authCactus';
import { environment } from '../../../environments/environment';
import { Responsible } from '../responsible/model/responsible.model';

type StopListenerCallback = () => void;

enum AuthEvents {
  SIGN_IN = 'signIn',
  SIGNED_IN = 'signedIn',
  SIGN_OUT = 'signedOut',
  SIGN_IN_WITH_REDIRECT = 'signInWithRedirect',
  SIGN_IN_WITH_REDIRECT_FAILURE = 'signInWithRedirect_failure',
  TOKEN_REFRESH = 'tokenRefresh',
}

export type UserProfile = 'standard' | 'advanced';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private router = inject(Router);
  private fetchAuthSessionService: AuthSessionService = inject(AuthSessionService);

  private authCactus: AuthCactus;
  private destroyListener: StopListenerCallback;

  public isLoginChecked: WritableSignal<boolean> = signal(false);

  initialize(): void {
    Amplify.configure({
      Auth: {
        Cognito: {
          userPoolId: environment.aws.auth.userPoolId,
          userPoolClientId: environment.aws.auth.userPoolWebClientId,
          loginWith: {
            oauth: environment.aws.auth.oauth,
            username: false,
            email: true,
            phone: false,
          },
        },
      },
    });

    const authListener: HubCallback = async ({ payload: { event, data } }) => {
      switch (event) {
        case AuthEvents.SIGN_IN_WITH_REDIRECT:
        case AuthEvents.SIGN_IN:
        case AuthEvents.SIGNED_IN:
        case AuthEvents.TOKEN_REFRESH:
          await this.handleSignIn();
          break;
        case AuthEvents.SIGN_OUT:
          await this.handleSignOut();
          break;
        case AuthEvents.SIGN_IN_WITH_REDIRECT_FAILURE:
          console.error('Sign in failure', data);
          break;
        default:
          console.error('Unhandled auth event', event);
          break;
      }
    };

    this.destroyListener = Hub.listen('auth', authListener);
    this.authCactus = new AuthCactus(this.fetchAuthSessionService);
  }

  async login(): Promise<void> {
    const isLoggedIn = await this.authCactus.isLoggedIn();

    if (!isLoggedIn) {
      const queryString = new URLSearchParams(window.location.search);
      if (queryString.has('error_description')) {
        console.error(queryString.get('error_description'));
        return;
      }

      await signInWithRedirect({
        provider: (environment.aws.auth.oauth.providers || [])[0],
      });
    } else {
      await this.handleSignIn();
    }
    this.isLoginChecked.set(true);
  }

  destroy() {
    this.destroyListener();
  }

  getProfile(): UserProfile {
    if (this.authCactus.attributes['custom:isAdvanced'] === 'true') {
      return 'advanced';
    }
    return 'standard';
  }

  getBearerToken(): string {
    return this.authCactus.bearerToken;
  }

  private async handleSignIn(): Promise<void> {
    this.authCactus.attributes = await this.fetchAuthSessionService.getAttributes();
  }

  private async handleSignOut(): Promise<boolean> {
    this.authCactus = new AuthCactus(this.fetchAuthSessionService);
    await signOut();
    return this.router.navigateByUrl('/');
  }

  getName() {
    const { firstName, lastName } = this.formatEmail(this.authCactus.attributes.email || '');

    return {
      firstName,
      lastName,
    };
  }

  private formatEmail(mail: string) {
    const firstName = this.capitalName(mail.split('.')[0]);
    let lastName = mail.split('.')[1].split('@')[0];
    if (lastName.endsWith('-ext')) {
      lastName = lastName.substring(0, lastName.length - 4);
    }
    lastName = this.capitalName(lastName);
    return { firstName, lastName };
  }

  private capitalName(name: string) {
    return name.charAt(0).toUpperCase() + name.slice(1);
  }

  getCurrentUserUuid(responsibleList: Responsible[]): string | undefined {
    if (!this.authCactus.attributes.email) {
      return undefined;
    }
    return responsibleList.find(
      (responsible) =>
        this.getName().lastName === responsible.lastName && this.getName().firstName === responsible.firstName,
    )?.uuid;
  }
}
