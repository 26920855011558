import { Component, inject, input } from '@angular/core';

import { NgClass } from '@angular/common';
import { SourceERPCode } from '../../../services/source-erp/source-erp.service';

import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { UnitService } from '../../../services/unit/unit.service';
import { ResponsibleService } from '../../../services/responsible/responsible.service';
import { ScopesService } from '../../../services/scopes/scopes.service';
import { EfaFilterNames } from '../../../services/efa-items/efa-items.service';
import { FilterFormGroup } from '../efa-management.types';

@Component({
  selector: 'app-efa-filter-drawer',
  standalone: true,

  imports: [NgClass, ReactiveFormsModule],
  templateUrl: './efa-filter-drawer.component.html',
  styleUrl: './efa-filter-drawer.component.css',
})
export class EfaFilterDrawerComponent {
  private scopeService = inject(ScopesService);
  private responsibleService = inject(ResponsibleService);
  private unitsService = inject(UnitService);

  private plantCodesByIdSignal = this.scopeService.retrievePlantCodesByIdSignal();
  private responsiblesByIdSignal = this.responsibleService.retrieveResponsiblesByIdSignal();
  private unitsByUnitCodeSignal = this.unitsService.retrieveUnitsByUnitCodeSignal();

  public filterForm = input.required<FormGroup<FilterFormGroup>>();
  public open = input<boolean>(false);
  public invertFilterDrawerOpenStatus = input<() => void>(() => {});

  public erpLabels: { [key in SourceERPCode]: string } = {
    B: 'Best',
    N: 'Nice',
    P: 'PRD',
  };

  private isErpCode(code: string): code is SourceERPCode {
    return code in this.erpLabels;
  }

  public getErpLabel(erpCode: string) {
    return this.isErpCode(erpCode) ? this.erpLabels[erpCode] : erpCode;
  }

  public getResponsibleLabel(responsibleUuid: string) {
    const responsible = this.responsiblesByIdSignal()[responsibleUuid];
    if (!responsible) {
      return responsibleUuid;
    }
    return `${responsible.firstName} ${responsible.lastName}`;
  }

  public getUnitLabel(unitCode: string) {
    return this.unitsByUnitCodeSignal()[unitCode]?.unit_name ?? unitCode;
  }

  public get erpCodeControls() {
    return Object.entries(this.filterForm().controls[EfaFilterNames.ERP_CODES].controls);
  }

  public get itemTypesControls() {
    return Object.entries(this.filterForm().controls[EfaFilterNames.ITEM_TYPE].controls);
  }

  public get responsiblesControls() {
    return Object.entries(this.filterForm().controls[EfaFilterNames.RESPONSIBLE].controls);
  }

  public get unitsControls() {
    return Object.entries(this.filterForm().controls[EfaFilterNames.UNITS].controls);
  }

  private get plantCodeSearchControl() {
    return this.filterForm().controls[EfaFilterNames.PLANT_CODES_SEARCH];
  }

  private get selectedPlantCodesControl() {
    return this.filterForm().controls[EfaFilterNames.PLANT_CODES_SELECTED];
  }

  public get plantCodes() {
    return Object.values(this.plantCodesByIdSignal());
  }

  public get selectedPlantCodes() {
    return Object.values(this.selectedPlantCodesControl.value);
  }

  public onScopeToAdd(event: Event) {
    if (!(event.target instanceof HTMLInputElement)) return;

    const { [event.target.value]: plantCodeInList } = this.plantCodesByIdSignal();
    const { [event.target.value]: plantCodeSelected } = this.selectedPlantCodesControl.value;

    if (plantCodeInList && !plantCodeSelected) {
      this.selectedPlantCodesControl.setValue({
        ...this.selectedPlantCodesControl.value,
        [plantCodeInList.plantCode]: plantCodeInList,
      });
      this.plantCodeSearchControl.setValue('');
    }
  }

  onRemoveScope(scopeCodeToRemove: string) {
    const { [scopeCodeToRemove]: removedCode, ...rest } = this.selectedPlantCodesControl.value;
    this.selectedPlantCodesControl.setValue(rest);
  }
}
