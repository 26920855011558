import { EfaScope } from '../../../services/efa-scope/model/efa-scope';
import { Responsible } from '../../../services/responsible/model/responsible.model';
import { UniqueId } from '../../../../tests/uniqueId';

export interface ItemSupply {
  itemSupplyUuid: string;
  itemCode: string;
  indicatorCode: string;
  indicatorValue: number;
  scope: EfaScope;
  itemSupplyStatus: string;
  updateBy: string;
  updateDate: Date | null;
  dataSource: string;
  processusLabel: string;
  responsible: Responsible;
  comment: string;
}

export class ItemSupplyModel {
  props: ItemSupply = {
    itemSupplyUuid: '',
    itemCode: '',
    indicatorCode: '',
    indicatorValue: NaN,
    dataSource: '',
    processusLabel: '',
    responsible: {
      firstName: '',
      lastName: '',
      uuid: '',
      email: '',
      getName() {
        return this.firstName + ' ' + this.lastName;
      },
    },
    scope: {
      scopeType: '',
      scopeCode: '',
      scopeTypeParent: '',
      scopeTypeCode: '',
      scopeLevel: 0,
    },
    itemSupplyStatus: 'KO',
    updateBy: '',
    updateDate: null,
    comment: '',
  };

  constructor(props: Partial<ItemSupply>) {
    Object.assign(this.props, props);
  }

  static builder() {
    return new ItemSupplyBuilder();
  }

  getValues() {
    return this.props;
  }
}

class ItemSupplyBuilder {
  private itemSupply: ItemSupply = {
    itemSupplyUuid: UniqueId.nextId(),
    itemCode: '',
    indicatorCode: 'EFA_TOTAL', // Cactus V1; will change on V2
    indicatorValue: 0,
    scope: {
      scopeType: '',
      scopeCode: '',
      scopeTypeParent: '',
      scopeTypeCode: '',
      scopeLevel: 0,
    },
    itemSupplyStatus: 'KO',
    updateBy: '',
    updateDate: null,
    dataSource: '',
    processusLabel: '',
    responsible: {
      firstName: '',
      lastName: '',
      uuid: '',
      email: '',
      getName() {
        return this.firstName + ' ' + this.lastName;
      },
    },
    comment: '',
  };

  build() {
    if (this.itemSupply) {
      return new ItemSupplyModel({ ...this.itemSupply }).getValues();
    }
    return new ItemSupplyModel({}).getValues();
  }

  withUuid(uuid: string) {
    this.itemSupply.itemSupplyUuid = uuid;
    return this;
  }

  withItemSupply(itemSupply: ItemSupply) {
    this.itemSupply = itemSupply;
    return this;
  }

  withItemCode(itemCode: string) {
    this.itemSupply.itemCode = itemCode;
    return this;
  }

  withScopeType(scopeType: string) {
    this.itemSupply.scope.scopeType = scopeType;
    return this;
  }

  withScopeCode(scopeCode: string) {
    this.itemSupply.scope.scopeCode = scopeCode;
    return this;
  }

  withStatus(itemSupplyStatus: string) {
    this.itemSupply.itemSupplyStatus = itemSupplyStatus;
    return this;
  }

  withDataSource(dataSource: string) {
    this.itemSupply.dataSource = dataSource;
    return this;
  }

  withProcessusLabel(processusLabel: string) {
    this.itemSupply.processusLabel = processusLabel;
    return this;
  }

  withIndicatorCode(indicatorCode: string) {
    this.itemSupply.indicatorCode = indicatorCode;
    return this;
  }

  withResponsible(responsible: Responsible) {
    this.itemSupply.responsible = responsible;
    return this;
  }

  withIndicatorValue(value: number) {
    this.itemSupply.indicatorValue = value;
    return this;
  }

  withUpdateBy(updateBy: string) {
    this.itemSupply.updateBy = updateBy;
    return this;
  }

  withUpdateDate(updateDate: string) {
    this.itemSupply.updateDate = new Date(updateDate);
    return this;
  }

  withComment(comment: string) {
    this.itemSupply.comment = comment;
    return this;
  }
}
