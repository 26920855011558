import { Component, input } from '@angular/core';
import { NgClass } from '@angular/common';

type StatusColorLabel = 'Created' | 'In progress' | 'Published' | 'Rework';

@Component({
  selector: 'app-subset-status-text',
  standalone: true,
  imports: [NgClass],
  templateUrl: './subset-status-text.component.html',
  styleUrl: './subset-status-text.component.css',
})
export class SubsetStatusTextComponent {
  status = input.required<StatusColorLabel | string>();
  statusBgColor = {
    Created: 'bg-slate-300',
    'In progress': 'bg-orange-200',
    Published: 'bg-published-color',
    Rework: 'bg-zinc-200',
  };
  statusTextColor = {
    Created: 'text-blue-600',
    'In progress': 'text-orange-400',
    Published: 'text-emerald-600',
    Rework: 'text-slate-500',
  };

  statusClass(status: StatusColorLabel | string) {
    return {
      [`${this.statusBgColor[status as StatusColorLabel]}`]: true,
      [`${this.statusTextColor[status as StatusColorLabel]}`]: true,
    };
  }
}
