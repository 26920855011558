import { inject, Injectable } from '@angular/core';
import { catchError, map, Observable, Subject, Subscriber, takeUntil, timer } from 'rxjs';
import { HttpParams } from '@angular/common/http';

import { CactusService } from '../cactus.service';
import { DateProvider } from '../year-of-consumption/model/date-provider';
import { environment } from '../../../environments/environment';
import { IRequestCalculationStatus } from './calculation/status/requestCalculationStatus.interface';
import { Responsible } from '../responsible/model/responsible.model';
import { StatusLabel, Subset, SubsetDatabase, SubsetModel } from './model/subset.model';
import { ScopeModel, SubsetScope } from '../scopes/model/scope.model';
import { subsetListSeed } from '../../../tests/seed/subsetList.seed';
import { UniqueId } from '../../../tests/uniqueId';

interface ICalculationReport {
  url: string;
}

@Injectable({
  providedIn: 'root',
})
export class SubsetService extends CactusService<Subset> {
  private dateProvider: DateProvider = inject(DateProvider);

  retrieveAll(responsibleList: Responsible[]): Observable<Subset[]> {
    if (environment.name === 'test') {
      return new Observable<Subset[]>((observer) => {
        if (!this.useInMemory) {
          this.inMemoryData = subsetListSeed;
        }
        observer.next(this.inMemoryData);
        observer.complete();
      });
    }
    return this.retrieveAllSource(responsibleList);
  }

  private retrieveAllSource(responsibleList: Responsible[]) {
    return this.http.get<{ data: SubsetDatabase[] }>(`${environment.api}retrieveSubsetList`).pipe(
      map(({ data }) => {
        this.inMemoryData = [];
        for (const subsetQueried of data) {
          const row = SubsetModel.builder()
            .withName(subsetQueried.subset_name)
            .withUuid(subsetQueried.subset_uuid)
            .withUserSubsetId(subsetQueried.user_subset_id)
            .withStatus(subsetQueried.subset_status as StatusLabel)
            .withCreationDate(this.dateProvider.parseStrict(subsetQueried.creation_date))
            .withYear(subsetQueried.fiscal_year)
            .withUpdateDate(this.dateProvider.parse(subsetQueried.update_date))
            .withComment(subsetQueried.comment)
            .withStatusDate(this.dateProvider.parse(subsetQueried.status_date));

          const responsibleInfo = responsibleList.find((r) => r.uuid === subsetQueried.responsible);
          if (responsibleInfo) {
            row.withResponsible({
              firstName: responsibleInfo.firstName,
              lastName: responsibleInfo.lastName,
              uuid: responsibleInfo.uuid,
              email: responsibleInfo.email,

              getName() {
                return this.firstName + ' ' + this.lastName;
              },
            });
          }

          if (subsetQueried.last_calculation_by) {
            const responsible = responsibleList.find((r) => r.uuid === subsetQueried.last_calculation_by);
            if (responsible) {
              row.withLastCalculationBy(responsible);
            }
            row
              .withLastCalculationDate(this.dateProvider.parse(subsetQueried.last_calculation_date))
              .withLastCalculationIteration(subsetQueried.last_calculation_iteration);
          }

          if (subsetQueried.publication_by) {
            const responsible = responsibleList.find((r) => r.uuid === subsetQueried.publication_by);
            if (responsible) {
              row.withPublicationBy(responsible);
            }
            row
              .withPublicationDate(this.dateProvider.parse(subsetQueried.publication_date))
              .withPublicationIteration(subsetQueried.published_calculation_iteration);
          }

          if (subsetQueried.update_by) {
            const responsible = responsibleList.find((r) => r.uuid === subsetQueried.update_by);
            if (responsible) {
              row.withUpdateBy(responsible);
            }
          }

          const scopes = subsetQueried.scopes_info ? JSON.parse(subsetQueried.scopes_info) : [];
          for (const scope of scopes) {
            row.withScope(ScopeModel.builder().withName(scope.plant_name).withPlantCode(scope.plant_code).build());
          }

          this.inMemoryData.push(row.build());
        }
        return this.inMemoryData;
      }),
      catchError(this.handleHttpError),
    );
  }

  refreshAll(responsibleList: Responsible[]) {
    return new Observable<Subset[]>((observer) => {
      this.retrieveAllSource(responsibleList).subscribe((subsetList: Subset[]) => {
        observer.next(subsetList);
        observer.complete();
      });
    });
  }

  create(
    toCreate: { year: string; responsible: Responsible; name: string; comment: string; scopes: SubsetScope[] } & {
      launchedBy?: Responsible;
    },
  ) {
    const subsetBuilder = SubsetModel.builder()
      .withName(toCreate.name)
      .withUuid(UniqueId.nextId())
      .withComment(toCreate.comment)
      .withCreationDate(DateProvider.now())
      .withYear(toCreate.year)
      .withResponsible(toCreate.responsible);

    if (toCreate.launchedBy) {
      subsetBuilder.withUpdateBy(toCreate.launchedBy);
    }

    for (const scope of toCreate.scopes) {
      subsetBuilder.withScope(scope);
    }

    return this.submitCreate(subsetBuilder.build(), toCreate.launchedBy);
  }

  submitCreate(subset: Subset, launchedBy?: Responsible) {
    if (['test'].includes(environment.name)) {
      return new Observable((observer) => {
        this.addSubsetToList(subset);
        observer.next();
        observer.complete();
      });
    }
    return new Observable((observer) => {
      this.http.post(`${environment.api}createSubset`, { ...subset, launchedBy }).subscribe(() => {
        this.addSubsetToList(subset);
        observer.next();
        observer.complete();
      });
    });
  }

  private addSubsetToList(subset: Subset) {
    this.inMemoryData.push(subset);
  }

  edit(
    uuid: string,
    updatedSubset: {
      name: string;
      responsible: Responsible;
      comment: string;
      scopes: SubsetScope[];
      year: string;
    } & { launchedBy: Responsible | undefined },
  ) {
    const subset = this.inMemoryData.find((subset) => subset.uuid === uuid) as Subset;
    subset.name = updatedSubset.name;
    subset.responsible = updatedSubset.responsible;
    subset.comment = updatedSubset.comment;
    subset.scopes = updatedSubset.scopes;
    subset.fiscalYear = updatedSubset.year;
    subset.updateDate = DateProvider.now();
    if (updatedSubset.launchedBy) {
      subset.updateBy = updatedSubset.launchedBy;
    }

    return this.submitEdit(subset, updatedSubset.launchedBy);
  }

  submitEdit(subset: Subset, launchedBy?: Responsible) {
    if (['test'].includes(environment.name)) {
      return new Observable((observer) => {
        this.editSubsetToList(subset);
        observer.next();
        observer.complete();
      });
    }
    return new Observable((observer) => {
      this.http.put(`${environment.api}editSubset`, { ...subset, launchedBy }).subscribe(() => {
        this.editSubsetToList(subset);
        observer.next();
        observer.complete();
      });
    });
  }

  private editSubsetToList(subset: Subset) {
    const index = this.inMemoryData.findIndex(({ uuid }) => uuid === subset.uuid);
    if (index !== -1) {
      this.inMemoryData.splice(index, 1, subset);
    }
  }

  launchCalculation(subset: Subset, launchedBy: string) {
    if (['test'].includes(environment.name)) {
      return new Observable((observer) => {
        observer.next(subset);
        observer.complete();
      });
    }
    return new Observable((observer) => {
      this.http
        .post(`${environment.api}launchCalculationSubset`, { subsetUuid: subset.uuid, launchedBy })
        .subscribe(() => {
          // this.editSubsetToList(subset);
          observer.next(subset);
          observer.complete();
        });
    });
  }

  retrieveCalculationStatus(subset: Subset): Observable<IRequestCalculationStatus> {
    if (['test'].includes(environment.name)) {
      return new Observable((observer) => {
        observer.error('Work in progress');
      });
    }
    const params = new HttpParams().set('subsetUuid', subset.uuid);
    return this.http.get<IRequestCalculationStatus>(`${environment.api}retrieveCalculationStatus`, { params });
  }

  pollingUntilCalculationFinished({
    timeout,
    request,
    callback,
  }: {
    timeout: Subject<unknown>;
    request: Observable<IRequestCalculationStatus>;
    callback?: (requestResult: IRequestCalculationStatus) => void;
  }): Observable<Subset> {
    const stop: Subject<void> = new Subject<void>();
    return new Observable<Subset>((observer: Subscriber<Subset>) => {
      timer(environment.pollingInterval, environment.pollingInterval)
        .pipe(
          map(() => request),
          takeUntil(timeout),
          takeUntil(stop),
        )
        .subscribe((requestResult: Observable<IRequestCalculationStatus>) => {
          requestResult
            .pipe(
              catchError((err, caught) => {
                console.error(err);
                stop.next(void 0);
                stop.complete();
                return caught;
              }),
            )
            .subscribe(({ pending, data }: IRequestCalculationStatus) => {
              if (callback) {
                callback({ pending, data });
              }
              if (!pending) {
                stop.next(void 0);
                stop.complete();
                observer.next(data.subset);
                observer.complete();
              }
            });
        });
    });
  }

  updateCalculationSubset(subset: Subset) {
    if (['test'].includes(environment.name)) {
      return new Observable((observer) => {
        observer.next(subset);
        observer.complete();
      });
    }
    return new Observable((observer) => {
      this.http.put(`${environment.api}updateCalculationSubset`, { subsetUuid: subset.uuid }).subscribe(() => {
        observer.next(subset);
        observer.complete();
      });
    });
  }

  deleteSubset(subsetUuid: string, launchedBy: string) {
    if (['test'].includes(environment.name)) {
      return new Observable((observer) => {
        this.deleteSubsetToList(subsetUuid);
        observer.next(void 0);
        observer.complete();
      });
    }
    return new Observable((observer) => {
      this.http.delete(`${environment.api}deleteSubset`, { body: { subsetUuid, launchedBy } }).subscribe(() => {
        this.deleteSubsetToList(subsetUuid);
        observer.next(void 0);
        observer.complete();
      });
    });
  }

  private deleteSubsetToList(subsetUuid: string) {
    const index = this.inMemoryData.findIndex(({ uuid }) => uuid === subsetUuid);
    if (index > -1) {
      this.inMemoryData.splice(index, 1);
    }
  }

  retrieveCalculationReport(subset: Subset): Observable<string> {
    return new Observable((observer) => {
      const params = new HttpParams().set('subsetUuid', subset.uuid);
      this.http
        .get<ICalculationReport>(`${environment.api}retrieveCombinedCalculationReportPresignedUrl`, { params })
        .subscribe((data) => {
          observer.next(data.url);
          observer.complete();
        });
    });
  }
}
