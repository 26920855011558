import { Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgClass } from '@angular/common';
import { Router } from '@angular/router';

import { AuthService } from '../../services/auth/auth.service';
import { DateProvider } from '../../services/year-of-consumption/model/date-provider';
import { SubsetCreateOrEditComponent } from '../../utils/components/subset-create-or-edit/subset-create-or-edit.component';
import { SubsetService } from '../../services/subset/subset.service';
import { Subset } from '../../services/subset/model/subset.model';
import { Responsible } from '../../services/responsible/model/responsible.model';
import { YearOfConsumptionService } from '../../services/year-of-consumption/year-of-consumption.service';

@Component({
  selector: 'app-subset-create',
  standalone: true,
  imports: [FormsModule, NgClass, SubsetCreateOrEditComponent],
  providers: [DateProvider, YearOfConsumptionService],
  templateUrl: './subset-create.component.html',
  styles: '',
})
export class SubsetCreateComponent {
  /**
   * Dependencies Injection
   */
  private authService: AuthService = inject(AuthService);
  private subsetService: SubsetService = inject(SubsetService);
  private router: Router = inject(Router);

  submit(subset: Partial<Subset> & { launchedBy: Responsible | undefined }) {
    this.subsetService
      .create({
        name: subset.name || '',
        responsible: subset.responsible as Responsible,
        comment: subset.comment || '',
        scopes: subset.scopes || [],
        year: subset.fiscalYear || '',
        launchedBy: subset.launchedBy || undefined,
      })
      .subscribe(() => this.gotoPrevious());
  }

  gotoPrevious() {
    // if new route, return list or view subset
    // if popup, close it
    return this.router.navigate([`/list`]);
  }

  canCreateSubset() {
    return this.authService.getProfile() === 'advanced';
  }
}
