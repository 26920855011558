import { Component, computed, inject, input, InputSignal, OnInit, OutputRef, Signal } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { outputFromObservable, toSignal } from '@angular/core/rxjs-interop';
import { map } from 'rxjs';

import { Responsible } from '../../../services/responsible/model/responsible.model';
import { ResponsibleService } from '../../../services/responsible/responsible.service';

@Component({
  selector: 'app-responsible-list',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule],
  templateUrl: './responsible-list.component.html',
  styleUrl: './responsible-list.component.css',
})
export class ResponsibleListComponent implements OnInit {
  /**
   * Dependencies Injection
   */
  private responsibleService: ResponsibleService = inject(ResponsibleService);

  /**
   * Core: might be required to retrieveAll before this component is open to work
   */
  public responsibleList: Signal<Responsible[]> = toSignal(this.responsibleService.retrieveAllObservable(), {
    initialValue: [],
  });

  /**
   * Input Output
   */
  public responsibleSelected = new FormControl();

  public inputDataTestidPrefix: InputSignal<string> = input('');
  public inputResponsibleSelected: InputSignal<Responsible | undefined> = input();
  public outputResponsibleSelected: OutputRef<Responsible | undefined> = outputFromObservable(
    this.responsibleSelected.valueChanges.pipe(
      map((uuid: string) => this.responsibleList().find((r: Responsible) => r.uuid === uuid)),
    ),
  );
  public dataTestidPrefix = computed(() => {
    if (this.inputDataTestidPrefix()) {
      return this.inputDataTestidPrefix() + '-';
    }
    return '';
  });

  ngOnInit(): void {
    if (this.inputResponsibleSelected()) {
      this.responsibleSelected.setValue(this.inputResponsibleSelected()?.uuid);
    }
  }
}
