import { FetchUserAttributesOutput } from 'aws-amplify/auth';
import { AuthSession, AuthSessionService } from './session/auth-session.service';

export class AuthCactus {
  private session: AuthSession;
  constructor(private fetchAuthSessionService: AuthSessionService) {}

  get attributes(): FetchUserAttributesOutput {
    return this._attributes;
  }

  set attributes(value: FetchUserAttributesOutput) {
    this._attributes = value;
  }
  private _attributes: FetchUserAttributesOutput;

  get bearerToken(): string {
    const idToken = this.session.tokens?.idToken?.toString();
    if (!idToken) {
      // TODO: should never reach this
      throw new Error('User possibly not logged in');
    }
    return idToken;
  }

  async isLoggedIn(): Promise<boolean> {
    const queryString = this.fetchAuthSessionService.getSSOQueryString();

    this.session = await this.fetchAuthSessionService.fetch(); // TODO: forceRefresh here?

    // retour du SSO
    if (queryString.has('code') && this.session?.tokens?.idToken?.payload) {
      return true;
    } else if (this.session?.tokens?.idToken?.payload) {
      return true;
    }
    return false;
  }
}
