import { Component, computed, inject, Signal } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { RouterLink } from '@angular/router';
import { toSignal } from '@angular/core/rxjs-interop';

import { AuthService, UserProfile } from '../../services/auth/auth.service';
import { ResponsibleService } from '../../services/responsible/responsible.service';
import { ScopesService } from '../../services/scopes/scopes.service';

@Component({
  selector: 'app-homepage',
  standalone: true,
  imports: [RouterLink, NgOptimizedImage],
  templateUrl: './homepage.component.html',
  styles: '',
})
export class HomepageComponent {
  /**
   * Preload services for performances
   * @private
   */
  private scopeService: ScopesService = inject(ScopesService);
  private responsibleService: ResponsibleService = inject(ResponsibleService);

  /**
   * prepare cache for other pages performances
   */
  private scopesList = toSignal(this.scopeService.retrieveAllObservable(), { initialValue: [] });
  private responsibleList = toSignal(this.responsibleService.retrieveAllObservable(), { initialValue: [] });

  private readonly authService = inject(AuthService);
  profile: Signal<UserProfile> = computed(() => this.authService.getProfile());
  isLoginChecked: Signal<boolean> = computed(() => this.authService.isLoginChecked());
}
