<div class="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
  <div class="bg-white rounded-lg shadow-lg p-6 w-1/3">
    <h2 class="text-xl font-semibold mb-4">{{ title() }}</h2>
    <p class="mb-6">{{ content() }}</p>

    <div class="flex justify-end space-x-4">
      @if (cancelButton()) {
        <button
          class="bg-gray-300 text-gray-800 px-4 py-2 rounded"
          (click)="onCancel()"
          [disabled]="disableButtons()"
          [ngClass]="disableButtons() ? '' : 'hover:bg-gray-400'"
        >
          Cancel
        </button>
      }
      @if (confirmButton()) {
        <button
          class="bg-[#28285f] text-white px-4 py-2 rounded"
          (click)="onConfirm()"
          [disabled]="disableButtons()"
          [ngClass]="disableButtons() ? '' : 'hover:bg-[#28285f]'"
        >
          Confirm
        </button>
      }
      @if (closeButton()) {
        <button
          class="bg-[#28285f] text-white px-4 py-2 rounded"
          (click)="onClose()"
          [disabled]="disableButtons()"
          [ngClass]="disableButtons() ? '' : 'hover:bg-[#28285f]'"
        >
          Close
        </button>
      }
    </div>
  </div>
</div>
