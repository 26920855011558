<div
  class="fixed top-0 transition-all duration-300 ease-in-out overflow-y-auto w-1/2 h-full bg-white z-50 shadow-slate-200 shadow-xl pt-8 px-6 pb-40"
  [ngClass]="open() ? 'left-0' : '-left-1/2'"
>
  <div class="flex flex-nowrap flex-col justify-start">
    <div class="inline-flex justify-between w-full border-b-[1px] border-slate-200 pb-4">
      <h4 class="text-2xl font-semibold">Filters</h4>
      <button
        class="bg-slate-600 hover:bg-slate-500 text-white text-3xl w-10 h-10 rounded-xl"
        (click)="invertFilterDrawerOpenStatus()()"
      >
        &#9932;
      </button>
    </div>
    <form [formGroup]="filterForm()" class="flex flex-col pt-3">
      <div class="grid grid-cols-2 gap-8">
        <div>
          <span class="text-lg font-medium">Erp Codes</span>
          <div class="grid grid-cols-[auto_1fr] max-h-52 overflow-y-auto mt-4" formGroupName="erpCodes">
            @for (erp of erpCodeControls; track i; let i = $index) {
              <input
                type="checkbox"
                [id]="erp[0] + '_checkbox'"
                class="w-[20px] h-[20px] accent-emerald-600 mr-4"
                [formControlName]="erp[0]"
                [value]="erp[1].value"
              />
              <label [for]="erp[0] + '_checkbox'" class="text-left">{{ getErpLabel(erp[0]) }}</label>
            }
          </div>
        </div>
        <div>
          <span class="text-lg font-medium">Item Type</span>
          <div class="grid grid-cols-[auto_1fr] max-h-52 overflow-y-auto mt-4" formGroupName="itemTypes">
            @for (itemType of itemTypesControls; track i; let i = $index) {
              <input
                type="checkbox"
                [id]="itemType[0] + '_checkbox'"
                class="w-[20px] h-[20px] accent-emerald-600 mr-4"
                [formControlName]="itemType[0]"
                [value]="itemType[1].value"
              />
              <label [for]="itemType[0] + '_checkbox'" class="text-left">{{ itemType[0] }}</label>
            }
          </div>
        </div>
        <div>
          <span class="text-lg font-medium">Responsible</span>
          <div class="grid grid-cols-[auto_1fr] max-h-52 overflow-y-auto mt-4" formGroupName="responsibles">
            @for (responsible of responsiblesControls; track i; let i = $index) {
              <input
                type="checkbox"
                [id]="responsible[0] + '_checkbox'"
                class="w-[20px] h-[20px] accent-emerald-600 mr-4"
                [formControlName]="responsible[0]"
                [value]="responsible[1].value"
              />
              <label [for]="responsible[0] + '_checkbox'" class="text-left">{{
                getResponsibleLabel(responsible[0])
              }}</label>
            }
          </div>
        </div>
        <div>
          <span class="text-lg font-medium">Unit</span>
          <div class="grid grid-cols-[auto_1fr] max-h-52 overflow-y-auto mt-4" formGroupName="units">
            @for (unit of unitsControls; track i; let i = $index) {
              <input
                type="checkbox"
                [id]="unit[0] + '_checkbox'"
                class="w-[20px] h-[20px] accent-emerald-600 mr-4"
                [formControlName]="unit[0]"
                [value]="unit[1].value"
              />
              <label [for]="unit[0] + '_checkbox'" class="text-left">{{ getUnitLabel(unit[0]) }}</label>
            }
          </div>
        </div>
      </div>
      <div class="mt-8">
        <label for="subset-scope"
          >Subset scope
          <input
            id="subset-scope"
            type="text"
            list="id-subset-scope"
            formControlName="plantCodesSearch"
            (input)="onScopeToAdd($event)"
          />
          <datalist id="id-subset-scope">
            <option [value]="''" disabled selected>Select a plant</option>
            @for (pc of plantCodes; track i; let i = $index) {
              <option class="text-slate-800" [value]="pc.plantCode">{{ pc.plantCode }} {{ pc.name }}</option>
            }
          </datalist>
          <div
            class="grid grid-cols-[auto_1fr_auto] [&>div]:flex [&>div]:justify-left [&>div]:items-center [&>div]:p-3 [&>div]:text-slate-800 fancy-grid text-sm"
          >
            @for (pc of selectedPlantCodes; track i; let i = $index) {
              <div>{{ pc.plantCode }}</div>
              <div>{{ pc.name }}</div>
              <div class="flex justify-center items-center">
                <button
                  (click)="onRemoveScope(pc.plantCode)"
                  class="w-6 h-6 text-white bg-slate-600 hover:bg-slate-500 rounded-[20px] flex items-center justify-center"
                >
                  &#9932;
                </button>
              </div>
            }
          </div>
        </label>
      </div>
    </form>
  </div>
</div>
