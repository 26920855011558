import { ScopeModel, SubsetScope } from '../../app/services/scopes/model/scope.model';

export const scopeListSeed: SubsetScope[] = [
  ScopeModel.builder().withName('VLISSINGEN').withPlantCode('N16VL').build(),
  ScopeModel.builder().withName('ZWICKAU').withPlantCode('N06ZK').build(),
  ScopeModel.builder().withName('ARKEMA SA - PLATFORM').withPlantCode('N01RM').build(),
  ScopeModel.builder().withName('DEHON SAINT PRIEST').withPlantCode('N01MT').build(),
  ScopeModel.builder().withName('BRUMMEN').withPlantCode('N16BM').build(),
  ScopeModel.builder().withName('MARSEILLE').withPlantCode('N0101').build(),
  ScopeModel.builder().withName('ANVERS').withPlantCode('N01AW').build(),
  ScopeModel.builder().withName('USINE DE HONFLEUR').withPlantCode('N01FL').build(),
  ScopeModel.builder().withName('VILLERS SAINT PAUL').withPlantCode('N01VS').build(),
  ScopeModel.builder().withName('LACQ').withPlantCode('N01LC').build(),
  ScopeModel.builder().withName('LA CHAMBRE').withPlantCode('N01KC').build(),
];
