import {
  Component,
  effect,
  inject,
  input,
  InputSignal,
  output,
  OutputEmitterRef,
  signal,
  Signal,
  untracked,
  WritableSignal,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgClass } from '@angular/common';
import { toSignal } from '@angular/core/rxjs-interop';

import { AuthService } from '../../../services/auth/auth.service';
import { DateProvider } from '../../../services/year-of-consumption/model/date-provider';
import { Responsible } from '../../../services/responsible/model/responsible.model';
import { ResponsibleService } from '../../../services/responsible/responsible.service';
import { ScopesService } from '../../../services/scopes/scopes.service';
import { SubsetScope } from '../../../services/scopes/model/scope.model';
import { Subset } from '../../../services/subset/model/subset.model';

@Component({
  selector: 'app-subset-create-or-edit',
  standalone: true,
  imports: [FormsModule, NgClass, ReactiveFormsModule],
  providers: [DateProvider],
  templateUrl: './subset-create-or-edit.component.html',
  styleUrl: './subset-create-or-edit.component.css',
})
export class SubsetCreateOrEditComponent {
  /**
   * Dependencies Injection
   */
  private authService: AuthService = inject(AuthService);
  private responsibleService: ResponsibleService = inject(ResponsibleService);
  private scopeService: ScopesService = inject(ScopesService);

  private currentYear = '2023';

  /**
   * Input Output
   */
  editMode: InputSignal<boolean> = input(false);
  subset: InputSignal<Subset | undefined> = input();
  outputSubset: OutputEmitterRef<Partial<Subset> & { launchedBy: Responsible | undefined }> = output<
    Partial<Subset> & { launchedBy: Responsible | undefined }
  >();
  outputClose = output<void>();

  public responsibleList: Signal<Responsible[]> = toSignal(this.responsibleService.retrieveAllObservable(), {
    initialValue: [],
  });

  private plantCodesByIdSignal = this.scopeService.retrievePlantCodesByIdSignal();
  public scopeSelected: WritableSignal<Record<string, SubsetScope>> = signal({});
  public displayError: WritableSignal<boolean> = signal(false);
  public sending: WritableSignal<boolean> = signal(false);
  public yearList: WritableSignal<string[]> = signal([this.currentYear]);

  /**
   * Forms
   */
  subsetName = new FormControl('', [Validators.maxLength(255), Validators.required]);
  subsetComment = new FormControl('', Validators.maxLength(255));
  responsibleSelected = new FormControl('');
  yearSelected = new FormControl('', Validators.required);
  scopeToAdd = new FormControl('', [Validators.minLength(1)]);

  constructor() {
    effect(() => {
      if (this.editMode() && this.subset() && Object.values(this.plantCodesByIdSignal())?.length) {
        untracked(() => {
          this.fillFormControl();
        });
      } else if (!this.editMode() && this.responsibleList()?.length) {
        untracked(() => {
          this.preselectedResponsible();
          this.preselectedYear();
        });
      }
    });
  }

  fillFormControl() {
    const { name, comment, fiscalYear, scopes, responsible } = this.subset() as Subset;
    this.subsetName.setValue(name);
    this.subsetComment.setValue(comment);
    this.responsibleSelected.setValue(`${responsible.firstName} ${responsible.lastName}`);
    this.yearSelected.setValue(fiscalYear);
    this.scopeSelected.set((() => scopes.reduce((prev, curr) => ({ ...prev, [curr.plantCode]: curr }), {}))());
  }

  preselectedResponsible() {
    const { firstName, lastName } = this.authService.getName();
    const myself = this.responsibleList().find(
      (responsible) =>
        responsible.firstName.toLowerCase() === firstName.toLowerCase() &&
        responsible.lastName.toLowerCase() === lastName.toLowerCase(),
    );

    if (myself) {
      this.responsibleSelected.setValue(`${firstName} ${lastName}`);
    }
  }

  preselectedYear() {
    this.yearSelected.setValue(this.currentYear);
  }

  public get plantCodes() {
    return Object.values(this.plantCodesByIdSignal());
  }

  public get selectedPlantCodes() {
    return Object.values(this.scopeSelected());
  }

  public onScopeToAdd(event: Event) {
    if (!(event.target instanceof HTMLInputElement)) return;

    const { [event.target.value]: plantCodeInList } = this.plantCodesByIdSignal();
    const { [event.target.value]: plantCodeSelected } = this.scopeSelected();

    if (plantCodeInList && !plantCodeSelected) {
      this.scopeSelected.set({
        ...this.scopeSelected(),
        [plantCodeInList.plantCode]: plantCodeInList,
      });
      this.scopeToAdd.setValue('');
    }
  }

  onRemoveScope(scopeCodeToRemove: string) {
    const { [scopeCodeToRemove]: removedCode, ...rest } = this.scopeSelected();
    this.scopeSelected.set(rest);
  }

  cancelCreate() {
    this.outputClose.emit();
  }

  applySubset() {
    const responsible = this.responsibleList().find(
      (r) =>
        `${r.firstName.toLowerCase()} ${r.lastName.toLowerCase()}` === this.responsibleSelected.value?.toLowerCase(),
    );
    const currentUserUuid = this.authService.getCurrentUserUuid(this.responsibleList());
    const launchedBy: Responsible | undefined = this.responsibleList().find((r) => r.uuid === currentUserUuid);

    const selectScopes = Object.values(this.scopeSelected());

    const hasError = [
      this.subsetName.invalid,
      this.subsetComment.invalid,
      this.yearSelected.invalid,
      !this.responsibleSelected.value?.length,
      !selectScopes.length,
    ].includes(true);

    if (hasError) {
      this.displayError.set(true);
      return;
    }
    this.displayError.set(false);
    this.sending.set(true);
    this.subsetName.disable();
    this.subsetComment.disable();
    this.yearSelected.disable();
    this.responsibleSelected.disable();

    this.outputSubset.emit({
      name: this.subsetName.value as string,
      responsible: responsible,
      comment: this.subsetComment.value || '',
      scopes: selectScopes,
      fiscalYear: this.yearSelected.value || '',
      launchedBy: launchedBy || undefined,
    });
  }

  // FIXME: refactor to use arktype
  hasError(field: string) {
    if (!this.displayError()) {
      return false;
    }

    switch (field) {
      case 'name':
        return this.subsetName.invalid;
      case 'comment':
        return this.subsetComment.invalid;
      case 'responsible':
        return this.responsibleSelected.value?.length === 0;
      case 'scope':
        return Object.values(this.scopeSelected()).length === 0;
      default:
        return false;
    }
  }
}
