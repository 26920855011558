import { EfaItemType } from '../../app/services/efa-item-type/efa-item-type.service';

export const sourceItemTypeList: EfaItemType[] = [
  'Finished',
  'Intermediate',
  'Other',
  'Packaging',
  'Plant emissions',
  'Plant wastes',
  'Raw',
  'Utility',
];
