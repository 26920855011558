<div class="w-[1040px] h-[768px] p-[60px] bg-white rounded-lg flex-col justify-start items-end gap-[50px] inline-flex">
  <div class="flex-col justify-start items-start gap-[50px] flex">
    <div class="justify-start items-center gap-2.5 inline-flex">
      <div class="text-blue-950 text-[40px] font-semibold font-['Work Sans']">
        @if (editMode()) {
          Subset details - Edit mode
        } @else {
          Subset details - Creation mode
        }
      </div>
      <div class="w-[34px] h-[34px] relative"></div>
    </div>
    <div class="flex-col justify-start items-start gap-10 flex">
      <div class="h-24 w-full flex-col justify-start items-start gap-2.5 flex">
        <div class="self-stretch text-black text-[22px] font-semibold font-['Work Sans']">Subset name</div>
        <div
          class="pl-5 pt-[19px] pb-[18px] w-full bg-white rounded-lg border-2 border-zinc-400 justify-start items-center inline-flex"
          [ngClass]="hasError('name') ? 'border-red-500' : ''"
        >
          <input
            type="text"
            placeholder="subset name"
            class="w-full text-black text-lg font-normal font-['Work Sans']"
            [formControl]="subsetName"
          />
        </div>
      </div>
      <div class="justify-start items-start gap-[30px] inline-flex">
        <div class="w-[445px] flex-col justify-start items-start gap-2.5 inline-flex">
          <div class="self-stretch text-black text-[22px] font-semibold font-['Work Sans']">Responsible</div>
          <div class="self-stretch h-[60px] w-full bg-white flex-col justify-start items-start flex">
            <div
              class="h-[60px] px-5 py-[18px] w-full bg-white rounded-lg border-2 border-zinc-400 justify-center items-start inline-flex"
              [ngClass]="hasError('responsible') ? 'border-red-500' : ''"
            >
              <select class="w-full" [formControl]="responsibleSelected">
                <option value="" disabled selected>Select a responsible</option>
                @for (responsible of responsibleList(); track i; let i = $index) {
                  <option class="text-black text-lg font-normal font-['Work Sans']">
                    {{ responsible.firstName }} {{ responsible.lastName }}
                  </option>
                }
              </select>
              <div class="w-6 h-6 relative flex-col justify-start items-start flex"></div>
            </div>
          </div>
        </div>
        <div class="w-[445px] flex-col justify-start items-start gap-2.5 inline-flex">
          <div class="self-stretch text-black text-[22px] font-semibold font-['Work Sans']">Year</div>
          <div class="self-stretch h-[60px] bg-white flex-col justify-start items-start flex">
            <div
              class="h-[60px] px-5 py-[18px] bg-white rounded-lg border-2 border-zinc-400 justify-center items-start gap-[333px] inline-flex"
            >
              @if (editMode()) {
                <select class="w-full" [formControl]="yearSelected">
                  <option value="" disabled selected>Select a year</option>
                  @for (year of yearList(); track i; let i = $index) {
                    <option class="text-black text-lg font-normal font-['Work Sans']">
                      {{ year }}
                    </option>
                  }
                </select>
              } @else {
                <div class="text-black text-lg font-normal font-['Work Sans']">{{ yearSelected.value }}</div>
                <div class="w-6 h-6 relative flex-col justify-start items-start flex"></div>
              }
            </div>
          </div>
        </div>
      </div>
      <div class="h-24 w-full flex-col justify-start items-start gap-2.5 flex">
        <div class="self-stretch text-black text-[22px] font-semibold font-['Work Sans']">Comment</div>
        <div
          class="pl-5 pt-[19px] pb-[18px] w-full bg-white rounded-lg border-2 border-zinc-400 justify-start items-center inline-flex"
          [ngClass]="hasError('comment') ? 'border-red-500' : ''"
        >
          <input
            type="text"
            placeholder="comment"
            class="w-full text-black text-lg font-normal font-['Work Sans']"
            [formControl]="subsetComment"
          />
        </div>
      </div>
      <div class="h-[161px] flex-col justify-start items-start gap-2.5 flex">
        <div class="self-stretch text-black text-[22px] font-semibold font-['Work Sans']">Subset scope</div>
        <div
          class="w-full overflow-y-auto justify-start items-start gap-5 inline-flex flex-wrap"
          [ngClass]="
            scopeSelected().length < 1 ? 'min-h-[1px]' : scopeSelected().length < 4 ? 'min-h-[80px]' : 'min-h-[160px]'
          "
        >
          @for (scope of scopeSelected(); track i; let i = $index) {
            @if (scope && scope.plantCode) {
              <div class="px-5 py-3.5 bg-blue-950 rounded-[100px] justify-center items-center gap-2.5 flex">
                <div class="px-2.5 py-1 bg-slate-500 rounded-[20px] justify-center items-center gap-2.5 flex">
                  <div class="text-white text-xs font-normal font-['Work Sans']">{{ scope.plantCode }}</div>
                </div>
                <div class="text-white text-xs font-normal font-['Work Sans']">{{ scope.name }}</div>
                <button
                  class="w-5 h-5 relative bg-orange-600 rounded-[20px] flex justify-center items-center"
                  (click)="removeScope(scope.plantCode)"
                >
                  <svg width="10" height="10" viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
                    <line x1="1" y1="1" x2="9" y2="9" stroke="white" stroke-width="2" />
                    <line x1="1" y1="9" x2="9" y2="1" stroke="white" stroke-width="2" />
                  </svg>
                </button>
              </div>
            }
          }
        </div>
        <div class="self-stretch h-[60px] bg-white rounded-lg flex-col justify-start items-start flex">
          <div
            class="h-[60px] px-5 py-[18px] bg-white rounded-lg border-2 border-zinc-400 justify-center items-start gap-[719px] inline-flex"
            [ngClass]="hasError('scope') ? 'border-red-500' : ''"
          >
            <select [(ngModel)]="scopeToAdd" (ngModelChange)="onScopeToAdd()" [disabled]="sending()">
              <option [value]="''" disabled selected>Select a plant</option>
              @for (scope of scopeAvailableList(); track i; let i = $index) {
                <option class="text-black font-normal font-['Work Sans']" [value]="scope.plantCode">
                  {{ scope.plantCode }} {{ scope.name }}
                </option>
              }
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="justify-start items-start gap-5 inline-flex">
    <button
      (click)="cancelCreate()"
      class="px-[30px] py-5 bg-pink-50 rounded-lg border-2 border-orange-600 justify-center items-center gap-2.5 flex"
      [disabled]="sending()"
    >
      <span class="text-orange-600 font-medium font-['Work Sans']">Cancel</span>
    </button>
    <button
      (click)="applySubset()"
      class="px-[30px] py-5 bg-blue-950 rounded-lg justify-center items-center gap-2.5 flex"
      [disabled]="sending()"
    >
      <span class="text-white font-medium font-['Work Sans']">Apply</span>
    </button>
  </div>
</div>
