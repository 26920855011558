import { FormControl, FormGroup } from '@angular/forms';
import { EfaFilterNames, EfaItemDTO } from '../../services/efa-items/efa-items.service';
import { SubsetScope } from '../../services/scopes/model/scope.model';
import { Item } from '../../services/items/items.service';

export interface FilterFormGroup {
  [EfaFilterNames.PAGE]: FormControl<number>;
  [EfaFilterNames.PAGE_SIZE]: FormControl<number>;
  [EfaFilterNames.SEARCH_STRING]: FormControl<string>;
  [EfaFilterNames.PLANT_CODES_SEARCH]: FormControl<string>;
  [EfaFilterNames.PLANT_CODES_SELECTED]: FormControl<Record<string, SubsetScope>>;
  [EfaFilterNames.ERP_CODES]: FormGroup<Record<string, FormControl<boolean>>>;
  [EfaFilterNames.ITEM_TYPE]: FormGroup<Record<string, FormControl<boolean>>>;
  [EfaFilterNames.RESPONSIBLE]: FormGroup<Record<string, FormControl<boolean>>>;
  [EfaFilterNames.UNITS]: FormGroup<Record<string, FormControl<boolean>>>;
}

export enum EfaFormNames {
  ITEM = 'item',
  ITEM_SUPPLY_UUID = 'itemSupplyUuid',
  ITEM_SUPPLY = 'itemSupply',
  INDICATORS = 'indicators',
  SCOPE_TYPE = 'scopeType',
  SCOPE_CODE = 'scopeCode',
  PROCESSUS_LABEL = 'processusLabel',
  DATA_SOURCE = 'dataSource',
  RESPONSIBLE = 'responsible',
  INDICATOR_VALUE = 'indicatorValue',
  INDICATOR_CODE = 'indicatorCode',
}

export interface ItemSupplyFields {
  [EfaFormNames.SCOPE_TYPE]: FormControl<string>;
  [EfaFormNames.SCOPE_CODE]: FormControl<string>;
  [EfaFormNames.PROCESSUS_LABEL]: FormControl<string>;
  [EfaFormNames.DATA_SOURCE]: FormControl<string>;
  [EfaFormNames.RESPONSIBLE]: FormControl<string>;
}

export interface EfaCode {
  [EfaFormNames.INDICATOR_VALUE]: FormControl<number>;
  [EfaFormNames.INDICATOR_CODE]: FormControl<string>;
}

export interface EfaFormGroup {
  [EfaFormNames.ITEM]: FormControl<Item | undefined>; // TODO: switch from snake case to camel case
  [EfaFormNames.ITEM_SUPPLY_UUID]: FormControl<string | null>;
  [EfaFormNames.ITEM_SUPPLY]: FormGroup<ItemSupplyFields>;
  [EfaFormNames.INDICATORS]: FormGroup<EfaCode>;
}

export enum EfaTableFormNames {
  CURRENT_VIEW = 'currentView',
  GROUPING = 'grouping',
  COLUMN_VISIBILITY = 'columnVisibility',
}

export interface TableFormGroup {
  [EfaTableFormNames.GROUPING]: FormControl<Array<keyof EfaItemDTO> | null>;
  [EfaTableFormNames.COLUMN_VISIBILITY]: FormGroup<Record<keyof EfaItemDTO, FormControl<boolean>>>;
}
