import * as i0 from '@angular/core';
import { Injectable, inject, ElementRef, Directive, Input, TemplateRef, ViewContainerRef, Renderer2, EventEmitter, Output, HostListener, HostBinding, ChangeDetectorRef, Component, ChangeDetectionStrategy, ContentChildren, ViewChild, ContentChild, NgModule } from '@angular/core';
import { Subject, race } from 'rxjs';
import { __decorate } from 'tslib';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { DynamicViewDirective } from '@ngneat/overview';
import { ActiveDescendantKeyManager, A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
const _c0 = ["*"];
function GroupComponent_Conditional_0_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function GroupComponent_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 0);
    i0.ɵɵtemplate(1, GroupComponent_Conditional_0_ng_container_1_Template, 1, 0, "ng-container", 2);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("dynamicView", ctx_r0.label);
  }
}
const _c1 = ["height"];
class CmdkService {
  constructor() {
    this._searchSub = new Subject();
    this.search$ = this._searchSub.asObservable();
    this._itemClickedSub = new Subject();
    this.itemClicked$ = this._itemClickedSub.asObservable();
    this._itemValueChangedSub = new Subject();
    this.itemValueChanged$ = this._itemValueChangedSub.asObservable();
  }
  setSearch(value) {
    this._searchSub.next(value);
  }
  itemClicked(value) {
    this._itemClickedSub.next(value);
  }
  itemValueChanged(oldValue, newValue) {
    this._itemValueChangedSub.next({
      oldValue,
      newValue
    });
  }
  static {
    this.ɵfac = function CmdkService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CmdkService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CmdkService,
      factory: CmdkService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CmdkService, [{
    type: Injectable
  }], null, null);
})();
class InputDirective {
  constructor() {
    this.updateOn = 'input';
    this._cmdkService = inject(CmdkService);
    this._elementRef = inject(ElementRef);
  }
  set value(value) {
    this.search(value);
  }
  search(value) {
    this._cmdkService.setSearch(value);
  }
  ngAfterViewInit() {
    this._elementRef.nativeElement.addEventListener(this.updateOn, ev => this.search(ev.target.value));
  }
  ngOnDestroy() {
    this._elementRef.nativeElement.removeAllListeners && this._elementRef.nativeElement.removeAllListeners(this.updateOn);
  }
  static {
    this.ɵfac = function InputDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || InputDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: InputDirective,
      selectors: [["input", "cmdkInput", ""]],
      hostAttrs: [1, "cmdk-input"],
      inputs: {
        updateOn: "updateOn",
        value: "value"
      },
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(InputDirective, [{
    type: Directive,
    args: [{
      selector: 'input[cmdkInput]',
      // eslint-disable-next-line @angular-eslint/no-host-metadata-property
      host: {
        class: 'cmdk-input'
      },
      standalone: true
    }]
  }], null, {
    updateOn: [{
      type: Input
    }],
    value: [{
      type: Input
    }]
  });
})();
class EmptyDirective {
  constructor() {
    this._hasView = false;
    this._templateRef = inject(TemplateRef);
    this._viewContainer = inject(ViewContainerRef);
    this._renderer2 = inject(Renderer2);
  }
  set cmdkEmpty(condition) {
    if (condition && !this._hasView) {
      const emb = this._viewContainer.createEmbeddedView(this._templateRef);
      this._renderer2.addClass(emb.rootNodes[0], 'cmdk-empty');
      this._hasView = true;
    } else if (!condition && this._hasView) {
      this._viewContainer.clear();
      this._hasView = false;
    }
  }
  static {
    this.ɵfac = function EmptyDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || EmptyDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: EmptyDirective,
      selectors: [["", "cmdkEmpty", ""]],
      hostAttrs: [1, "cmdk-empty"],
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(EmptyDirective, [{
    type: Directive,
    args: [{
      selector: '[cmdkEmpty]',
      // eslint-disable-next-line @angular-eslint/no-host-metadata-property
      host: {
        class: 'cmdk-empty'
      },
      standalone: true
    }]
  }], null, null);
})();
let cmdkItemId = 0;
let ItemDirective = class ItemDirective {
  constructor() {
    this._disabled = false;
    this.selected = new EventEmitter();
    this.originalDisplay = '';
    this.filtered = true;
    this._active = false;
    this._value = '';
    this._cmdkService = inject(CmdkService);
    this.itemId = `cmdk-item-${cmdkItemId++}`;
    this._elementRef = inject(ElementRef);
  }
  set disabled(value) {
    this._disabled = coerceBooleanProperty(value);
  }
  get disabled() {
    return this._disabled;
  }
  getLabel() {
    throw new Error('Method not implemented.');
  }
  setActiveStyles() {
    this.active = true;
  }
  setInactiveStyles() {
    this.active = false;
  }
  set value(value) {
    if (value !== this.value) {
      this._cmdkService.itemValueChanged(this.value, value);
      this._value = value;
    }
  }
  get value() {
    return this._value ? this._value : this._elementRef.nativeElement.textContent.trim().toLowerCase();
  }
  onClick() {
    this.selected.emit();
  }
  onKeyUp(ev) {
    if (ev?.key === 'Enter') {
      this.selected.emit();
    }
  }
  get hidden() {
    return !this.filtered;
  }
  get display() {
    return this.filtered ? this.originalDisplay : 'none';
  }
  get dataValue() {
    return this.value;
  }
  get attrRole() {
    return 'option';
  }
  get buttonType() {
    return 'button';
  }
  get isSelected() {
    return this._active;
  }
  get active() {
    return this._active;
  }
  get itemDisabled() {
    return this.disabled;
  }
  get itemFiltered() {
    return this.filtered;
  }
  set active(value) {
    this._active = value;
  }
  onMouseUp() {
    this._cmdkService.itemClicked(this.value);
  }
  ngAfterContentInit() {
    this.originalDisplay = window.getComputedStyle(this._elementRef.nativeElement).display;
  }
  static {
    this.ɵfac = function ItemDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ItemDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: ItemDirective,
      selectors: [["", "cmdkItem", ""]],
      hostAttrs: [1, "cmdk-item"],
      hostVars: 13,
      hostBindings: function ItemDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("click", function ItemDirective_click_HostBindingHandler() {
            return ctx.onClick();
          })("keydown", function ItemDirective_keydown_HostBindingHandler() {
            return ctx.onKeyUp(ctx.event);
          })("mouseup", function ItemDirective_mouseup_HostBindingHandler() {
            return ctx.onMouseUp();
          });
        }
        if (rf & 2) {
          i0.ɵɵhostProperty("type", ctx.buttonType);
          i0.ɵɵattribute("cmdk-hidden", ctx.hidden)("data-value", ctx.dataValue)("role", ctx.attrRole)("aria-selected", ctx.isSelected);
          i0.ɵɵstyleProp("display", ctx.display);
          i0.ɵɵclassProp("cmdk-item-active", ctx.active)("cmdk-item-disabled", ctx.itemDisabled)("cmdk-item-filtered", ctx.itemFiltered);
        }
      },
      inputs: {
        disabled: "disabled",
        filtered: "filtered",
        value: "value"
      },
      outputs: {
        selected: "selected"
      },
      standalone: true
    });
  }
};
ItemDirective = __decorate([UntilDestroy()], ItemDirective);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ItemDirective, [{
    type: Directive,
    args: [{
      selector: '[cmdkItem]',
      // eslint-disable-next-line @angular-eslint/no-host-metadata-property
      host: {
        class: 'cmdk-item'
      },
      standalone: true
    }]
  }], null, {
    disabled: [{
      type: Input
    }],
    selected: [{
      type: Output
    }],
    filtered: [{
      type: Input
    }],
    value: [{
      type: Input
    }],
    onClick: [{
      type: HostListener,
      args: ['click']
    }],
    onKeyUp: [{
      type: HostListener,
      args: ['keydown', ['event']]
    }],
    hidden: [{
      type: HostBinding,
      args: ['attr.cmdk-hidden']
    }],
    display: [{
      type: HostBinding,
      args: ['style.display']
    }],
    dataValue: [{
      type: HostBinding,
      args: ['attr.data-value']
    }],
    attrRole: [{
      type: HostBinding,
      args: ['attr.role']
    }],
    buttonType: [{
      type: HostBinding,
      args: ['type']
    }],
    isSelected: [{
      type: HostBinding,
      args: ['attr.aria-selected']
    }],
    active: [{
      type: HostBinding,
      args: ['class.cmdk-item-active']
    }],
    itemDisabled: [{
      type: HostBinding,
      args: ['class.cmdk-item-disabled']
    }],
    itemFiltered: [{
      type: HostBinding,
      args: ['class.cmdk-item-filtered']
    }],
    onMouseUp: [{
      type: HostListener,
      args: ['mouseup']
    }]
  });
})();
let cmdkGroupId = 0;
class GroupComponent {
  constructor() {
    this.showGroup = true;
    this._active = false;
    this.groupId = `cmdk-group-${cmdkGroupId++}`;
    this._cdr = inject(ChangeDetectorRef);
  }
  get filteredItems() {
    return this.items?.filter(item => item.filtered);
  }
  get active() {
    return this._active;
  }
  set active(value) {
    this._active = value;
    this._cdr.markForCheck();
  }
  get filtered() {
    return this.filteredItems.length > 0;
  }
  get id() {
    return this.groupId;
  }
  get activeClass() {
    return this.active;
  }
  get hidden() {
    return !this.showGroup;
  }
  get dataValue() {
    return this.label?.toString().toLowerCase();
  }
  static {
    this.ɵfac = function GroupComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || GroupComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: GroupComponent,
      selectors: [["cmdk-group"]],
      contentQueries: function GroupComponent_ContentQueries(rf, ctx, dirIndex) {
        if (rf & 1) {
          i0.ɵɵcontentQuery(dirIndex, ItemDirective, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.items = _t);
        }
      },
      hostAttrs: [1, "cmdk-group"],
      hostVars: 5,
      hostBindings: function GroupComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵhostProperty("id", ctx.id);
          i0.ɵɵattribute("cmdk-hidden", ctx.hidden)("data-value", ctx.dataValue);
          i0.ɵɵclassProp("cmdk-group-active", ctx.activeClass);
        }
      },
      inputs: {
        label: "label",
        ariaLabel: "ariaLabel"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 3,
      vars: 2,
      consts: [["role", "presentation", 1, "cmdk-group-label"], ["role", "group", 1, "cmdk-group-content"], [4, "dynamicView"]],
      template: function GroupComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵtemplate(0, GroupComponent_Conditional_0_Template, 2, 1, "div", 0);
          i0.ɵɵelementStart(1, "div", 1);
          i0.ɵɵprojection(2);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵconditional(ctx.label ? 0 : -1);
          i0.ɵɵadvance();
          i0.ɵɵattribute("aria-label", ctx.ariaLabel);
        }
      },
      dependencies: [DynamicViewDirective],
      styles: ["[cmdk-hidden=true][_nghost-%COMP%]{display:none}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GroupComponent, [{
    type: Component,
    args: [{
      selector: 'cmdk-group',
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        class: 'cmdk-group'
      },
      standalone: true,
      imports: [DynamicViewDirective],
      template: "@if (label) {\n  <div role=\"presentation\" class=\"cmdk-group-label\">\n    <ng-container *dynamicView=\"label\"></ng-container>\n  </div>\n}\n<div class=\"cmdk-group-content\" role=\"group\" [attr.aria-label]=\"ariaLabel\">\n  <ng-content></ng-content>\n</div>\n",
      styles: [":host[cmdk-hidden=true]{display:none}\n"]
    }]
  }], null, {
    label: [{
      type: Input
    }],
    ariaLabel: [{
      type: Input
    }],
    items: [{
      type: ContentChildren,
      args: [ItemDirective, {
        descendants: true
      }]
    }],
    id: [{
      type: HostBinding,
      args: ['id']
    }],
    activeClass: [{
      type: HostBinding,
      args: ['class.cmdk-group-active']
    }],
    hidden: [{
      type: HostBinding,
      args: ['attr.cmdk-hidden']
    }],
    dataValue: [{
      type: HostBinding,
      args: ['attr.data-value']
    }]
  });
})();

/**
 * A visual and semantic separator between items or groups.
 * Visible when the search query is empty or `alwaysRender` is true, hidden otherwise.
 */
class SeparatorComponent {
  constructor() {
    this.showSeparator = true;
    this.cdr = inject(ChangeDetectorRef);
  }
  get hidden() {
    return !this.showSeparator;
  }
  static {
    this.ɵfac = function SeparatorComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SeparatorComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SeparatorComponent,
      selectors: [["cmdk-separator"]],
      hostAttrs: [1, "cmdk-separator"],
      hostVars: 1,
      hostBindings: function SeparatorComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵattribute("cmdk-hidden", ctx.hidden);
        }
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 1,
      vars: 0,
      template: function SeparatorComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelement(0, "hr");
        }
      },
      styles: ["[cmdk-hidden=true][_nghost-%COMP%]{display:none}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SeparatorComponent, [{
    type: Component,
    args: [{
      selector: 'cmdk-separator',
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        class: 'cmdk-separator'
      },
      standalone: true,
      template: "<hr>",
      styles: [":host[cmdk-hidden=true]{display:none}\n"]
    }]
  }], null, {
    hidden: [{
      type: HostBinding,
      args: ['attr.cmdk-hidden']
    }]
  });
})();
class LoaderDirective {
  constructor() {
    this._hasView = false;
    this._templateRef = inject(TemplateRef);
    this._viewContainer = inject(ViewContainerRef);
  }
  set cmdkLoader(condition) {
    if (condition && !this._hasView) {
      this._viewContainer.createEmbeddedView(this._templateRef);
      this._hasView = true;
    } else if (!condition && this._hasView) {
      this._viewContainer.clear();
      this._hasView = false;
    }
  }
  static {
    this.ɵfac = function LoaderDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || LoaderDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: LoaderDirective,
      selectors: [["", "cmdkLoader", ""]],
      hostAttrs: [1, "cmdk-loader"],
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LoaderDirective, [{
    type: Directive,
    args: [{
      selector: '[cmdkLoader]',
      // eslint-disable-next-line @angular-eslint/no-host-metadata-property
      host: {
        class: 'cmdk-loader'
      },
      standalone: true
    }]
  }], null, null);
})();
let cmdkListId = 0;
class ListComponent {
  constructor() {
    this._active = false;
    this._elementRef = inject(ElementRef);
    this.listId = `cmdk-list-${cmdkListId++}`;
    this._cdr = inject(ChangeDetectorRef);
  }
  get filteredItems() {
    return this.items?.filter(item => item.filtered);
  }
  get active() {
    return this._active;
  }
  set active(value) {
    this._active = value;
    this._cdr.markForCheck();
  }
  get filtered() {
    return this.filteredItems.length > 0;
  }
  get id() {
    return this.listId;
  }
  get activeClass() {
    return this.active;
  }
  ngAfterViewInit() {
    if (this.heightEle.nativeElement && this._elementRef.nativeElement) {
      const el = this.heightEle.nativeElement;
      const wrapper = this._elementRef.nativeElement;
      this._observer = new ResizeObserver(() => {
        this._animationFrame = requestAnimationFrame(() => {
          const height = el.getBoundingClientRect().height;
          wrapper.style.setProperty(`--cmdk-list-height`, height.toFixed(1) + 'px');
          this._cdr.markForCheck();
        });
      });
      this._observer.observe(el);
    }
  }
  ngOnDestroy() {
    if (this._animationFrame !== undefined) {
      cancelAnimationFrame(this._animationFrame);
    }
    if (this._observer && this.heightEle.nativeElement) {
      this._observer.unobserve(this.heightEle.nativeElement);
    }
  }
  static {
    this.ɵfac = function ListComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ListComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: ListComponent,
      selectors: [["cmdk-list"]],
      contentQueries: function ListComponent_ContentQueries(rf, ctx, dirIndex) {
        if (rf & 1) {
          i0.ɵɵcontentQuery(dirIndex, ItemDirective, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.items = _t);
        }
      },
      viewQuery: function ListComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c1, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.heightEle = _t.first);
        }
      },
      hostAttrs: [1, "cmdk-list"],
      hostVars: 3,
      hostBindings: function ListComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵhostProperty("id", ctx.id);
          i0.ɵɵclassProp("cmdk-list-active", ctx.activeClass);
        }
      },
      inputs: {
        ariaLabel: "ariaLabel"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 3,
      vars: 1,
      consts: [["height", ""], ["role", "listbox", 1, "cmdk-list-content"]],
      template: function ListComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "div", 1, 0);
          i0.ɵɵprojection(2);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵattribute("aria-label", ctx.ariaLabel);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ListComponent, [{
    type: Component,
    args: [{
      selector: 'cmdk-list',
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        class: 'cmdk-list'
      },
      standalone: true,
      template: "<div class=\"cmdk-list-content\" role=\"listbox\" [attr.aria-label]=\"ariaLabel\" #height>\n  <ng-content></ng-content>\n</div>\n"
    }]
  }], null, {
    ariaLabel: [{
      type: Input
    }],
    items: [{
      type: ContentChildren,
      args: [ItemDirective, {
        descendants: true
      }]
    }],
    heightEle: [{
      type: ViewChild,
      args: ['height']
    }],
    id: [{
      type: HostBinding,
      args: ['id']
    }],
    activeClass: [{
      type: HostBinding,
      args: ['class.cmdk-list-active']
    }]
  });
})();
let commandId = 0;
const GROUP_SELECTOR = 'cmdk-group';
const GROUP_HEADING_SELECTOR = '.cmdk-group-label';
let CommandComponent = class CommandComponent {
  constructor() {
    this.valueChanged = new EventEmitter();
    this.filter = (value, search) => value.toLowerCase().includes(search.toLowerCase());
    this.loop = false;
    this.search = '';
    this.panelId = `cmdk-command-${commandId++}`;
    this.cmdkService = inject(CmdkService);
  }
  get attrAriaLabel() {
    return this.ariaLabel;
  }
  get id() {
    return this.panelId;
  }
  ngOnChanges(changes) {
    if (changes['value'] && !changes['value'].firstChange) {
      this.setValue(this.value);
    } else if (changes['loading'] && !changes['loading'].firstChange && this.loader) {
      this.loader.cmdkLoader = this.loading;
    }
  }
  ngAfterViewInit() {
    race(this.cmdkService.itemValueChanged$, this.items.changes).pipe(untilDestroyed(this)).subscribe(() => {
      setTimeout(() => {
        if (this.keyManager) {
          this.keyManager.destroy();
        }
        // create key and focus managers
        this.initKeyManager();
        if (this.filter) {
          this.handleSearch(this.search);
        }
      });
    });
    // show/hide loader
    if (this.loader) {
      this.loader.cmdkLoader = this.loading;
    }
    // create key and focus managers
    this.initKeyManager();
    if (this.filter) {
      this.cmdkService.search$.pipe(untilDestroyed(this)).subscribe(s => this.handleSearch(s));
    }
    // if value is given, make that item active, else make first item active
    if (this.value) {
      this.setValue(this.value);
    } else {
      this.makeFirstItemActive();
    }
    // emit value on item clicks
    this.cmdkService.itemClicked$.pipe(untilDestroyed(this)).subscribe(value => {
      const emit = true;
      this.setValue(value, emit);
    });
  }
  initKeyManager() {
    this.keyManager = new ActiveDescendantKeyManager(this.items).withWrap(this.loop).withPageUpDown().skipPredicate(item => item.disabled || !item.filtered);
    // set active group on active item change
    this.keyManager.change.pipe(untilDestroyed(this)).subscribe(() => {
      const activeItem = this.keyManager.activeItem;
      if (activeItem) {
        const emit = true;
        this.setValue(activeItem.value, emit);
        this.setActiveGroupForActiveItem(activeItem.itemId);
      }
    });
  }
  get filteredItems() {
    return this.items?.filter(item => item.filtered);
  }
  get filteredGroups() {
    return this.groups?.filter(group => group.filtered);
  }
  get filteredLists() {
    return this.lists?.filter(group => group.filtered);
  }
  handleSearch(search) {
    this.search = search;
    if (this.items?.length) {
      // filter items
      this.items?.forEach(item => {
        item.filtered = this.filter ? this.filter(item.value, search) : true;
      });
      // make first item active and in-turn it will also make first group active, if available
      this.makeFirstItemActive();
    }
    // show/hide empty directive
    if (this.empty) {
      this.empty.cmdkEmpty = this.filteredItems?.length === 0;
    }
    // show/hide group
    this.groups?.forEach(group => {
      group.showGroup = group.filteredItems?.length > 0;
      group._cdr.markForCheck();
    });
    // hide separator if search and filter both are present, else show
    this.separators?.forEach(seperator => {
      seperator.showSeparator = !(this.filter && search);
      seperator.cdr.markForCheck();
    });
  }
  onKeyUp(ev) {
    if (ev.key === 'Enter' && this.keyManager.activeItem && this.filteredItems.length > 0) {
      this.valueChanged.emit(this.keyManager.activeItem.value);
      this.keyManager.activeItem.selected.emit();
    } else {
      this.keyManager.onKeydown(ev);
    }
  }
  ngOnDestroy() {
    this.keyManager.destroy();
  }
  makeFirstItemActive() {
    setTimeout(() => {
      const firstItem = this.filteredItems?.[0];
      if (firstItem) {
        this.keyManager.setFirstItemActive();
      } else {
        this.valueChanged.emit(undefined);
      }
    });
  }
  setActiveGroupForActiveItem(nextActiveItemId) {
    this.filteredGroups?.forEach(group => {
      group.active = group.filteredItems.some(item => item.itemId === nextActiveItemId);
    });
    this.filteredLists?.forEach(list => {
      list.active = list.filteredItems.some(item => item.itemId === nextActiveItemId);
    });
  }
  setValue(value, emit = false) {
    if (value !== undefined) {
      const valueItem = this.filteredItems?.find(item => item.value === value);
      if (valueItem) {
        if (this.keyManager.activeItem !== valueItem) {
          setTimeout(() => {
            this.keyManager.setActiveItem(valueItem);
          });
        }
        setTimeout(() => {
          this.scrollActiveIntoView();
        });
        if (emit) {
          this.valueChanged.emit(value);
        }
      }
    }
  }
  scrollActiveIntoView() {
    const item = this.keyManager.activeItem;
    const nativeElement = item?._elementRef?.nativeElement;
    if (nativeElement) {
      if (nativeElement.parentElement?.firstChild === nativeElement) {
        // First item in Group, ensure heading is in view
        nativeElement.closest(GROUP_SELECTOR)?.querySelector(GROUP_HEADING_SELECTOR)?.scrollIntoView({
          block: 'nearest'
        });
      }
      // Ensure the item is always in view
      nativeElement.scrollIntoView({
        block: 'nearest'
      });
    }
  }
  static {
    this.ɵfac = function CommandComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CommandComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CommandComponent,
      selectors: [["cmdk-command"]],
      contentQueries: function CommandComponent_ContentQueries(rf, ctx, dirIndex) {
        if (rf & 1) {
          i0.ɵɵcontentQuery(dirIndex, EmptyDirective, 5);
          i0.ɵɵcontentQuery(dirIndex, LoaderDirective, 5);
          i0.ɵɵcontentQuery(dirIndex, ItemDirective, 5);
          i0.ɵɵcontentQuery(dirIndex, GroupComponent, 5);
          i0.ɵɵcontentQuery(dirIndex, ListComponent, 5);
          i0.ɵɵcontentQuery(dirIndex, SeparatorComponent, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.empty = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.loader = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.items = _t);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.groups = _t);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.lists = _t);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.separators = _t);
        }
      },
      hostAttrs: [1, "cmdk-command"],
      hostVars: 2,
      hostBindings: function CommandComponent_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("keydown", function CommandComponent_keydown_HostBindingHandler($event) {
            return ctx.onKeyUp($event);
          });
        }
        if (rf & 2) {
          i0.ɵɵhostProperty("id", ctx.id);
          i0.ɵɵattribute("aria-label", ctx.attrAriaLabel);
        }
      },
      inputs: {
        value: "value",
        ariaLabel: "ariaLabel",
        loading: "loading",
        filter: "filter",
        loop: "loop"
      },
      outputs: {
        valueChanged: "valueChanged"
      },
      exportAs: ["cmdkCommand"],
      standalone: true,
      features: [i0.ɵɵProvidersFeature([CmdkService]), i0.ɵɵNgOnChangesFeature, i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function CommandComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
CommandComponent = __decorate([UntilDestroy()], CommandComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CommandComponent, [{
    type: Component,
    args: [{
      selector: 'cmdk-command',
      providers: [CmdkService],
      changeDetection: ChangeDetectionStrategy.OnPush,
      exportAs: 'cmdkCommand',
      host: {
        class: 'cmdk-command'
      },
      standalone: true,
      template: "<ng-content></ng-content>\n"
    }]
  }], null, {
    valueChanged: [{
      type: Output
    }],
    value: [{
      type: Input
    }],
    ariaLabel: [{
      type: Input
    }],
    loading: [{
      type: Input
    }],
    filter: [{
      type: Input
    }],
    loop: [{
      type: Input
    }],
    items: [{
      type: ContentChildren,
      args: [ItemDirective, {
        descendants: true
      }]
    }],
    groups: [{
      type: ContentChildren,
      args: [GroupComponent, {
        descendants: true
      }]
    }],
    lists: [{
      type: ContentChildren,
      args: [ListComponent, {
        descendants: true
      }]
    }],
    separators: [{
      type: ContentChildren,
      args: [SeparatorComponent, {
        descendants: true
      }]
    }],
    empty: [{
      type: ContentChild,
      args: [EmptyDirective]
    }],
    loader: [{
      type: ContentChild,
      args: [LoaderDirective]
    }],
    attrAriaLabel: [{
      type: HostBinding,
      args: ['attr.aria-label']
    }],
    id: [{
      type: HostBinding,
      args: ['id']
    }],
    onKeyUp: [{
      type: HostListener,
      args: ['keydown', ['$event']]
    }]
  });
})();
const ComponentsAndDirectives = [CommandComponent, InputDirective, EmptyDirective, GroupComponent, SeparatorComponent, ItemDirective, LoaderDirective, ListComponent];
class CmdkModule {
  static forRoot() {
    return {
      ngModule: CmdkModule
    };
  }
  static {
    this.ɵfac = function CmdkModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CmdkModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CmdkModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, A11yModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CmdkModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, A11yModule, ...ComponentsAndDirectives],
      exports: ComponentsAndDirectives
    }]
  }], null, null);
})();

/*
 * Public API Surface of cmdk
 */

/**
 * Generated bundle index. Do not edit.
 */

export { CmdkModule, CmdkService, CommandComponent, EmptyDirective, GroupComponent, InputDirective, ItemDirective, ListComponent, LoaderDirective, SeparatorComponent };
