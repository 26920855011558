export interface EfaScope {
  scopeType: string;
  scopeCode: string;
  scopeTypeParent: string;
  scopeTypeCode: string;
  scopeLevel: number;
}

export class EfaScopeModel {
  props: EfaScope = {
    scopeType: '',
    scopeCode: '',
    scopeTypeParent: '',
    scopeTypeCode: '',
    scopeLevel: NaN,
  };

  constructor(props: Partial<EfaScope>) {
    Object.assign(this.props, props);
  }

  static builder(): EfaScopeBuilder {
    return new EfaScopeBuilder();
  }

  getValues() {
    return this.props;
  }
}

class EfaScopeBuilder {
  private scopeType: string;
  private scopeCode: string;
  private scopeTypeParent: string;
  private scopeTypeCode: string;
  private scopeLevel: number;

  build() {
    return new EfaScopeModel({
      scopeType: this.scopeType,
      scopeCode: this.scopeCode,
      scopeTypeParent: this.scopeTypeParent,
      scopeTypeCode: this.scopeCode,
      scopeLevel: this.scopeLevel,
    }).getValues();
  }

  withLevel(level: number) {
    this.scopeLevel = level;
    return this;
  }

  withType(type: string) {
    this.scopeType = type;
    return this;
  }

  withCode(code: string) {
    this.scopeCode = code;
    return this;
  }

  withTypeParent(parent: string) {
    this.scopeTypeParent = parent;
    return this;
  }

  withTypeCode(code: string) {
    this.scopeTypeCode = code;
    return this;
  }
}
