import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';

/**
 * Usage: export class YouService extends CactusService<YourType>
 * In TestBed's configuration: providers: [provideHttpClient(), provideHttpClientTesting()],
 */
export class CactusService<A> {
  protected useInMemory: boolean;
  public http: HttpClient = inject(HttpClient); // public to allow test to inject
  public inMemoryData: A[] = []; // public for testing purpose but should not appear in production

  public feedWith(data: A[]): A[] {
    this.useInMemory = true;
    this.inMemoryData = data;
    return this.inMemoryData;
  }

  public handleHttpError(error: HttpErrorResponse): Observable<never> {
    return new Observable((observer) => {
      console.error('An error occurred:', error.message);
      observer.error(error);
      observer.complete();
    });
  }
}
