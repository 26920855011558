import { Component, input, InputSignal, output, OutputEmitterRef } from '@angular/core';
import { SubsetScope } from '../../../services/scopes/model/scope.model';

@Component({
  selector: 'app-plant-code',
  standalone: true,
  imports: [],
  templateUrl: './plant-code.component.html',
  styleUrl: './plant-code.component.css',
})
export class PlantCodeComponent {
  public scope: InputSignal<SubsetScope | undefined> = input();
  outputAction: OutputEmitterRef<SubsetScope> = output<SubsetScope>();

  clickOnCross() {
    this.outputAction.emit(this.scope() as SubsetScope);
  }
}
