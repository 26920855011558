import { Component, input, output } from '@angular/core';

@Component({
  selector: 'app-modal',
  standalone: true,
  imports: [],
  template: `
    <div class="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50" (click)="onClose()">
      <div
        class="bg-white rounded-lg shadow-lg p-6"
        (click)="$event.stopPropagation()"
        [style]="'width: ' + width() + '%;'"
      >
        <div class="relative">
          <h2 title class="text-xl font-semibold text-emerald-600 pr-10 text-pretty mb-4">
            <ng-content select="[title]"></ng-content>
          </h2>
          <div class="text-2xl cursor-pointer absolute top-0 right-2" (click)="onClose()">&#9932;</div>
        </div>
        <ng-content select="[body]"></ng-content>
        <footer class="inline-flex justify-between w-full"><ng-content select="[footer]"></ng-content></footer>
      </div>
    </div>
  `,
})
export class ModalComponent {
  close = output<void>();
  dangerousAction = input<boolean>(false);
  width = input<number>(33);

  onClose() {
    this.close.emit();
  }
}
