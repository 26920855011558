import { NgClass, NgComponentOutlet } from '@angular/common';
import { Component, input } from '@angular/core';
import { FlexRenderDirective, Row } from '@tanstack/angular-table';
import { GroupedTableRowComponent } from '../table.component';

@Component({
  selector: 'app-recursive-grouped-rows',
  standalone: true,
  imports: [NgClass, NgComponentOutlet, FlexRenderDirective],
  template: `
    <tr>
      @for (cell of row().getVisibleCells(); track cell.id) {
        @if (cell.getIsGrouped() && isRecursedGroupedCell(cell.column.id)) {
          <td
            [attr.colspan]="800"
            (click)="row().getToggleExpandedHandler()()"
            [ngClass]="recursionLevel() === 0 ? 'bg-green-100' : 'bg-indigo-50'"
          >
            <div
              class="inline-flex w-full items-center cursor-pointer [&>:not(svg)]:w-full pr-4"
              [style.paddingLeft]="0.25 + recursionLevel() * 0.5 + 'rem'"
            >
              <svg
                [ngClass]="row().getIsExpanded() ? 'rotate-90' : ''"
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                viewBox="0 0 24 24"
                class="mr-4"
              >
                <path d="M7.293 4.707 14.586 12l-7.293 7.293 1.414 1.414L17.414 12 8.707 3.293 7.293 4.707z" />
              </svg>
              @if (groupedTableRows().length === 0 || groupedTableRows()[recursionLevel()] === undefined) {
                {{ cell.getValue() }}
              } @else {
                <ng-container
                  *ngComponentOutlet="
                    groupedTableRows()[recursionLevel()];
                    inputs: {
                      row: row(),
                      noSubRows: row().subRows.length,
                      actionButtonCallback:
                        actionButtonCallbacks()[recursionLevel()] ?? this.defaultActionButtonCallback,
                    }
                  "
                >
                </ng-container>
              }
            </div>
          </td>
        }
      }
    </tr>
    @if (row().getIsExpanded()) {
      @if (row().subRows[0].subRows.length > 0) {
        @for (subrow of row().subRows; track subrow.id) {
          <app-recursive-grouped-rows
            [row]="subrow"
            [recursionLevel]="recursionLevel() + 1"
            [groupedTableRows]="groupedTableRows()"
            [grouping]="grouping()"
          ></app-recursive-grouped-rows>
        }
      } @else {
        @for (subrow of row().subRows; track subrow.id) {
          <tr>
            @for (cell of subrow.getVisibleCells(); track cell.id; let first = $first) {
              <!-- If you wish to show the grouped cell value, you can remove this line -->
              @if (!grouping().includes(cell.column.id)) {
                <td class="text-center">
                  <ng-container *flexRender="cell.column.columnDef.cell; props: cell.getContext(); let cell">
                    {{ cell }}
                  </ng-container>
                </td>
              }
            }
          </tr>
        }
      }
    }
  `,
  styles: `
    :host {
      display: contents;
    }
  `,
})
export class RecursiveGroupedRowsComponent<T extends Record<string, any>> {
  row = input.required<Row<T>>();
  grouping = input.required<string[]>();
  groupedTableRows = input<Array<GroupedTableRowComponent<T>>>([]);
  actionButtonCallbacks = input<Array<(row: Row<T>) => void>>([]);
  recursionLevel = input<number>(0);

  defaultActionButtonCallback = () => {
    console.log('hellslslsl');
  };

  /**
   * cell.getIsGrouped() will return true for all grouped cells. We need to find the specific grouped cell based on our level in the recursion.
   * @param cell
   * @returns
   */
  isRecursedGroupedCell(cellColumn: string) {
    return cellColumn === this.grouping()[this.recursionLevel()];
  }
}
