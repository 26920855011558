import { ArkErrors, BaseRoot, GenericRoot } from "@ark/schema";
import { Callable, Hkt } from "@ark/util";
export class InternalTypeParser extends Callable {
  constructor($) {
    const attach = Object.assign({
      errors: ArkErrors,
      hkt: Hkt,
      $: $,
      raw: $.parse,
      module: $.constructor.module,
      scope: $.constructor.scope,
      define: $.define,
      generic: $.generic,
      schema: $.schema,
      // this won't be defined during bootstrapping, but externally always will be
      keywords: $.ambient,
      unit: $.unit,
      enumerated: $.enumerated,
      instanceOf: $.instanceOf
    },
    // also won't be defined during bootstrapping
    $.ambientAttachments);
    super((...args) => {
      if (args.length === 1) {
        // treat as a simple definition
        return $.parse(args[0]);
      }
      if (args.length === 2 && typeof args[0] === "string" && args[0][0] === "<" && args[0].at(-1) === ">") {
        // if there are exactly two args, the first of which looks like <${string}>,
        // treat as a generic
        const paramString = args[0].slice(1, -1);
        const params = $.parseGenericParams(paramString, {});
        return new GenericRoot(params, args[1], $, $, null);
      }
      // otherwise, treat as a tuple expression. technically, this also allows
      // non-expression tuple definitions to be parsed, but it's not a supported
      // part of the API as specified by the associated types
      return $.parse(args);
    }, {
      bind: $,
      attach
    });
  }
}
export const Type = BaseRoot;