import { ActivatedRoute, Router } from '@angular/router';
import { Component, inject, signal, WritableSignal } from '@angular/core';
import { lastValueFrom, map } from 'rxjs';
import { toSignal } from '@angular/core/rxjs-interop';

import { AuthService } from '../../services/auth/auth.service';
import { Responsible, ResponsibleModel } from '../../services/responsible/model/responsible.model';
import { SubsetCreateOrEditComponent } from '../../utils/components/subset-create-or-edit/subset-create-or-edit.component';
import { Subset } from '../../services/subset/model/subset.model';
import { SubsetService } from '../../services/subset/subset.service';
import { ResponsibleService } from '../../services/responsible/responsible.service';

@Component({
  selector: 'app-subset-edit',
  standalone: true,
  imports: [SubsetCreateOrEditComponent],
  templateUrl: './subset-edit.component.html',
  styleUrl: './subset-edit.component.css',
})
export class SubsetEditComponent {
  /**
   * Dependency Injection
   */
  private activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private authService: AuthService = inject(AuthService);
  private subsetService: SubsetService = inject(SubsetService);
  private router: Router = inject(Router);
  private responsibleService: ResponsibleService = inject(ResponsibleService);

  public responsibleList: WritableSignal<Responsible[]> = signal([]);

  public subsetUuid: string = this.activatedRoute.snapshot.paramMap.get('uuid') || '';
  public subset: WritableSignal<Subset | undefined> = signal(void 0);

  private _allRequest = toSignal(
    this.responsibleService.retrieveAllObservable().pipe(
      map((responsibleList) => {
        this.responsibleList.set(responsibleList);
        return responsibleList;
      }),
      map((responsibleList) => this.subsetService.retrieveAll(responsibleList)),
      map(async (subsetList) => {
        const result: Subset[] = await lastValueFrom(subsetList);
        const subset = result.find(({ uuid }) => uuid === this.subsetUuid);
        if (subset) {
          this.subset.set(subset);
        }
      }),
    ),
  );

  submit(subset: Partial<Subset> & { launchedBy: Responsible | undefined }) {
    this.subsetService
      .edit(this.subsetUuid, {
        name: subset.name || '',
        responsible: ResponsibleModel.builder()
          .withResponsible(subset.responsible as Responsible)
          .build(),
        comment: subset.comment || '',
        scopes: subset.scopes || [],
        year: subset.fiscalYear || '',
        launchedBy: subset.launchedBy || undefined,
      })
      .subscribe(() => this.gotoPrevious());
  }

  gotoPrevious() {
    // if new route, return list or view subset
    // if popup, close it
    return this.router.navigate([`/subset/${this.subsetUuid}`]);
  }

  canEditSubset() {
    return this.authService.getProfile() === 'advanced';
  }
}
