import { EfaScope, EfaScopeModel } from './model/efa-scope';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { CactusService } from '../cactus.service';
import { environment } from '../../../environments/environment';
import { efaScopeListSeed } from '../../../tests/seed/efaScopeList.seed';

@Injectable({
  providedIn: 'root',
})
export class EfaScopeService extends CactusService<EfaScope> {
  retrieveAllObservable(): Observable<EfaScope[]> {
    if (['test'].includes(environment.name) || this.useInMemory) {
      return new Observable<EfaScope[]>((observer) => {
        if (!this.useInMemory) {
          this.inMemoryData = efaScopeListSeed;
        }
        observer.next(this.inMemoryData);
        observer.complete();
      });
    }
    return this.getSource();
  }

  private getSource() {
    if (this.useInMemory) {
      return new Observable<EfaScope[]>((observer) => {
        observer.next(this.inMemoryData);
        observer.complete();
      });
    }
    return this.http.get<{ data: EfaScope[] }>(`${environment.api}retrieveEfaScopeList`).pipe(
      map(({ data }) => {
        const inMemoryEfaScopeList: EfaScope[] = [];
        for (const efaScopeQueried of data) {
          const efaScope = EfaScopeModel.builder()
            .withType(efaScopeQueried.scopeType)
            .withCode(efaScopeQueried.scopeCode)
            .withTypeParent(efaScopeQueried.scopeTypeParent)
            .withTypeCode(efaScopeQueried.scopeTypeCode)
            .withLevel(efaScopeQueried.scopeLevel)
            .build();
          inMemoryEfaScopeList.push(efaScope);
        }
        return this.feedWith(inMemoryEfaScopeList);
      }),
    );
  }
}
