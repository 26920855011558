import {
  Component,
  computed,
  effect,
  inject,
  input,
  InputSignal,
  output,
  OutputEmitterRef,
  signal,
  Signal,
  untracked,
  WritableSignal,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgClass } from '@angular/common';
import { toSignal } from '@angular/core/rxjs-interop';

import { AuthService } from '../../../services/auth/auth.service';
import { DateProvider } from '../../../services/year-of-consumption/model/date-provider';
import { Responsible } from '../../../services/responsible/model/responsible.model';
import { ResponsibleService } from '../../../services/responsible/responsible.service';
import { ScopesService } from '../../../services/scopes/scopes.service';
import { SubsetScope } from '../../../services/scopes/model/scope.model';
import { Subset } from '../../../services/subset/model/subset.model';
import { YearOfConsumptionService } from '../../../services/year-of-consumption/year-of-consumption.service';

@Component({
  selector: 'app-subset-create-or-edit',
  standalone: true,
  imports: [FormsModule, NgClass, ReactiveFormsModule],
  providers: [DateProvider, YearOfConsumptionService],
  templateUrl: './subset-create-or-edit.component.html',
  styleUrl: './subset-create-or-edit.component.css',
})
export class SubsetCreateOrEditComponent {
  /**
   * Dependencies Injection
   */
  private authService: AuthService = inject(AuthService);
  private responsibleService: ResponsibleService = inject(ResponsibleService);
  private scopeService: ScopesService = inject(ScopesService);
  private yearOfConsumptionService: YearOfConsumptionService = inject(YearOfConsumptionService);

  /**
   * Input Output
   */
  editMode: InputSignal<boolean> = input(false);
  subset: InputSignal<Subset | undefined> = input();
  outputSubset: OutputEmitterRef<Partial<Subset> & { launchedBy: Responsible | undefined }> = output<
    Partial<Subset> & { launchedBy: Responsible | undefined }
  >();
  outputClose = output<void>();

  public responsibleList: Signal<Responsible[]> = toSignal(this.responsibleService.retrieveAllObservable(), {
    initialValue: [],
  });
  public scopeList: Signal<SubsetScope[]> = toSignal(this.scopeService.retrieveAllObservable(), { initialValue: [] });
  public scopeSelected: WritableSignal<SubsetScope[]> = signal([]);
  public scopeAvailableList: Signal<SubsetScope[]> = computed(() => this.scopeListMinusSelected());
  public displayError: WritableSignal<boolean> = signal(false);
  public sending: WritableSignal<boolean> = signal(false);
  public yearList: WritableSignal<string[]> = signal([
    this.yearOfConsumptionService.getYearOfConsumption(),
    this.yearOfConsumptionService.getPreviousYearOfConsumption(),
  ]);

  scopeToAdd = '';

  /**
   * Forms
   */
  subsetName = new FormControl('', [Validators.maxLength(100), Validators.required]);
  subsetComment = new FormControl('', Validators.maxLength(100));
  responsibleSelected = new FormControl('');
  yearSelected = new FormControl('', Validators.required);

  constructor() {
    effect(() => {
      if (this.editMode() && this.subset() && this.scopeList()?.length) {
        untracked(() => {
          this.fillFormControl();
        });
      } else if (!this.editMode() && this.responsibleList()?.length) {
        untracked(() => {
          this.preselectedResponsible();
          this.preselectedYear();
        });
      }
    });
  }

  fillFormControl() {
    const { name, comment, fiscalYear, scopes, responsible } = this.subset() as Subset;
    this.subsetName.setValue(name);
    this.subsetComment.setValue(comment);
    this.responsibleSelected.setValue(`${responsible.firstName} ${responsible.lastName}`);
    this.yearSelected.setValue(fiscalYear);
    scopes.forEach((scope) => {
      this.scopeToAdd = scope.plantCode;
      this.onScopeToAdd();
    });
  }

  preselectedResponsible() {
    const { firstName, lastName } = this.authService.getName();
    const myself = this.responsibleList().find(
      (responsible) =>
        responsible.firstName.toLowerCase() === firstName.toLowerCase() &&
        responsible.lastName.toLowerCase() === lastName.toLowerCase(),
    );

    if (myself) {
      this.responsibleSelected.setValue(`${firstName} ${lastName}`);
    }
  }

  preselectedYear() {
    this.yearSelected.setValue(this.yearOfConsumptionService.getYearOfConsumption());
  }

  onScopeToAdd() {
    const scopeForSubset = this.scopeList().find(({ plantCode }) => plantCode === this.scopeToAdd) as SubsetScope;
    this.scopeSelected.set([...this.scopeSelected(), scopeForSubset]);

    this.scopeToAdd = '';
  }

  removeScope(scopeCodeToRemove: string) {
    this.scopeSelected.set([...this.scopeSelected().filter(({ plantCode }) => plantCode !== scopeCodeToRemove)]);

    this.scopeToAdd = '';
  }

  cancelCreate() {
    this.outputClose.emit();
  }

  applySubset() {
    const responsible = this.responsibleList().find(
      (r) =>
        `${r.firstName.toLowerCase()} ${r.lastName.toLowerCase()}` === this.responsibleSelected.value?.toLowerCase(),
    );
    const currentUserUuid = this.authService.getCurrentUserUuid(this.responsibleList());
    const launchedBy: Responsible | undefined = this.responsibleList().find((r) => r.uuid === currentUserUuid);

    const hasError = [
      this.subsetName.invalid,
      this.subsetComment.invalid,
      this.yearSelected.invalid,
      !this.responsibleSelected.value?.length,
      !this.scopeSelected().length,
    ].includes(true);

    if (hasError) {
      this.displayError.set(true);
      return;
    }
    this.displayError.set(false);
    this.sending.set(true);
    this.subsetName.disable();
    this.subsetComment.disable();
    this.yearSelected.disable();
    this.responsibleSelected.disable();

    this.outputSubset.emit({
      name: this.subsetName.value as string,
      responsible: responsible,
      comment: this.subsetComment.value || '',
      scopes: this.scopeSelected(),
      fiscalYear: this.yearSelected.value || '',
      launchedBy: launchedBy || undefined,
    });
  }

  scopeListMinusSelected() {
    const availableList = [...this.scopeList()];

    for (const selectedScope of this.scopeSelected()) {
      const index = availableList.findIndex(({ plantCode }) => plantCode === selectedScope.plantCode);
      if (index !== -1) {
        availableList.splice(index, 1);
      }
    }

    return availableList;
  }

  hasError(field: string) {
    if (!this.displayError()) {
      return false;
    }

    switch (field) {
      case 'name':
        return this.subsetName.invalid;
      case 'comment':
        return this.subsetComment.invalid;
      case 'responsible':
        return this.responsibleSelected.value?.length === 0;
      case 'scope':
        return this.scopeSelected().length === 0;
      default:
        return false;
    }
  }
}
